import Grid from "UI/App/Components/Grid/Grid";
import Block from "UI/App/Components/Block/Block";
import Button from "UI/App/Components/Button/Button";
import Icon from "UI/App/Components/Icon/Icon";
import {Link} from "react-router-dom";
import IF from "UI/App/Components/Conditional/IF";
import {statusses} from "UI/App/Partials/Content/Calculations/Calculations/overview";
import Table from "UI/App/Components/Table/Table";
import Boolean, {BooleanControlled} from "UI/App/Components/Form/Boolean";
import React, {useContext} from "react";
import {toast} from "react-toastify";
import {FetchContext} from "App/Strapi/FetchContext";

export default function InformationBlock({
         hasChecklist,
         printCalculation,
         company,
         calculationNumber,
         calculationDimensions,
         copiedFrom,
         amounts,
         setCalculationStatus,
         calculationStatus,
         thirdPartyQuotes,
         copyTableData,
         checklistTable,
        jsonData,
         callBack
     }) {
    const {authAxios} = useContext(FetchContext);

    return (
        <Grid columns={{
            1100: 1,
            default: 2
        }} customColTemplate={{
            1100: '1fr',
            default: hasChecklist ? '1fr 2fr' : '1fr'
        }}>
            <Grid columns={1}>
                <Block
                    name='info'
                    className='info'
                    title='Informatie'
                    headerRightSideChildren={
                        (jsonData !== undefined && jsonData !== null) ?
                            <Boolean
                                value={jsonData?.locked === null ? false : jsonData?.locked}
                                displayFalse={<Icon name={'lock-open'}/>}
                                displayTrue={<Icon name={'lock'}/>}
                                style={{backgroundColor: '#E2E2E2'}}
                                field={{name: 'debug'}}
                                setValue={(name, value) => {
                                    if(jsonData?.data?.locked !== null) {
                                        // Set locked value
                                        jsonData.data.locked = value;

                                        // Update calculation
                                        authAxios.put(`/calculations/${jsonData.id}`, jsonData).then(async ({data: returnData}) => {
                                            if(value){
                                                toast.warn(`Calculatie op slot gezet.`);
                                            }else{
                                                toast.success(`Calculatie van slot gehaald.`);
                                            }
                                        }).catch((exception) => {
                                            console.error(exception);
                                            toast.error(`Er ging iets mis met het bewerken van calculatie!`);
                                        }).finally(() => {
                                            callBack();
                                        });
                                    }
                                }}
                            />
                            : <></>
                }
                >
                    <Grid columns={1} gap='10px'>
                        <div>
                            <Button onClick={printCalculation} className='btn--black btn--icon-right'>
                                Print calculatie PDF<Icon name='arrow-down'/>
                            </Button>
                        </div>
                        {company !== null && company !== undefined ?
                            <div>
                                <strong>Klant</strong>: <Link to={`/crm/companies/${company.id}`}>{company.name}</Link>
                            </div>
                            : <></>}
                        {calculationNumber &&
                            <div>
                                <strong>Nummer</strong>: {calculationNumber}
                            </div>
                        }
                        <div>
                            <strong>Afmetingen </strong><span style={{color: 'gray'}}>(mm)</span>: {calculationDimensions?.width + " x " + calculationDimensions?.length}
                        </div>
                        {copiedFrom !== null && copiedFrom !== undefined ?
                            <div>
                                <strong>Revisie van</strong>: <Link to={`/calculations/${copiedFrom.id}/view`}>{copiedFrom.number}</Link>
                            </div>
                            : <></>}
                        <div>
                            <strong>Aantallen</strong>: {amounts?.join(', ')}
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px'
                        }}>
                            <IF condition={setCalculationStatus !== undefined}>
                                <label>
                                    <strong>Status</strong>:
                                </label>
                                <select
                                    value={calculationStatus ?? ''}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    onChange={({target}) => {
                                        setCalculationStatus(target.value);
                                    }}
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 0
                                    }}
                                >
                                    {statusses.map(({label, value, disabled}) => {
                                        return (
                                            <option key={value} value={value} disabled={disabled}>
                                                {label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </IF>
                        </div>
                    </Grid>
                </Block>
                {thirdPartyQuotes !== null && thirdPartyQuotes?.length !== 0 ? <Block name={'Inkoop'} title={'Inkoop'} useSmallerPadding={true}>
                    <Table
                        structure={['50%', '50%']}
                        data={thirdPartyQuotes}
                        overflowText={true}
                        border='row'
                        hover
                    />
                </Block> : <></>}
                {copyTableData.length !== 0 ?
                    <Block name={'Revisies'} title={'Revisies'} useSmallerPadding={true}>
                        <Table
                            structure={['145px', '100%']}
                            data={copyTableData}
                            overflowText={true}
                            border='row'
                            hover
                        />
                    </Block>
                    : <></>}
            </Grid>
            {hasChecklist ?
                <Block name={'checklist'} title={'Checklist'}>
                    <ul style={{listStyleType: 'none'}} className={'checklist'}>
                        {checklistTable}
                    </ul>
                </Block>
                : ''}
        </Grid>
    )
}

import React from 'react';
import './versions.scss';
import VersionBlock from "UI/App/Partials/Version/VersionBlock";

export default function Versions() {
    return (
        <div className={'version-container'}>
            <VersionBlock
                version={'2.3.10'}
                changes={[
                    '"Branch" placeholder in afleveradressen aangepast naar "Selecteer adres".',
                    '"Locatie manager" hernoemd naar "Aanspreekpunt op locatie" in afleveradressen.',
                    'Layout van het klant blok op de order verbeterd.'
                ]}
                features={[
                    'Optie toegevoegd om calculaties dicht te zetten voor aanpassingen.',
                    'Bij het inklokken op het dashboard zijn nu de huidige aanwezige mensen te zien.',
                    'Statussen "In proef", "Bestand afgekeurd (door klant)" en "Bestand afgekeurd (door Pretore)" toegevoegd voor orders.',
                    'Veld "Aanmaakdatum" toegevoegd aan orders.',
                    'Mogelijkheid toegevoegd om orders te sorteren op leverdatum.',
                    'Plannings icoon wordt nu rood weergegeven bij orders als er nog geen planning is gemaakt voor een order in "Binnengekomen" ,"Prepress" en "Productie".',
                    'Orders toegevoegd voor "Schoonmaken" en "Opruimen".',
                    'Blok toegevoegd op dashboard om uren te kunnen schrijven op projecten buiten de planning om.',
                    'Optioneel veld "Locatiemanager" toegevoegd bij vestigingen.',
                    'Bij het maken van een export komt dit nu in de logging terecht.',
                    'Marge bij calculaties word automatisch berekend op basis van het eerste resultaat.'
                ]}
                fixes={[
                    'Bug opgelost waardoor job tickets niet om konden gaan met herhalende variabelen.',
                    'Bug opgelost waardoor de pakbon bij het printen niet de juiste taal aannam.',
                ]}
            />

            <VersionBlock
                version={'2.3.9'}
                changes={[
                    'Het ontwerp van de orderbon is duidelijker gemaakt.'
                ]}
                features={[
                    'Order bevat nu een informatie veld.',
                    'Het is nu mogelijk om direct voor een afleveradres een pakbon aan te maken.'
                ]}
            />
            <VersionBlock
                version={'2.3.8.1'}
                fixes={[
                    'Bug opgelost waardoor het aanpassen van een standaard waarde niet altijd juist werkte.'
                ]}
            />
            <VersionBlock
                version={'2.3.8'}
                features={[
                    'Het is nu mogelijk om meerdere mensen tegelijk toe te voegen aan een machine binnen de planning.',
                    'het is nu mogelijk om voorraad te exporteren vanuit magazijnen.',
                    'Er is een personeel verantwoording toegevoegd bij de nacalculatie.',
                    'Bij de nacalculatie is op elk overzicht een verwijder filters knop toegevoegd.',
                    'Er is een export toegevoegd voor materiaal binnen de nacalculatie.',
                    'Er is een export toegevoegd voor uren verantwoording binnen de nacalculatie.',
                    'Een order kan nu meerdere verzend adressen bevatten.'
                ]}
                changes={[
                    'Telefoon nummer is nu optioneel op pakbonnen.',
                    'Op de order pagina is een informatie veld toegevoegd.',
                    'RFID veld is niet langer verplicht voor gebruikers.',
                    'Het gebruiker overzicht heeft nu een filter op actief/inactief.',
                    'Het gebruiker overzicht heeft nu een zoekfilter.'
                ]}
            />
            <VersionBlock
                version={'2.3.7'}
                features={[
                    'Bij inkoop bonnen kan nu een getekende inkoop bon geupload worden.',
                    'Bij inkoop bonnen kunnen nu producten gekozen worden.',
                    "Bij inkoop bonnen kunnen nu Request for quotation en purchase orders gemaakt worden"
                ]}
                changes={[
                    "Order bewerken geeft nu niet meer standaard 'Widget inlaad' popup.",
                    'Als een inkoop bon aan een materiaal hangt word deze nu ook getoond op het materiaal overzicht.'
                ]}
            />
            <VersionBlock
                version={'2.3.6'}
                features={[
                    'Order aantal is nu aanpasbaar voor administrators.',
                    'Job tickets vullen de klantreferentie nu automatisch in',
                    'Job tickets XML kan nu worden bewerkt.',
                    'Er kunnen opslag posities toegevoegd worden aan een magazijn.',
                    'Er is een aparte login gemaakt voor het bekijken van roosters.',
                    'Emily is nu beschikbaar in het Engels, Pools en Sloveens'
                ]}
                changes={[
                    'Inkoop bonnen worden niet meer aangemaakt bij een calculatie maar bij een order.',
                    'Sticker aanmaak button word nu disabled gezet tijdens het aanmaken van een sticker.',
                    'In en uit boeken van materialen ondersteunen opslag posities.',
                    'Nieuwe statussen voor inkoop bonnen toegevoegd.',
                    'Omschrijving veld toegevoegd aan werkderden bij de calculatie.',
                    'Interface van inkoopbonnen geupdate.'
                ]}
                fixes={[
                    'Bug opgelost waardoor de persoonlijke planning soms versprong.',
                    'Bug opgelost waar order bevestigingen soms niet meer werken.',
                    'Bug opgelost voor nestedkeys bij calculatie-template update.',
                    'Bug opgelost waar calculatie-template update checklist templates blijft updaten.',
                    'Bug opgelost waar transport niet altijd meegenomen werd in het calculatie resultaat.',
                    'Bij order aan maken worden nu meer dan 25 contactpersonen weergegeven.',
                    'Bug opgelost waardoor het aan maken van HJMG calculaties niet functioneerde.'
                ]}
            />
            <VersionBlock
                version={'2.3.5'}
                features={[
                    'Calculatie template checklists kunnen nu meerdere regels bevatten.',
                    'Order detail pagina laat nu status en leverdatum zien.',
                    'Jobtickets ondersteunen nu hoofdletters.',
                    "Order status 'Afgekeurd door Pretore' toegevoegd.",
                    'Job tickets zijn nu aan te maken via een API call.',
                    'Bij jobtickets zijn de order checklists zichtbaar gemaakt.',
                    'Stickers ondersteunen nu ook bold en cursief text.',
                    'Stickers kunnen nu barcodes aan maken.',
                ]}
                changes={[
                    'Tijdens het opslaan van offertes en bevestigingen worden geblokkeerd.',
                    'Alle menu items zijn evengroot gemaakt.',
                    'Order detail pagina toont nu ook leverdatum en status.'
                ]}
                fixes={[
                    'Het probleem met de planning op de grote(beamer) is opgelost.'
                ]}
            />
            <VersionBlock
                version={'2.3.4'}
                features={[
                    'Ophalen van orders mogelijk gemaakt voor portals.',
                    'Ophalen van gemaakte calculaties mogelijk gemaakt voor portals.',
                    'Filter toegevoegd om portal calculaties te verbergen of weer te geven.',
                    'Mogelijkheid toegevoegd om materialen filters uit te breiden voor een specifiek calculatie template.',
                    'Optie toegevoegd om webshop calculatie templates te koppelen aan een bedrijf.',
                    'Mogelijkheid toegevoegd om contact personen aan te maken vanuit een portal.',
                    'API endpoint om een order te maken toegevoegd.',
                    'Nacalculatie is nu bewerkbaar voor admins.',
                    'Mogelijkheid om een contactpersoon aan te maken via de portal API.',
                    'Mogelijkheid om een storing te melden bij een machine.'

                ]}
                changes={[
                    'Timers worden nu niet meer hervat als hij eenmaal gestopt is, maar er wordt een nieuwe timer gestart.',
                    'Portal calculaties hebben nu niet meer een verplicht bedrijf id, maar werken via een UUID.',
                ]}
                fixes={[
                    'Bug opgelost waardoor de dagelijkse planning niet kon worden geladen als er op een dag teveel orders waren ingepland.',
                    'Bug opgelost waardoor de nacalculatie de pauze niet van de geregistreerde uren aftrok.',
                    'Bug opgelost waardoor het bewerken van een calculatie niet altijd de materialen goed laadde.',
                    'Bug opgelost waardoor webshop calculaties optionele machines nog steeds liet zien in het resultaat.',
                    'Bug opgelost waardoor de offerte prijs aanpassen van een kopie niet goed werkte.',
                    'Bug opgelost waardoor offerte kopie & revisie een missende tekst hebben.',
                    'Bug opgelost waardoor bij het editen van template niet goed ging.',
                    'Bug opgelost waardoor de checklist niet meegenomen wordt op order.'
                ]}
            />

            <VersionBlock
                version={'2.3.3'}
                fixes={[
                    'Bug opgelost waar op het order formulier de verkeerde inschiet weer gegeven word.',
                    'Bug opgelost waar tijdens een materiaal export niet alle velden mee genomen werden.',
                    'Bug bij het afboeken van materiaal in het magazijn opgelost.'
                ]}
                changes={[
                    'Het overzicht van uren in de nacalculatie is geupdate.',
                    'Het overzicht van materiaal in de nacalculatie is geupdate.',
                    'Het overzicht van urenverantwoording is vele malen sneller gemaakt.',
                    'Dashboards zijn nu alleen nog zichtbaar voor de rollen admin en management.',
                    'Order aanmaken vanuit een offerte nu vele malen sneller.'
                ]}
                features={[
                    'Er is een popup toegevoegd met de checklist bij het aanmaken van de calculatie.',
                    'Totale materiaal kosten worden nu weergegeven bij het calculatie resultaat.'
                ]}
            />
            <VersionBlock
                version={'2.3.2'}
                fixes={[
                    'Bug opgelost waar webshop calculaties niet opgeslagen kunnen worden.',
                    'Bug opgelost waar de kosten niet terug kwamen bij het ophalen van een webshop calculatie'
                ]}
                changes={[
                    'Dashboards worden nu ingeladen via een iFrame. Dashboards kunnen nu weer geopend worden, maar alleen als de gebruiker hiervoor een licentie heeft.',
                    'Bij de dagelijkse planning veranderd nu de timer knop in een pauze knop als er al een timer loopt.',
                    'Bij de webshop calculatie worden inactieve materialen niet langer teruggegeven bij materiaal widgets.',
                    'Bij de webshop calculatie worden nu ook rolbreedtes en gramgewichten mee gegeven.',
                    'Labels aangepast binnen de planning.',
                    'In de planning bij het openen van een plan item word nu eerst de algemene checklist weer gegeven.',
                    'Bij het inklokken zijn de meldingen aangepast om beter te passen bij de gegeven melding.',
                    'Bij materiaal is de interne SKU nu verplicht om te werken met de webshop calculaties.',
                    'Volgorde van uren aangepast binnen de na calculatie.',
                    `Inkoop bon PDF aangepast hier staat nu 'HJ Media Groep B.V.' bij.`
                ]}
                features={[
                    'Bij de planning komt er nu een klokje tevoorschijn als er een timer loopt.',
                ]}
            />
            <VersionBlock
                version={'2.3.1'}
                features={[
                    'Timers kunnen nu worden gestart vanuit de dagelijkst planning.',
                    'Order timers kunnen nu vanuit de popup worden gestopt.',
                    'Order planningen kunnen nu verwijderd worden.',
                    'Terminals hebben nu een grafiekje met daarin de gemeten temperatuur en luchtvochtigheid.',
                    'Select widgets en materiaal widgets kunnen nu een standaard waarde krijgen bij het maken van een calculatie template.',
                    'Uren registratie heeft nu een overzichtspagina.'
                ]}
                fixes={[
                    'Bug opgelost waardoor af en toe meerdere timers gestart konden worden op één order item.',
                    'Bug opgelost waardoor het werk derden niet altijd zichtbaar was in het eenvoudige resultaat.',
                    'Bug opgelost waardoor machines niet altijd correct ingepland konden worden.',
                    'Bug opgelost waardoor machine status niet naar "Niet begonnen" kon worden in de planning.',
                    'De labels op de webshop calculatie template lijnen nu wel netjes uit.',
                    'Bug opgelost waardoor machines niet altijd verplaatst kunnen worden in calculatie templates.',
                    'Bug opgelost waardoor gekopiëerde offertes ook een underscore kregen bij het offertenummer.',
                    'Bug opgelost waardoor de webshop calculatie standaard waarden niet zag als geldig.',
                    'Bug opgelost waardoor de webshop calculatie checklist geen materiaal eigenschappen kon invullen.',
                    'Bug opgelost waardoor de layout editor in de webshop calculatie niet kon worden ingevuld.',
                    'Bug opgelost waardoor webshop calculatie templates niet konden worden opgeslagen vanuit de interface.',
                ]}
                changes={[
                    'Order timers kunnen nu niet meer gepauzeerd worden.',
                    'Order kopiëren verwijderd nu automatisch de planning voor de nieuwe order.',
                    'Lopende order timers laten nu een pauze icoontje zien op de order in plaats van een pijltje.',
                    'Het ophalen van een webshop template geeft nu alleen alle zichtbare en invulbare widgets terug.',
                    'Webshop template checklist laat nu alleen nog maar de zichtbare en invulbare widgets zien.',
                    'Formaat en aantal zijn nu eenvoudig invulbaar in de webshop template checklist.',
                    'Aantal is nu verplicht in webshop calculatie template.',
                ]}
            />
            <VersionBlock
                version={'2.3.0'}
                fixes={[
                    'Verantwoordelijken aanpassen bij een bedrijf, is nu vele malen sneller.',
                    'Bug opgelost waardoor een eenmaal ingevulde status in de planning niet meer leeg gemaakt kon worden.'
                ]}
                features={[
                    'Er kunnen checklists aangemaakt worden bij machine templates.',
                    'Dagelijkse planning bevat nu de order checklist en de nieuwe machine checklist.',
                    'In de planning kan nu worden ingevoerd hoe veel van het product er geproduceerd is.',
                    'Calculaties hebben nu zowel een eenvoudig resultaat, als een expert resultaat.',
                    'Op een order kunnen nu timers worden bijgehouden.',
                    'Bij een offerte is het nu mogelijk om deze te versturen onder een andere naam dan de naam van de gebruiker.',
                ]}
                changes={[
                    'De afsluiten van PDF documenten is verbeterd.'
                ]}
            />
            <VersionBlock
                version={'2.2.8'}
                fixes={[
                    'Pakbon zonder achtergrond werkt weer.',
                    'Inloggen stabieler gemaakt.',
                    'Planning kleur niet zichtbaar is opgelost.'
                ]}
                features={[
                    'Validatie template calculaties.'
                ]}
                changes={[
                    'Machine template tabs worden gecontroleerd op lege namen bij het opslaan.'
                ]}
            />
            <VersionBlock
                version={'2.2.7'}
                fixes={[
                    'Soms kon er geen contact persoon gekozen worden bij calculatie aanmaken.',
                    'Verschillende fouten opgelost bij het zoeken binnen het CRM.',
                    'Handwerk word niet goed mee geteld in het calculatie totaal.',
                    'Problemen opgelost bij het openen van orders.',
                    'Planning scrollt elke keer als de gebruiker opslaat.'
                ]}
                features={[
                    'Met dubbelklik kan de gebruiker nu een plan item toevoegen.',
                    'Zoek functie binnen Emily onthoud nu de laatste zoekopdracht.',
                    'Handwerk is nu zichtbaar op de orderzak.',
                    'In de planning is nu een filter optie op afgeronde taken.',
                    'Op een order is nu zichtbaar hoeveel vellen of meters een materiaal nodig heeft.'
                ]}
                changes={[
                    'Pakbonnen zijn nu zichtbaar in de order tabel.',
                    'Drempel waarde is nu zichtbaar in de materiaal tabel op een order.',
                    'Materiaal labels zijn nu in het Nederlands.',
                    'Pakbon achtergrond is aangepast naar briefpapier Pretore.'
                ]}
            />
            <VersionBlock
                version={'2.2.6'}
                fixes={[
                    'Bug opgelost bij het openen van een order met 6x IX2100.',
                    'Offerte kopiëren neemt voortgang niet mee.',
                    'Bij het hardcover template zijn problemen opgelost bij het calculatie resultaat.',
                    'inzoomen werkt nu goed op de planning.'
                ]}
                features={[
                    'Orders kunnen nu gekopieërd worden.',
                    "Bij de planning is een 'toggle' toegevoegd voor afgeronden taken.",
                    'Er is een update functie toegevoegd om widgets te updaten binnen templates.',
                    'Het beren van terminals is toegevoegd.',
                    'BIj materiaal een tooltip toegevoegd.',
                    'Drempel waarde toegevoegd aan materialen.',
                    'Machine data toegevoegd aan order overzicht.',
                    'Tijdlijn toegevoegd aan order overzicht.',
                    'Bij planning kan de gebruiker nu met dubbelklik plan items toevoegen.',
                    'Bij gebruikers is nu een rooster toe tevoegen.'
                ]}
                changes={[
                    'Bij de planning is nu op kaarten te zien over welk onderdeel het gaat.',
                    'Het berekenen van optionele machines is vele malen sneller.',
                    'Bij de planning zijn inactieve gebruikers uit de select gehaald.',
                    'Bij de dagelijkse planning zijn nu de machines opvolgorde.',
                    'Bij planning staat nu de huidige dag altijd bovenaan.'
                ]}
            />
            <VersionBlock
                version={'2.2.5'}
                fixes={[
                    'Bug opgelost waar terminal schermen een error gaven.',
                    'Planning popup is niet bewerkbaar.'
                ]}
                features={[
                    'Support voor alle Fontawsome iconen toegevoegd.',
                    'Uitbreidingen voor inloggen via terminal.',
                    'Dagplanning kan nu gesorteerd worden op prioriteit.',
                    'Bij een memo kan je nu meerdere bestanden toevoegen.'
                ]}
                changes={[
                    'Planning design verbetert met meer informatie op een planning item.',
                    'Admin nemen nu RFID invullen.',
                    'Planning popup sluit nu automatisch.',
                    'Order kopiëren neemt nu voortgang mee.',
                    'Bij het aanmaken van een order kan je klantreferentie toevoegen.'
                ]}
            />
            <VersionBlock
                version={'2.2.4'}
                features={[
                    'Dagelijkse personeels planning toegevoegd.',
                    'Opmerkingen toegevoegd aan planning items.',
                    'Kenmerk van persoon toegevoegd aan planning items.',
                    'Calculatie templates kunnen nu gedeactiveerd worden.',
                    'Materialen kunnen nu gedeactiveerd worden.',
                ]}
                changes={[
                    'Planning popup geupdate.',
                    'HJMG offerte design veranderd.',
                    'Template toegevoegd aan jobtickets.'
                ]}
                fixes={[
                    'Bij het mensen toewijzen in de planning verdwijnt de planning soms volledig.',
                    'Offerte prijs kan niet bewerkt worden met meerdere calculaties.',
                    'Bug gefixt in HJMG orders.'
                ]}
            />
            <VersionBlock
                version={'2.2.3'}
                features={[
                    'Zoeken in het koppelen van waardes in template editor mogelijk gemaakt.',
                    'De globale planning laat nu avatars zien van de mensen die gepland staan op die machine.',
                    'Er kunnen nu opmerkingen worden toegevoegd aan items in de planning.',
                    'Het is nu mogelijk om de prioriteit, voortgang, operators, tijd en opmerkingen van een item in de planning aan te passen in de globale planning.',
                    'Het is nu mogelijk om velden onzichtbaar te maken in de calculatie template editor.',
                    'Machines kunnen nu worden gedeactiveerd.',
                    'Machine templates kunnen nu worden gedeactiveerd.',
                ]}
                changes={[
                    'Checklist template toegevoegd aan jobtickets.',
                    'Layout van de HJ Media Groep offerte verbeterd.',
                    'Elke omgeving van Emily heeft nu zijn eigen kleur.',
                    'Het koppelen van machines is toegankelijker gemaakt.',
                ]}
                fixes={[
                    'Bug opgelost waardoor offertes niet konden worden opgeslagen als prijzen werden verwijderd of aangepast.',
                    'Bug opgelost waardoor HJ Media Groep orders en offertes een error kregen bij het openen.',
                ]}
            />
            <VersionBlock
                version={'2.2.2'}
                features={[
                    'Materialen kunnen nu actief en inactief gezet worden.',
                    'Calculatie templates kunnen nu actief en inactief gezet worden.',
                    'Select widgets renderen nu alleen als ze meer dan 1 optie hebben.'
                ]}
                changes={[
                    'Bucket id',
                    'Het updaten van machines is nu iets efficiënter.'
                ]}
                fixes={[
                    'Bij het wisselen van calculatie templates komt er soms een error naar boven.'
                ]}
            />
            <VersionBlock
                version={'2.2.1'}
                features={[
                    'Een lader toegevoegd aan het aanmaken van een calculatie.',
                    'Aantal op een order is nu aanpasbaar voor admin gebruikers.',
                    'Productie medewerkers zien alleen orders met een bepaalde status.'
                ]}
                changes={[
                    'Positie is niet langer een verplicht veld bij machines.'
                ]}
                fixes={[
                    'PowerBI dashboard geven soms een timeout error.',
                    'Kopie maken van een oude calculatie vult checklist niet in.'
                ]}
            />
            <VersionBlock
                version={'2.2.0'}
                features={[
                    'Calculatie templates hebben nu een checklist editor voor webshop templates.',
                    'Webshop calculaties betsaan nu uit twee opties Emily of API template',
                    'Webshop calculaties hebben nu een layout editor voor API templates.',
                    'De API kan nu calculaties berekenen.',
                    'Calculatie template widgets kunnen nu zichtbaar gezet worden.',
                    'Calculatie templates hebben nu instellingen voor handwerk,werkderden,marge en het formaat.',
                    'Er kunnen nu meerdere aantallen toegevoegd worden aan een calculatie template.'
                ]}
                changes={[
                    'De calculatie template lijst kan nu filteren op machines.',
                ]}
                fixes={[
                    'Offertes kunnen nu weer uit meerdere calculaties bestaan.'
                ]}
            />
            <VersionBlock
                version={'2.1.4'}
                features={[
                    'Er kan nu direct vanuit het CRM een calculatie gemaakt worden.',
                    'Er kan nu direct vanuit het CRM een offerte gemaakt worden.',
                    'Er kan nu direct vanuit het CRM een order gemaakt worden.',
                    'Er zijn verscheidene nieuwe filters toegevoegd aan orders, offertes en calculaties.',
                    'De calculatie geeft nu een waarschuwing als het template gewisseld wordt.',
                ]}
                changes={[
                    'Contact persoon functie is niet langer verplicht.',
                    'Order documenten openen niet langer in een nieuw tab.',
                    'Offerte afsluiting aangepast in NL en EN.',
                    'Bevestigings afsluiting aangepast in het nederlands.',
                    'De sticker tabel op de order pagina is nu opgeschoond.',
                    'Inkoop bon gebruikt nu het juiste aantal.',
                    'Job ticket tabel opgeschoond.',
                    'Wijzigingen in de leveringsvoorwaarden op de bevestiging en offerte.',
                    'Een inkoop bon laat nu niet meer onnodig 2 plus knoppen zien.'
                ]}
                fixes={[
                    'Order overzicht laat nu weer inkoop bonnen zien.',
                    'Inschiet en leverdatum word niet langer half gerenderd.',
                    'Aantal is nu weer zichtbaar bij bevestingen.',
                    'Dubbele voornamen worden nu beide met een hoofdletter weergegeven.',
                    'Order formulieren laden nu weer correct in.'
                ]}
            />
            <VersionBlock
                version={'2.1.3'}
                features={[
                    'Machine overzicht toegevoegd voor planning.',
                    'Maandelijkgs plan overzicht toegevoegd.',
                    'Volgorde van machines is nu aantepassen voor het plan overzicht.',
                    'Materialen zijn nu te kopieren.',
                    'Er kan in gezocht worden in order omschrijvingen.',
                    'Statussen van calculaties worden nu automatisch om gezet.',
                    'PowerBI token auto refresh toegevoegd.'
                ]}
                changes={[
                    'Joblogs tonen nu alleen nog maar  aanmaakdatum, naam en waarde.',
                    'Volgorde van icoontjes aangepast bij jobs.',
                    'Order aan maken popup toont nu [ordernummer] [klantnaam] - [Omschrijving] als label.',
                    'Opslaan weggehaald bij machine planning dit is nu view only.',
                    'Materiaal select in calculatie laat nu interne SKU zien achter opties.',
                    'Bij aangemaakte jobtickets worden nu templates bijgehouden.'
                ]}
                fixes={[
                    'Globaal plan overzicht kan nu opslaan en renderen.',
                    'Bug fix in globaal plan overzicht waar volgorde fout weer gegeven werd.',
                    'Bug fix bij het inplannen van een extra machine over het weekend.'
                ]}
            />
            <VersionBlock
                version={'2.1.2'}
                features={[
                    'Materiaal kan nu afgeboekt worden op de materiaal overzicht pagina.',
                    'Materialen hangen nu aan een leverancier.'
                ]}
                changes={[
                    'Verschillende bug fixes bij meldingen over materiaal eenheden.'
                ]}
                fixes={[
                    'Orders en offertes laden nu weer goed in op de klant overzicht pagina.',
                    'Meerdere kleine gebruiksvriendelijkheid problemen opgelost.'
                ]}
            />
            <VersionBlock
                version={'2.1.1'}
                features={[
                    'Bij een order kunnen nu materialen worden gereserveerd.',
                    'Materiaal houd nu een log bij die afgeboekt/gereserveerd en toegevoegd heeft.',
                    'Papier (vellen/rollen) boek je nu in al vellen/meter inplaats van KG.',
                    'Op een order overzicht kun je nu extra werk toevoegen.',
                    'Op het order overzicht word nu het totaal bedrag weer gegeven.'
                ]}
                changes={[
                    'Materiaal waarden worden nu weer gegeven als vellen/rollen etc inplaats van KG.',
                    'Materiaal verbruik word nu per order weer gegeven inplaats van globaal.',
                    'Op een orderbon word het aantal niet meer inclusief inschiet weer gegeven.',
                    'Omschrijving van order overzicht afgehaald.'
                ]}
            />
            <VersionBlock
                version={'2.1.0'}
                features={[
                    'Voorraad toegevoegd. bij materialen.',
                    'Magazijnen toegevoegd.',
                    'Bij order materialen kan nu materiaal worden afgeboekt uit een magazijn.'
                ]}
                changes={[
                    'Inkoop bonnen kunnen un aangemaakt worden zonder calculatie.',
                    'Logs worden nu beperkt tot de laatste 30 dagen om performance redenen.'
                ]}
                fixes={[
                    'Verschillende planning bugs opgelost.',
                ]}
            />
            <VersionBlock
                version={'2.0.12'}
                features={[
                    'Interface om magazijnen toe te voegen.',
                    'Bij materialen magazijnen toegevoegd.',
                    'Geschiedenis wat er met een materiaal gebeurd is toegevoegd.',
                    'Inkoop bonnen zijn nu aan te maken zonder calculatie.'
                ]}
                changes={[
                    'Planning items in globale planning zijn nu klikbaar en sturen de gebruiker door naar order planning.',
                    'Logs gelimiteerd tot 30 dagen terug.'
                ]}
                fixes={[
                    'Bug opgelost dat in het plan overzicht items niet op maandag zichtbaar waren.',
                ]}
            />
            <VersionBlock
                version={'2.0.11'}
                features={[
                    'Jobticket XML is nu downloadbaar.',
                    'Dashboard zoomed nu in tot juiste groote.'
                ]}
                changes={[
                    'Text overflow problemen opgelost in inkoop bonnen.',
                    'Sticker layout verbeterd met veel text.',
                    'Productie medewerker kan geen order bonnen meer aanpassen.',
                    'Calculatie opslaan problemen opgelost.',
                    `Order status 'Afgeboekt' toegevoegd.`,
                    'Validatie op handtekening uploaden toegevoegd.',
                    'CRM managing company exact nummer hernoemt naar KVK nummer.',
                    'Microsoft uit de planning gehaald.',
                    'Er zijn nu meer velden invulbaar bij een order bevestiging.'
                ]}
                fixes={[
                    'Prijs format problemen opgelost in offerte.',
                    'Vertaal problemen opgelost in offertes.',
                    'Klant referentie wordt niet weer gegeven.',
                    'HJMG calculatie laat altijd service blok zien.'
                ]}
            />
            <VersionBlock
                version={'2.0.10'}
                changes={[
                    "Todo's zijn nu correct gesorteerd.",
                    'Offerte plaats lijnt nu netjes uit.',
                ]}
                fixes={[
                    'Bug opgelost waar CRM data bij het bewerken niet goed',
                    'Log bij bedrijven kan buiten kaders lopen.',
                    'Sticker aanmaken payload is leeg.',
                    'Order statussen filteren niet goed.'
                ]}
            />
            <VersionBlock
                version={'2.0.9'}
                features={[
                    'HJMG Orders kunnen nu gemaakt worden inclusief .',
                    'Meerdere dashboards toegevoegd.',
                    'Dynamische HJMG checklists voor calculaties.'
                ]}
                changes={[
                    'Order overzicht geeft nu aantallen uit calculatie weer inplaats van uit checklist.',
                    'Order formulier in het zelfde tabblad renderen.',
                    'Uur tarieven (voor HJMG) zijn aanpasbaar gemaakt.',
                    'Vormgeving van het orderformulier gelijk getrokken.',
                ]}
                fixes={[
                    'Bug opgelost waar sommige plan items zonder eind datum leeg zijn.',
                    'Aanmaken van HJMG offerte gaat kapot.',
                    'Sticker rendert karakters met accenten niet goed.',
                    'Offerte opslaan geeft foutmelding.',
                    'Als je bij een terminal uitgelogd ben kan je niet meer inloggen.',
                    'Breadcrumbs bij calculatie bekijken geeft een error.',
                    'Bug opgelost waardoor de order niet juist om kon gaan met vrije calculaties.',
                ]}
            />
            <VersionBlock
                version={'2.0.8'}
                features={[
                    'Onderhoud, reserveringen en storingen kunnen worden ingepland.',
                    'Memo\'s en To-Do\'s kunnen nu worden ingeklapt.',
                    'De order omschrijving kan nu worden aangepast ook na het aanmaken van een order.',
                    'Inkoopbonnen hebben nu een extra veld voor het inkoop aantal.',
                ]}
                changes={[
                    'De offerte teksten boven en onderaan de PDF zijn nu aanpasbaar.',
                    'Diverse aanpassingen aan de manier waarop PDFs worden gegenereerd.',
                    'Styling aangepast van de Memo\'s en To-Do\'s.',
                    'Bij orders kan nu ook worden gezocht op de klantnaam.',
                ]}
                fixes={[
                    'Bug opgelost waardoor de opmerkingen in een order PDF van de pagina afliepen als ze te lang waren.',
                    'Bug opgelost waardoor de opmerkingen in een order PDF niet uitgelijnd waren met de lijntjes.',
                    'Bug opgelost waardoor de weergegeven status van een calculatie niet overeen kwam met de werkelijke status.',
                    'Bug opgelost waardoor bij het openen van Emily na een langere tijd een error werd weergegeven op het dashboard.',
                    'Bug opgelost waardoor bij het aanmaken van een calculatie met een inkoopbon een error onterecht werd weergegeven.',
                    'Bug opgelost waardoor de orderbon in een te lage resolutie werd afgedrukt.',
                ]}
            />
            <VersionBlock
                version={'2.0.7'}
                features={[
                    'Offerte bedrag word in calculatie gewijzigd als deze aangepast word bij offerte.',
                    'Een order kan nu met een calculatie zonder machines gemaakt worden voor onderhoud en storingen.'
                ]}
                changes={[
                    'Login aangepast voor Terminal login via token.',
                ]}
                fixes={[
                    'Order status verandering word niet gerenderd.',
                ]}
            />
            <VersionBlock
                version={'2.0.6'}
                changes={[
                    '"Memo\'s" heten nu "Team-memo\'s"',
                    'Het aanmaken van een order is nu intuïtiever en duidelijker.',
                    'De weergave van de order pdf is verbeterd.',
                    'Refactored order aan maken om bevestigingen met meerdere calculaties en projecten te ondersteunen.'
                ]}
                fixes={[
                    'Bug opgelost waardoor machine planning niet geopend kon worden.',
                    'Bug opgelost waardoor het soms niet mogelijk was om een order aan te maken.',
                    'Bug opgelost waardoor het voor sommige orders niet mogelijk was om een planning aan te maken.',
                    'Bug opgelost waardoor een to-do geen reminder had.',
                    'Bug opgelost waardoor het uploaden van een logo bij een bedrijf niet goed werkte.',
                    'Bug opgelost waardoor tabellen op de bedrijfspagina niet goed werden weergegeven als er teveel informatie in stond.'
                ]}
            />
            <VersionBlock
                version={'2.0.5'}
                features={[
                    "Order > Stickers hebben nu een upload functionaliteit.",
                    'Order formulieren kunnen nu bewerkt en opgeslagen worden.'
                ]}
                changes={[
                    'Responsiviteit verbeterd in het CRM, calculaties, orders en offertes.',
                    'Offerte, Bevestiging > Alle regels met een € en een $-teken rechts uit laten lijnen zoals bij offerte.'
                ]}
                fixes={[
                    'Bij het aan maken van planning is het dashboard menu item opgelicht.',
                    'Offerte revisies en kopieën worden calculaties niet goed ingeladen.',
                    'Job tickets icoontje blijft draaien.',
                    'Job tickets kunnen niet worden aangemaakt ondanks dat order een planning heeft.',
                    'Offerte text valt van de pagina af.',
                    'Enkel project plannen maandelijkse view werkt niet.',
                    'Bug bij het aan maken van een offerte.',
                    'calculaties worden 2 keer opgeslagen.'
                ]}
            />
            <VersionBlock
                version={'2.0.4'}
                features={[
                    "Handtekening word gerenderd onder aan PDF's.",
                ]}
                changes={[
                    'Hand tekening toevoegen aangezet op account pagina.',
                ]}
                fixes={[
                    'Bug opgelost waarbij orders niet goed toegevoegd werden aan een project',
                ]}
            />
            <VersionBlock
                version={'2.0.3'}
                features={[
                    'Order bevestiging heeft nu meerdere calculaties.',
                    'Meerdere rollen toegevoegd.',
                    'Globaal volg(Project) nummer toegevoegd.'
                ]}
                changes={[
                    'Alle documenten worden nu getoond bij een order.',
                    'Planning haalt nu de data vanuit strapi.',
                    'Inkoop bon toeslag standaard naar 0%.'
                ]}
                fixes={[
                    'Reset van waardes op calculatie resultaat.',
                    'Er word nog met 40% kosten gerekend bij het calculatie resultaat.',
                    'Job ticket haalt nu waarden weg van machines die niet gebruikt worden.',
                    'Job ticket rendert nu correct icoon als hij klaar is.',
                    'Dependencies werken nu in het plan overzicht.',
                    'Calculatie omschrijving mist in kop',
                    'De waardes van het calculatie resultaat worden nu wel juist opgeslagen.'
                ]}
            />
            <VersionBlock
                version={'2.0.2'}
                fixes={[
                    'Bug opgelost waardoor iets bewerken en toevoegen bij een bedrijf niet werkte.',
                    'Bug opgelost waardoor de kleurtjes om aan te geven van wie een bedrijf klant is, niet altijd goed werden weergegeven.',
                    'Bug opgelost waardoor bij sommige bedrijven met missende data de bedrijfspagina niet goed werd weergegeven.',
                    'Bug opgelost waardoor je bij het aan maken van een order zonder bevestiging data mist.'
                ]}
            />
            <VersionBlock
                version={'2.0.1'}
                changes={[
                    'Workflow standaard waarde is van 10 naar 0 gezet.',
                    'Calculatie- en offertenummers e.t.c. begint nu met 24.',
                    'Document titel aangepast voor bevestigingen.',
                    'X-Up toegevoegd aan order PDF.'
                ]}
                fixes={[
                    'Order bon werkt niet meer als er geen bevestiging aangekoppeld is.',
                    'Bug opgelost in contact persoon benaming tijdens het aanmaken van een order.'
                ]}
            />
            <VersionBlock
                version={'2.0.0'}
                features={[
                    'Stickers toegevoegd aan order overzicht.',
                    'Job tickets toegevoegd.',
                    'Sales managers dynamisch instelbaar.'
                ]}
                changes={[
                    'Inkoop bonnen titel niet aanpasbaar',
                    'Inkoopbon heeft een text veld voor extra data.'
                ]}
                fixes={[
                    'Orderstatus niet aanpasbaar.',
                    'Offerte opslaan werkt niet.',
                    'Algemene bugfixes.'
                ]}
            />
            <VersionBlock
                version={'1.9.1'}
                features={[
                    'Order aanmaken vanuit een offerte/calculatie of bevestiging is nu mogelijk.',
                    'Console log alleen beschikbaar in development.'
                ]}
                changes={[
                    'Offerte interface kopieën is aangepast naar revisies.',
                    'Klant kenmerk verwijderd van calculatie.',
                    'Order heeft nieuwe statussen.',
                    'Offerte layout aangepast naar nieuw design.',
                    'Order aangepast naar nieuw design.',
                    'Offerte bedrag ondersteund nu Dollar en Euro valuta.',
                    'pagina headers zijn gelijk getrokken.',
                    'Inkoop bon titel teksten zijn aangepast.',
                    'bedrijfsgegevens layout aangepast.',
                    'Pakbon gereviseerd er kan nu een bestand geüpload worden.',
                    'Inkoop standaard de datum van vandaag toevoegen.',
                    'CRM Memos gereviseerd.',
                    'Planning personen die niet aan een machine gehangen zijn kunnen nu toegevoegd worden.'
                ]}
                fixes={[
                    'Planning dependencies kunnen naar andere projecten.',
                    'Planning all opslaan werkt niet.',
                    'Bevestiging status aanpassen werkt niet.',
                    'Planning all individuele machine updaten werkt niet.',
                    'Planning dubbele haakjes popup weg halen.'
                ]}
            />
            <VersionBlock
                version={'1.9.0'}
                features={[
                    'Dependency pijlen toggle toegevoegd aan planning.',
                    'Maandelijkse view rendert nu dependencies.',
                    'Planning per dag kan er een verschillend aantal uren opgegeven worden.'
                ]}
                changes={[
                    'CMD+s slaat nu op bij planning.',
                    'Machine naam word mee genomen in elk plan blokje',
                    'Tooltip voor afgebroken blokjes',
                    'Planning general kleuren toegevoegd.',
                    'Planning general design duidelijker.',
                    'Dag planning design aangepast.',
                ]}
                fixes={[
                    'Planning geeft een 500 bij opslaan.',
                    'Conflicten renderen niet.',
                    'Operators toevoegen werkt niet.',
                    'Machine popup kan niet opslaan.'
                ]}
            />
            <VersionBlock
                version={'1.8.9'}
                features={[
                    'Toggle om relaties aan en uit te zetten in planning.',
                    'Relaties weer geven in maandelijks overzicht.',
                    'Uren invulbaar per dag in planning.'
                ]}
                changes={[
                    'Conflicten renderen in planning laat nu extra data zien.',
                    'Dagelijks plan overzicht aangepast.',
                    'Kopiëren en reviseren van calculaties/offertes en bevestigingen aangepast.',
                    'Planning items kunnen nu van meerdere andere items afhankelijk zijn.',
                    'Loader toegevoegd bij planning.',
                    'Plan items kunnen nu in meerdere dagen verdeeld worden.'
                ]}
                fixes={[
                    'Conflicten renderen in planning werkt nu weer.',
                    'Verschillende bugs opgelost bij gebruik van de planning.',
                    'Machine popup fix.',
                ]}
            />
            <VersionBlock
                version={'1.8.8'}
                features={[
                    'Dagelijks overzicht voor planning toegevoegd.',
                    'Afdelingen toegevoegd bij machines.'
                ]}
                changes={[
                    'Plan items kunnen opgesplitst worden over meerdere dagen.',
                    'Meerdere afhankelijkheden per plan item.',
                    'PLan items zijn nu instelbaar per dag inplaats van in totaal',
                    'Afhankelijkheid popup toegevoegd.',
                    'Planning rendert flow lines in plaats van dependency lines.',
                    'Offerte bedrag word nu los opgeslagen bij offertes.',
                    'Labels bij vestigingen aangepast.'
                ]}
                fixes={[
                    'Missende spatie opgelost bij calculaties',
                    'Handwerk/Werkderde werken niet bij planning door machine id.'
                ]}
            />
            <VersionBlock
                version={'1.8.7'}
                features={[
                    'Inkoop toegevoegd aan de HJ Media Groep calculatie.'
                ]}
                fixes={[
                    'Bug opgelost waardoor automatisch uitloggen nadat de sessie verliep, niet juist werkte.',
                    'Bug opgelost waardoor in sommige gevallen de pdf niet altijd een nieuwe pagina aanmaakte.',
                    'Bug opgelost waardoor geïmporteerde contact personen soms een tussenvoegsel van "null" hadden.',
                    'Bug opgelost waar inkoop niet inlaad bij het bewerken van een calculatie (HJMG/Pretore)'
                ]}
            />
            <VersionBlock
                version={'1.8.6'}
                features={[
                    'Inloggen gaat nu via PHP voor meer veiligheid.',
                ]}
                changes={[
                    'Reminder popup aangepast met duidelijkere veldnamen en meer stabiliteit.',
                    'Calculatie bewerken opent nu op het eerste tab in plaats van de vierde.',
                    'In de planning worden conflicten nu weergegeven.',
                    'Niet ingeplande machines worden nu ook weergegeven in de planning.',
                    'Planning machines text krijgt nu drie puntjes als de text te lang is.',
                ]}
                fixes={[
                    'Bij teveel machines op één dag gaat de hoogte nu mee zonder dat de machines over hun randen heen gaan.',
                    'Bug opgelost waardoor in de planning sommige machines verdwenen.'
                ]}
            />
            <VersionBlock
                version={'1.8.5'}
                features={[
                    'Planning een nieuw project aan maken als er nog geen bestaat.',
                    'Relatie tussen orders en planning gemaakt.',
                    'Personeelsplanning koppelen aan machine planning',
                    'Calculatie data opschonen voor gebruik in planning.'
                ]}
                changes={[
                    'Redesign voor inactiviteits popup.',
                    'Offerte overzicht redesign.',
                    'Materiaal omschrijving ipv offerte omschrijving.',
                    'Offerte aanmaken om naar nieuw design.',
                    'Nieuwe calculaties nemen line aantal mee in checklist.',
                    'Machine data word nu opgehaald uit Strapi.',
                    'Planning Weergeven van items die niet én beginnen én eindigen in dezelfde maand of week',
                    'Limiet op het aantal karakters binnen een plan-item',
                    'Basis kleur toewijzen aan plan-items',
                    'Startdatum meegeven waar vanaf gepland mag worden',
                    'Projecten/Machines toevoegen aan interface op datum',
                    'Naam/Projectnummer in planningsbalk voor overzicht'
                ]}
                fixes={[
                    'Popup overflow niet zichtbaar.',
                    'Popup om opnieuw in te loggen komt erg vaak',
                    'Werk derden opslaan gaat niet goed.',
                    'CRM filterbalk is niet goed responsive.',
                    'MSGRAPH nemen axios interceptors niet mee na refresh.',
                    "Orders multipage gaat niet goed.",
                    'PDF nieuwlines werken niet goed.',
                    'Order bevestiging laad niet alle waardes goed in.',
                    'Order bevestiging laad prijs niet goed in.',
                    'Offerte heeft een scrollbar.',
                    'Offerte laat niet duidelijk zien waar pagina eindigt.',
                    'planning machine popup reset operators opties niet als machine geen operators heeft in Strapi',
                    'Er zitten fouten in drag en drop systeem van de planning.'
                ]}
            />
            <VersionBlock
                version={'1.8.4'}
                changes={[
                    'Inkoop overzicht aangepast zodat het werkt met huidige data.',
                    'Detail pagina van inkoop bon toegevoegd.',
                    'Relatie van calculatie naar inkoop bon toegevoegd op calculatie detail pagina.'
                ]}
            />
            <VersionBlock
                version={'1.8.3'}
                features={[
                    'Leverancier toegevoegd aan het CRM overzicht inclusief filters.'
                ]}
                changes={[
                    'Checklist hoeveelheid weg gehaald op dashboard.',
                    'Button tooltip komt nu sneller en is customizable.'
                ]}
            />
            <VersionBlock
                version={'1.8.2'}
                features={[
                    'Eerste versie van de inkoop module.',
                    'Filter op managing company bij CRM Overzicht.',
                    'Gebruikers komen nu na inloggen weer terug op de pagina waar ze gebleven zijn.'
                ]}
                changes={[
                    'Endpoint maken voor het loggen van calculatie lock.',
                    'Materiaal velden aangepast om met de nieuwe data te werken.',
                    'Offertenaam gebruiken in materiaal widget.',
                    'Offerte statussen aangepast.',
                    'Calculatie statussen aangepast.',
                    'Bij vestiging gegevens klikbaar maken.'
                ]}
                fixes={[
                    'totalResultJSON word niet mee genomen bij het aanmaken van een calculatie dit zorgt voor problemen bij het aanmaken van een order.',
                    'Pakbon texten vallen van de pagina als ze te lang zijn.',
                    'HJMG Calcualtie na het kiezen van een print calculatie kan deze niet meer ongedaan worden.',
                    'Afronden van getallen bij HJMG calcualtie gaat niet goed.',
                    'Bij offerte bekijken is contactpersoon niet klikbaar.',
                    'Omschrijving word niet weergegeven op order.'
                ]}
            />
            <VersionBlock
                version={'1.8.1'}
                fixes={[
                    'Bug opgelost waardoor inloggen 2 keer in de log terecht kwam.',
                    'Bugs opgelost die boven water kwamen door de klanten van HJ Media Groep in te laden.'
                ]}
            />
            <VersionBlock
                version={'1.8'}
                features={[
                    'Calculaties hebben nu een project omschrijving.',
                    'In het CRM overzicht staan nu leveranciers, klanten, prospects en leads.',
                ]}
                changes={[
                    'Emily heeft nu een nieuwe vormgeving, die ook op kleinere schermen bruikbaar is.',
                    'Omschrijving is nu zichtbaar in het overzicht van calculaties.',
                    'Customers worden nu klanten genoemd',
                    'In het CRM is nu in één oogopslag te zien van wie een bedrijf een relatie is.'
                ]}
                fixes={[
                    'Bug opgelost waardoor het soms niet mogelijk was om een offerte te maken.',
                    'Bug opgelost waardoor het privé email van een contact persoon niet zichtbaar was',
                    'Bug opgelost waardoor het offerte bedrag van een HJ Media Groep calculatie niet altijd update.'
                ]}
            />
            <VersionBlock
                version={'1.7.6'}
                changes={[
                    'Project omschrijving van HJMG/Pretore calculaties word nu opgeslagen.',
                    'Klant toegevoegd bij calculatie overzicht.',
                    'Toevoegen bij leverancier.',
                    'CRM overzicht neemt nu managing company mee.'

                ]}
                fixes={[
                    'Offerte bedrag HJMG calculatie word niet automatisch ingevuld.',
                    'Materiaal geeft bij catalogus niet altijd de juiste naam.',
                    'Archief voor pakbonnen mist.'
                ]}
            />
            <VersionBlock
                version={'1.7.5'}
                changes={[
                    'Opslaan knop toegevoegd bij Pretore calculatie.'
                ]}
                fixes={[
                    'Horizontale scrollbar bij tabellen.',
                    'Offerte bedrag vult niet in bij HJ media groep calculatie.',
                    'Verschillende bug fixes in het ontwerp.'
                ]}
            />
            <VersionBlock
                version={'1.7.4'}
                fixes={[
                    'Form error meldingen bij calculatie opnieuw toegevoegd.',
                    'Product lengte naar product hoogte veranderd.'
                ]}
            />
            <VersionBlock
                version={'1.7.3'}
                changes={[
                    'Blauwe balletje weg gehaald bij verplicht machines voor calculatie Pretore.',
                    'Blauw block headers weg gehaald bij Pretore calculatie'
                ]}
                fixes={[
                    'Machines die optioneel zijn en gekozen worden niet weer gegeven bij het bewerken van een calculatie.',
                    'Menu is niet scrollbaar op kleine schermgrote'
                ]}
            />
            <VersionBlock
                version={'1.7.2'}
                changes={[
                    'Accountmanagers hoeven (tijdelijk) geen handtekening meer te hebben.',
                    'Materiaal import aangepast zodat het ook werkt met de nieuwe kolom namen.',
                ]}
            />
            <VersionBlock
                version={'1.7.1'}
                changes={[
                    'Afdeling verwijderd bij machines.',
                    'Juiste naam bij leads toegevoegd.',
                    'HJMG Calculatie omgebouwd naar nieuw design.'
                ]}
                fixes={[
                    'Version popup opent niet.',
                    'ALs een bedrijf een http website heeft word deze gerenderd als https website.',
                    'Leveranciers ophalen werkt niet.',
                    'HJMG calculatie gaat kapot als je direct naar totaal gaat.',
                    'Calculatie overzicht gaat kapot als calculaties geen owner hebben.'
                ]}
            />
            <VersionBlock
                version={'1.7'}
                features={[
                    'HJ Media Groep calculatie toegevoegd inclusief PDF Print.',
                ]}
                changes={[
                    'Bedrijfs tags opnieuw geïmplementeerd.',
                    'Tabel ontwerp bijgewerkt in heel emily.',
                    'Calculatie stappen veranderd naar nieuwer stappen.',
                    'Calculatie ontwerp veranderd naar nieuw design.',
                    'Calculatie overzicht heeft nu ook HJ media groep calculaties'
                ]}
                fixes={[
                    'Offerte bedrag word niet correct mee genomen bij het aanmaken van een calculatie.',
                    'Pagina titels bijgewerkt zodat ze kloppen met de content.',
                    'Overzicht pagina CRM responsive niet leesbaar opgelost.',
                ]}
            />
            <VersionBlock
                version={'1.6.16'}
                fixes={[
                    'Bug opgelost waardoor er altijd een scrollbar zichtbaar was in het navigatie menu.',
                    'Bug opgelost waardoor alle notities die geïmporteerd zijn als bijgewerkt gemarkeerd werden.',
                    'Bug opgelost waardoor eventuele errors bij het aanmaken niet altijd een titel hadden',
                    'Bug opgelost waardoor verantwoordelijken bij een bedrijf niet konden worden opgeslagen als één van de verantwoordelijken inactief was.',
                    'Bug opgelost waardoor de layout van de bedrijfspagina soms niet goed werd weergegeven.',
                    'Bug opgelost waardoor de landcode van het bedrijf niet werd meegenomen bij het aanmaken van een nieuwe vestiging.',
                ]}
                changes={[
                    'Checklists tabel verwijderd van de bedrijfspagina.',
                    'Log geeft nu parameters en query weer.',
                    'Terug knop op de bewerkpagina van een contactpersoon stuurt nu terug naar de contactpersoon pagina in plaats van de bedrijfspagina.',
                    'Diverse styling aanpassingen.'
                ]}
            />

            <VersionBlock
                version={'1.6.15'}
                fixes={[
                    'Niet ingevulde telefoon nummers worden niet meer weergegeven als undefined.',
                    'Aanmaken van een bedrijf of leverancier zonder telefoonnummer is nu weer mogelijk.',
                    'Achtergrond aanpassingen aan notities voor o.a. beveiliging.'
                ]}
                changes={[
                    'Zoeken op payload, log type en user werkt nu via de pagination standaard.',
                    'Log laadt payload niet meer standaard in om performance te verbeteren.',
                    'De pagina titel voor het toevoegen van nieuwe items is veranderd van "Aanmaken" naar "Toevoegen" zodat dit overal hetzelfde is.',
                    'Achtergrond aanpassingen aan de offerte pagina om performance te verbeteren.',
                ]}
            />

            <VersionBlock
                version={'1.6.14'}
                features={[
                    'Bij het maken van een bevestiging/order een melding geven als klant type niet customer is.',
                ]}
                changes={[
                    'CRM export neemt nu notities mee.',
                ]}
                fixes={[
                    'Bij het aan maken van een template staat select over de button er onder.',
                    'Order pagination werkt niet opgelost.',
                    'Bij log zijn alle users niet langer meer unauthenticated.',
                    'Bewerken van een calculatie zet active machines weer actief.'
                ]}
            />

            <VersionBlock
                version={'1.6.13'}
                changes={[
                    'Bugfixes en verbeteringen op de achtergrond.',
                ]}
            />

            <VersionBlock
                version={'1.6.12'}
                features={[
                    'Versie popup toegevoegd.',
                    'Functie om alle constants te updaten in alle templates toegevoegd.'
                ]}
            />

            <VersionBlock
                version={'1.6.8'}
                fixes={[
                    'Bedrijfsoverzicht vestigingen.',
                    'Overzicht CRM reset niet altijd filters.'
                ]}
                features={[
                    'Email veld toegevoegd bij vestigingen.',
                    'Pakbon: heeft nu achtergronden.',
                    'Pakbon: Prefix is nu PAC in plaats van PAK.',
                    'Calculaties hebben nu statussen (Afgerond, In bewerking, In afwachting).',
                    'Bij orders komt de Klant referentie nu terug op elke pagina.',
                    'Widget edit heeft nu een formule render functie om op machine niveau te kunnen testen.'
                ]}
            />
    </div>
    )
}

import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import VestigingDetailItem from './VestigingDetailItem'
import Block from 'UI/App/Components/Block/Block';
import Grid from 'UI/App/Components/Grid/Grid';
import Button from 'UI/App/Components/Button/Button';
import Icon from 'UI/App/Components/Icon/Icon';
import {useContext} from 'react';
import {FetchContext} from 'App/Strapi/FetchContext';
import {toast} from "react-toastify";

export default function VestigingDetails({ vestiging }) {
    const { authAxios } = useContext(FetchContext);
    const navigate = useNavigate()

    // get the params from the url
    const params = useParams();

    // check if on suppliers path
    const location = useLocation();
    const isSupplier = /\/suppliers\//.test(location.pathname);

    function deleteBranch() {
        if (window.confirm('Weet u zeker dat u deze vestiging wilt verwijderen?')) {
            authAxios.delete(`/crm/companies/branches/${params.branchId}`).then(({ data }) => {
                toast.success('Vestiging verwijderd.');
                navigate(`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${params.companyId}`);
            }).catch((reason) => {
                toast.error('Het verwijderen van de vestiging is mislukt!');
                console.error(reason);
            });
        }
    }

    return (
        <Block name='details' title={<>
            <Link className={'btn btn--transparent'} to={`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${params.companyId}`}><Icon name={'arrow-left'} /></Link>
            Vestigingsgegevens
        </>} headerRightSideChildren={
            <>
                <div className="company__list-actions">
                    <Button to={`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${params.companyId}/branch/${params.branchId}/update`} className='btn btn--round btn--black'><Icon name={'pencil'} /></Button>
                    <Button onClick={deleteBranch} className='btn btn--round btn--black '><Icon name={'trash-can'} /></Button>
                </div>
            </>
        }>
            <Grid columns={vestiging?.tags ? 2 : 1}>
                {vestiging?.company && <div key='1'>
                    <VestigingDetailItem title='Bedrijf' display={vestiging.company || vestiging.supplier} content={[
                        <Link to={`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${params.companyId}`}>{!isSupplier ? vestiging?.company?.name : vestiging?.supplier?.name}</Link>,
                    ]} />

                    <VestigingDetailItem title='Locatiemanager' display={vestiging.locationManager} content={[
                        <p>{vestiging.locationManager}</p>
                    ]} />

                    <VestigingDetailItem title='Naam Vestiging' display={vestiging.name} content={[
                        <p>{vestiging.name}</p>
                    ]} />

                    <VestigingDetailItem title='Email' display={vestiging.email} content={[
                        <Link to={'https://outlook.office.com/?path=/mail/action/compose&to=' + vestiging.email} target='_blank' rel='noreferrer'>{vestiging.email}</Link>,
                    ]} />

                    <VestigingDetailItem title='Adres' display={vestiging?.address} content={[
                        <p>{vestiging.address.addressLine1}</p>,
                        vestiging.address?.addressLine2 ? <p>{vestiging.address?.addressLine2}</p> : null,
                        vestiging.address?.addressLine3 ? <p>{vestiging.address?.addressLine3}</p> : null,
                        <p>{vestiging.address.postalCode} {vestiging.address.city}</p>,
                        <p>{vestiging.address.province && vestiging.address.province + ', '}{vestiging.address.country}</p>,
                    ]} />

                    <VestigingDetailItem title='Telefoon' display={vestiging?.telephone} content={[
                        <Link to={`tel: +${vestiging.telephone?.dialingCode} ${vestiging.telephone?.telephoneNumber}`}>+{vestiging.telephone?.dialingCode} {vestiging.telephone?.telephoneNumber}</Link>,
                    ]} />
                </div>
                }
            </Grid>
        </Block>
    )
}

import { useEffect, useState } from "react";
import Select from "react-select";
import { getCountriesFlags } from "../countries";
import './translate.scss';

// define the wanted languages
const usedLanguages = [
    {
        value: "nl",
        label: "Nederlands",
        image: "nl",
    },
    {
        value: "en",
        label: "English",
        image: "gb",
    },
    {
        value: "pl",
        label: "Polski",
        image: "pl",
    },
    {
        value: "sl",
        label: "Slovenski",
        image: "sk",
    }
]

export function GoogleTranslateWidget(withSelector = false) {
    useEffect(() => {
        // prep the script to be added
        const addScript = document.createElement('script');
        addScript.setAttribute(
            'src',
            '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
        );

        // add the script to the doc
        document.body.appendChild(addScript);

        // init the google element
        window.googleTranslateElementInit = () => {
            window.googleTranslator = new window.google.translate.TranslateElement(
                {
                    pageLanguage: 'nl',
                    // layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
                    // autoDisplay: false,
                    includedLanguages: usedLanguages.map((el) => el.value).join(","),
                },
                'google_translate_element' // elements with this calls will become the dropdown selector
            );
        };
    }, [])

    if (!withSelector) {
        return <div className="google_translate_element"></div>
    }

    return null;
}

export function LanguageSelector() {
    const [startLang, setStartLang] = useState(undefined);
    const [flags, setFlags] = useState(undefined);
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "var(--medium-gray)"
        }),
        menu: base => ({
            ...base
        }),
        menuList: base => ({
            ...base,
            background: "var(--medium-gray)",
        })
    };

    useEffect(() => {
        setFlags(getCountriesFlags);
        setStartLang(readCookie('googtrans')?.split("/")[2] ?? "nl")
    }, [])

    function setCookie(cName, cValue, exDays) {
        let d = new Date(),
            expires = exDays;
        if (typeof exDays === 'number') {
            d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
            expires = "expires=" + d.toUTCString();
        }

        const domain = window.location.hostname.split('.').slice(-2).join('.')
        const subDomain = window.location.hostname.split('.').slice(-3).join('.')

        document.cookie = `${cName}=${cValue}; Path=/; ${expires};`;
        document.cookie = `${cName}=${cValue}; Path=/; ${expires}; Domain=${domain}`;
        document.cookie = `${cName}=${cValue}; Path=/; ${expires}; Domain=${subDomain};`;
    }

    function triggerEvent(element, eventName) {
        const event = new Event(eventName);
        element.dispatchEvent(event);
    }

    function readCookie(name) {
        let c = document.cookie.split('; '),
            cookies = {}, i, C;

        for (i = c.length - 1; i >= 0; i--) {
            C = c[i].split('=');
            cookies[C[0]] = C[1];
        }

        return cookies[name];
    }

    function clearCookie(name) {
        const domain = window.location.hostname.split('.').slice(-2).join('.')
        const subDomain = window.location.hostname.split('.').slice(-3).join('.')
        document.cookie = name +`=; Path=/; MaxAge=0;`;
        document.cookie = name +`=; Path=/; MaxAge=0; Domain=${domain}`;
        document.cookie = name +`=; Path=/; MaxAge=0; Domain=${subDomain};`;
    }

    function onChange(e) {
        // set the Google Translate cookie with the right value, keep track of the value for frontend
        if (e.value === "nl") {

            // bool to keep track if we restored the page already
            let restored = false;

            // go through all things inside the translator
            Object.keys(window.googleTranslator).forEach((k) => {
                if (restored) {
                    return;
                }
                // if we have a restore function on this entry call it
                if (typeof window.googleTranslator[k]?.restore === 'function') {
                    window.googleTranslator[k].restore();
                    restored = true;
                }
            });

            // empty cookie so we don't go back to old language on refresh
            clearCookie('googtrans');
            setStartLang(e.value)

        } else {
            setCookie('googtrans', `/nl/${e.value}`, 'Session')
            setStartLang(e.value)
        }

        // wait for cookie to be set before we 'update'
        const timeoutId = setTimeout(() => {
            if (e.value === "nl") {
                // empty the value so we don't go back to old lang
                // get the Google element and set the lang value
                document.querySelector('.goog-te-combo').value = ""
                // empty cookie so we don't go back to old language on refresh
                clearCookie('googtrans');
            } else {
                // get the google element and set the lang value
                document.querySelector('.goog-te-combo').value = e.value
                // trigger the change element
                triggerEvent(document.querySelector('.goog-te-combo'), 'change');
            }
        }, 100);

        return () => clearTimeout(timeoutId);
    }

    return (
        <div className="lang__selector" style={{ zIndex: "400" }}>
            <div style={{ display: "hidden" }} id="google_translate_element" ></div>

            <Select
                style={{ minWidth: "150px", zIndex: "1" }}
                value={usedLanguages.find((el) => el.value === startLang)}
                options={usedLanguages}
                formatOptionLabel={value => (<img style={{ width: "25px" }} src={flags.find((el) => el.value === value.image)?.flag} alt="" />)}
                styles={customStyles}
                onChange={(e) => { onChange(e) }}
                isSearchable={false}
            />
        </div>
    )
}


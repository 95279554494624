import {useCallback, useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import CalculationResult from "./result/CalculationResult";
import {Link, useNavigate, useParams} from "react-router-dom";
import Icon from "UI/App/Components/Icon/Icon";
import {toast} from "react-toastify";
import PageHeader from "UI/App/Components/PageHeader/PageHeader";
import {stringify} from "qs";
import Button from "../../../../Components/Button/Button";
import Popup, {closePopup, openPopup} from "../../../../Components/Popup/Popup";
import HasRole from "UI/App/Components/Auth/HasRole";
import useWindowDimensions from "../../../../../../App/Util/useWindowDimensions";
import EditConfirmationPopup from "./EditConfirmationPopup";

export default function ViewCalculation() {
    const [totalRawData, setTotalRawData] = useState([]);
    const {width, height} = useWindowDimensions();
    const [amounts, setAmounts] = useState([]);
    const [checklistContent, setChecklistContent] = useState('');
    const [calculationNumber, setCalculationNumber] = useState('');
    const [calculationStatus, setCalculationStatus] = useState(null);
    const [totalResultJson, setTotalResultJson] = useState(null);
    const [rawTemplateJson, setRawTemplateJson] = useState(null);
    const [copiedFrom, setCopiedFrom] = useState(null);
    const [subTotal, setSubTotal] = useState(null);
    const [company, setCompany] = useState(null);
    const [values, setValues] = useState(null);
    const [status, setStatus] = useState('');
    const [copies, setCopies] = useState([]);
    const [data, setData] = useState(null);
    const [name, setName] = useState('');
    const [language, setLanguage] = useState('');
    const navigate = useNavigate();
    const [calculation,setCalculation] = useState(null);
    const [calculationData,setCalculationData] = useState(null);

    const {authAxios} = useContext(FetchContext);

    const params = useParams();
    const [json,setJson] = useState([]);

    useEffect(() => {
        void load();
    }, []);

    function updateJson(changedJson){
        setData(changedJson.data.data)
    }


    async function load() {
        const query = stringify({
            populate: {
                contactPerson: true,
                company: true,
                copiedFrom: true,
                locked:true,
                copies: true,
                purchaseQuotes: {
                    populate: {
                        quantities: true,
                        supplier: {
                            fields: ['id', 'name']
                        },
                        contactPerson: {
                            fields: ['id']
                        }
                    },
                },
                lockedBy: {
                    fields: [
                        'firstName',
                        'email'
                    ]
                }
            }
        }, {
            encodeValuesOnly: true,
        });

        authAxios.get(`/calculations/${params.calculationId}?${query}`).then(({data: {data}}) => {
            setJson({data:data})
            data.data.thirdParty = [
                ...(data.data?.thirdParty ?? []),
                ...(data?.purchaseQuotes ?? [])
            ];


            /**
             * Set necessary data
             */
            setCalculation(data.data)
            setAmounts(data.data.amounts);
            setTotalRawData(data.data)
            setName(data.customerReference ?? data.name ?? ``);
            setCalculationNumber(data.number);
            setCalculationStatus(data.status);
            setRawTemplateJson(data.data.templateData);
            setValues(data.data.values);
            setSubTotal(data.data.totals);
            setData(data.data);
            setCompany(data.company);
            setLanguage(data.language);
            setCalculationData(data);

            setChecklistContent(data.data.checklist);

            setCopies(data.copies);
            setCopiedFrom(data.copiedFrom);


        }).catch((exception) => {
            console.error(exception);
        });
    }

    const updateStatus = useCallback((newStatus) => {
        const oldStatus = calculationStatus;
        setCalculationStatus(newStatus);
        authAxios.put(`/calculations/${params.calculationId}`, {
            data: {
                status: newStatus
            }
        }).then(() => {
            toast.success(`Calculatie status omgezet naar ${newStatus}!`);
        }).catch(() => {
            toast.error(`Er ging iets mis met het omzetten van de status!`);
            setCalculationStatus(oldStatus);
        });
    }, [authAxios, params.calculationId])

    const storeCalculation = () => {
        let dataForStorage = {};

        dataForStorage.data = data;
        dataForStorage.status = calculationStatus;

        authAxios.put(`/calculations/${params.calculationId}`, {
            data: dataForStorage
        }).then(({data}) => {
            toast.success(`Calculatie ${data.data.number} bewerkt!`);
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het bewerken van calculatie!`);
        });
    }

    return (
        <>
            <Popup popupID='CopyOrRevision' title={'Kopie of revisie'}>
                <Button onClick={() => {
                    closePopup('CopyOrRevision')
                    navigate(`/calculations/${params.calculationId}/copy`)
                }}>
                    Kopie
                </Button>
                <Button onClick={() => {
                    closePopup('CopyOrRevision')
                    navigate(`/calculations/${params.calculationId}/revision`)
                }}>
                    Revisie
                </Button>
            </Popup>
            <EditConfirmationPopup calculationId={params.calculationId}/>
            <PageHeader color={'primary'} sticky={true} title={`${calculationNumber} | ${name}`}>
                <HasRole roles={['admin', 'management', 'productie manager']}>
                    {width > 500 &&
                        <>
                            {calculation?.isWebshopCalculation === true ? (calculationData?.locked !== null && calculationData?.locked) ?
                                <Button disabled data-title={'Bewerken geblokkeerd'} className={'btn btn--round btn--black'}>
                                    <Icon name={'fa-solid pencil-slash'}/>
                                </Button>
                                :   <Button data-title={'Bewerken'} className={'btn btn--round btn--black'} onClick={() => openPopup('editConfirmationMessage')}>
                                        <Icon name={'pencil'}/>
                                    </Button>
                            :<HasRole roles={['admin']}>
                                <Button data-title={'Bewerken'} className={'btn btn--round btn--black'} onClick={() => openPopup('editConfirmationMessage')}>
                                    <Icon name={'pencil'}/>
                                </Button>
                            </HasRole>}

                        <Button data-title={'Calculatie kopiëren'} className={'btn btn--black btn--round'} onClick={() => {
                            openPopup("CopyOrRevision")
                        }}><Icon name={'copy'}/></Button>
                        </>
                    }
                    <Link data-title={'Offerte'} to={`/quotes/create/${params.calculationId}`} className='btn btn--black btn--round'><Icon name={'offer-solid'}/></Link>
                    <button disabled={calculationData?.locked === true} data-title={calculationData?.locked === true ? 'Bewerken geblokkeerd' : 'Opslaan'} onClick={() => {
                        if (calculationData?.locked !== true)
                            storeCalculation()
                    }} className={'btn btn--round btn--black'}>
                        {calculationData?.locked === true ?
                            <Icon name={'fa-solid floppy-disk-circle-xmark'}/>
                        : <Icon name={'save'}/>}

                    </button>
                </HasRole>
            </PageHeader>

            <br/>
            <CalculationResult
                status={status}
                setStatus={setStatus}
                company={company}
                renderInfo={true}
                checklistContent={checklistContent}
                language={language?.toLowerCase()}
                values={values}
                setTotalResultJson={setTotalResultJson}
                rawTemplateJson={rawTemplateJson}
                subTotal={subTotal}
                amounts={amounts}
                data={data}
                setData={setData}
                calculationNumber={calculationNumber}
                calculationStatus={calculationStatus}
                setCalculationStatus={updateStatus}
                copies={copies}
                copiedFrom={copiedFrom}
                calculation={calculation}
                jsonData={calculationData}
                callBack={load}
                updateJson={updateJson}
                json={json}
            />
        </>
    )
}

import CompanyDetail from './CompanyDetail';
import './companyDetails.scss';

//import components
import Button from "UI/App/Components/Button/Button";
import Icon from "UI/App/Components/Icon/Icon";
import Title from "UI/App/Components/Title/Title";

import capitalize from 'lodash/capitalize';
import {useLocation, useParams} from 'react-router-dom';
import {useContext, useEffect, useState} from 'react';
import {FetchContext} from 'App/Strapi/FetchContext';
import {toast} from 'react-toastify';
import Block from 'UI/App/Components/Block/Block';
import Grid from 'UI/App/Components/Grid/Grid';
import {spinnerPrimary} from "UI/App/Components/Spinner";
import BooleanSwitch from "UI/App/Components/Form/Boolean";
import IF from "UI/App/Components/Conditional/IF";
import useWindowDimensions from 'App/Util/useWindowDimensions';
import HasRole from "UI/App/Components/Auth/HasRole";

export default function CompanyDetails({company}) {
    const {width} = useWindowDimensions();

    const {authAxios} = useContext(FetchContext);

    // check if on suppliers path
    const location = useLocation();
    const isSupplier = /\/suppliers\//.test(location.pathname);

    const params = useParams();
    const [tagGroups, setTagGroups] = useState([]);
    const [img, setImg] = useState(false);

    useEffect(() => {
        authAxios.get(`/config/crm`, {
            params: {
                populate: "*"
            }
        }).then(({data: {data}}) => {
            setTagGroups(() => {
                return (data?.companyTags ?? []).map((tagGroup) => {
                    return tagGroup.groupName;
                });
            });
        });

        if (company.logo?.id) {
            setImg(<img width="30px" height="30px" src={spinnerPrimary} alt='logo'/>);

            // Get company logo
            authAxios.get("../download/files/" + company.logo?.id, {
                responseType: 'arraybuffer',
            }).then((response) => {
                let url;
                if (response.headers['content-type'] === 'image/svg+xml') {
                    url = window.URL.createObjectURL(new Blob([response.data], {type: 'image/svg+xml'}));
                } else {
                    url = window.URL.createObjectURL(new Blob([response.data]));
                }
                const imgElement = <img width="200px" height="200px" src={url} alt='logo'/>;
                setImg(imgElement);
            }).catch((error) => {
                setImg(<p><span style={{color: 'var(--text-error)'}}>Kon het logo niet ophalen</span></p>);

                process.env.NODE_ENV === 'development' && console.error(error);
            });
        } else {
            setImg(<p>Geen logo</p>);
        }
    }, [])

    // build the phone number for this company
    const phoneNumber = `+${company?.telephone?.dialingCode} ${company?.telephone?.telephoneNumber}`;

    // always prepend https to the website url
    const websiteURL = (company?.website.startsWith("http:") ? 'http://' : 'https://') + company?.website?.replace(/https?:\/\//, '');

    function setArchived(archived) {
        const putCompanyPath = !isSupplier ? `/crm/companies/${params.companyId}` : `/crm/suppliers/${params.companyId}`

        authAxios.put(putCompanyPath, {
            data: {
                archived
            }
        }).then(({data: {data}}) => {
            toast.success(`Bedrijf ${data.archived ? 'gearchiveerd' : 'gedearchiveerd'}.`);
        }).catch((exception) => {
            toast.error(`${archived ? 'Archiveren' : 'Dearchiveren'} mislukt! ${exception?.response?.status ? `(${exception.response.status})` : ''}`);

            console.error(exception);
        });
    }

    return (
        <>
            <Block name='details' title='Bedrijfsgegevens' headerRightSideChildren={<Button to={'update'} className='btn btn--round btn--black'><Icon name='pencil'/></Button>}>
                {/* Company name */}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'space-between'
                    }}
                >
                    <Title className={"notranslate"} type='h3'>{company?.name}</Title>
                    <div className='owner-indicator'>
                        <IF condition={company?.managingCompany?.HJMG}>
                            <span className='circle__big circle-hjmg' />
                        </IF>
                        <IF condition={company?.managingCompany?.Pretore}>
                            <span className='circle__big circle-pretore' />
                        </IF>
                    </div>
                </div>

                <Grid columns={width > 600 ? 2 : 1} gap='0 var(--gap-l)'>
                    <div key='1' className='company__column'>
                        <IF condition={(company?.managingCompany) && (company?.managingCompany?.HJMG || company?.managingCompany?.Pretore)}>
                            <CompanyDetail key='btw' title='btw' display={company?.BTW} content={[
                                company?.BTW
                            ]} />
                        </IF>

                        {/* Address */}
                        <CompanyDetail key={`adres-${company?.address?.id}`} title='adres' display={company?.address} content={[
                            company?.address?.addressLine1,
                            company?.address?.addressLine2,
                            company?.address?.addressLine3,
                            `${company?.address?.postalCode} ${company?.address?.city}`,
                            company?.address?.province,
                            company?.address?.country
                        ]}/>

                        {/* General email */}
                        <CompanyDetail key='email' title='email' display={company?.generalEmail} content={[
                            <a href={'https://outlook.office.com/?path=/mail/action/compose&to=' + company?.generalEmail} target='_blank' rel='noreferrer'>{company?.generalEmail}</a>
                        ]}/>

                        {/* General email */}
                        <CompanyDetail key='invoiceEmail' title='Facturatie e-mailadres' display={company?.invoiceEmail} content={[
                            <a href={'https://outlook.office.com/?path=/mail/action/compose&to=' + company?.invoiceEmail} target='_blank' rel='noreferrer'>{company?.invoiceEmail}</a>
                        ]}/>

                        {/* Phone number */}
                        <CompanyDetail key='telefoon' title='telefoon' display={company?.telephone !== null && company?.telephone?.telephoneNumber && company?.telephone?.dialingCode} content={[
                            <a href={`tel: ${phoneNumber}`}>{phoneNumber}</a>
                        ]}/>

                        <HasRole roles={['admin']} >
                            <CompanyDetail key='UUID' title='UUID' display={company?.UUID} content={[
                                <small>{company.UUID}</small>
                            ]}/>
                        </HasRole>
                    </div>
                    <div key='2' className='company__column'>
                        {/* Tags */}
                        {company?.tags && Object.keys(company?.tags ?? {}).map((tagGroup) => {
                            return tagGroups.includes(tagGroup) && <CompanyDetail key={`tags__${tagGroup}`} title={tagGroup} display={company.tags} content={
                                // check if tagGroup values = string or array
                                (Array.isArray(company?.tags[tagGroup]) ? [...company?.tags[tagGroup]] : [company?.tags[tagGroup]]).map((tag, key) => {
                                    return <p key={key}>{tag}</p>
                                })
                            }/>
                        })}

                        {/* Type */}
                        <CompanyDetail key='type' title='type' display={!isSupplier && company?.type} content={[
                            capitalize(company?.type)
                        ]}/>

                        <IF condition={company.managingCompany?.HJMG || company.managingCompany?.Pretore}>
                            <CompanyDetail key='Exact' title='Klant van' content={[
                                company.managingCompany?.HJMG ? `HJ Media Groep ${company?.managingCompany?.exactHJMG ? `(${company?.managingCompany?.exactHJMG})` : ''}` : '',
                                company.managingCompany?.Pretore ? `Pretore ${company?.managingCompany?.exactPretore ? `(${company?.managingCompany?.exactPretore})` : ''}` : ''
                            ]}/>
                        </IF>
                        {/* Is Archived */}
                        <CompanyDetail key='archived' title='status' display={company.archived !== undefined} content={[
                            <BooleanSwitch displayFalse={'Gearchiveerd'} displayTrue={'Actief'} value={!company.archived} nullable={false} field={{name: 'archived'}} falseIsRed={true} setValue={(name, value) => setArchived(!value)} style={{transform: `scale(${width > 300 ? 0.75 : 0.65})`, transformOrigin: 'left center'}}/>
                        ]}/>

                        <CompanyDetail key='logo' title='logo' content={[img]}/>
                    </div>
                    {/* Logo */}
                </Grid>
            </Block>
        </>
    )
}

import Title from "UI/App/Components/Title/Title";
import Grid from "UI/App/Components/Grid/Grid";
import CompanyDetail from "UI/App/Partials/Content/CRM/Companies/Company/Blocks/CompanyDetails/CompanyDetail";
import Block from "UI/App/Components/Block/Block";
import { formatName } from "App/Util/format";
import { useEffect, useState } from 'react';
import IF from 'UI/App/Components/Conditional/IF';

export default function CompanyBlock({ calculation, company = {}, contactPerson = null, otherEntries = [], children, ...props }) {
    const [telephoneNumber, setTelephoneNumber] = useState('');

    useEffect(() => {
        if (company?.telephone?.dialingCode !== null && typeof company?.telephone?.telephoneNumber === 'string') {
            setTelephoneNumber(`+${company?.telephone?.dialingCode} ${company?.telephone?.telephoneNumber}`.trim());
        }
    }, [company?.telephone]);

    return (
        <Block
            name='Klant'
            title='Klant'
            {...props}
        >
            {/* Company name */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    justifyContent: 'space-between',
                    marginBottom: '10px'
                }}
            >
                <Title type='h3' className={"notranslate"}>{company?.name}</Title>
                <div className='owner-indicator'>
                    <IF condition={company?.managingCompany?.HJMG}>
                        <span className='circle__big circle-hjmg' />
                    </IF>
                    <IF condition={company?.managingCompany?.Pretore}>
                        <span className='circle__big circle-pretore' />
                    </IF>
                </div>
            </div>

            <Grid columns='1'>
                <div key='1' className='company__column'>

                    {/* Contact person */}
                    <CompanyDetail
                        display={contactPerson}
                        title='Contact persoon'
                        content={[
                            formatName(contactPerson?.firstName, contactPerson?.prefixToSurname, contactPerson?.surname)
                        ]}
                    />

                    {/* Contact person email */}
                    <CompanyDetail
                        display={contactPerson?.email}
                        title='Email contactpersoon'
                        content={[
                            <a href={'https://outlook.office.com/?path=/mail/action/compose&to=' + contactPerson?.email} target='_blank' rel='noreferrer'>
                                {contactPerson?.email}
                            </a>
                        ]}
                    />

                    {/* General email */}
                    <CompanyDetail
                        display={company?.invoiceEmail}
                        title='Facturatie e-mailadres'
                        content={[
                            <a href={'https://outlook.office.com/?path=/mail/action/compose&to=' + company?.invoiceEmail} target='_blank' rel='noreferrer'>
                                {company?.invoiceEmail}
                            </a>
                        ]}
                    />

                    {/* Phone number */}
                    <CompanyDetail
                        display={telephoneNumber}
                        title='telefoon'
                        content={[
                            <a href={`tel: ${telephoneNumber}`}>
                                {telephoneNumber}
                            </a>
                        ]}
                    />

                    {otherEntries.map((entry, index) => (
                        <CompanyDetail
                            key={index}
                            title={entry.title}
                            content={[
                                entry.content
                            ]}
                        />
                    ))}

                    {children}
                </div>
            </Grid>
        </Block>
    )
}

import './dailyPlanning.scss'
import './planning.scss'
import {useContext, useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {FetchContext} from "App/Strapi/FetchContext";
import Grid from "UI/App/Components/Grid/Grid";
import Block from "UI/App/Components/Block/Block";
import Icon from "UI/App/Components/Icon/Icon";
import {formatDateWithoutTime, formatTime} from "App/Util/format";
import {SpinnerOverlay} from "UI/App/Components/Spinner";
import UpdateMachinePlanningPopup from "UI/App/Partials/Content/Planning/UpdateMachinePlanningPopup";
import {toast} from "react-toastify";
import {addOperatorsToMachine} from "UI/App/Partials/Content/Planning/Planning";
import {openPopup} from "UI/App/Components/Popup/Popup";
import HasRole from "UI/App/Components/Auth/HasRole";
import Select from "react-select";
import User from "App/Strapi/User";
import capitalize from "lodash/capitalize";
import {getPriorityName, getProducedAmountLabelAndClass} from "UI/App/Partials/Content/Planning/GeneralPlanning";
import useWindowDimensions from "App/Util/useWindowDimensions";
import qs from "qs";

export const _PLANNING_PROGRESS_FILTERS = [
    { label: 'Alle', value: '*' },
    { label: 'Niet begonnen', value: 'Niet begonnen' },
    { label: 'Mee bezig', value: 'Mee bezig' },
    { label: 'Afgerond', value: 'Afgerond' }
];

export default function PersonalPlanning() {
    const { authAxios} = useContext(FetchContext);
    const user = User.get();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);

    const [popupFormData, setPopupFormData] = useState({});
    const [currentMachine, setCurrentMachine] = useState({});
    const [updatedTimer, setUpdatedTimer] = useState(undefined);

    const [userProjects, setUserProjects] = useState(null);

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(user.id);

    const [selectedProgressFilter, setSelectedProgressFilter] = useState('*');
    const [filtersOpen, setFiltersOpen] = useState(false);

    const { width } = useWindowDimensions();

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowRight') {
                e.preventDefault();
                nextDay();
            } else if (e.key === 'ArrowLeft') {
                e.preventDefault();
                previousDay();
            }
        }

        const handleDocumentClick = (e) => {
            if (e.target.id === 'emily-overlay' && filtersOpen) {
                setFiltersOpen(false);
            }
        }

        document.addEventListener('keyup', handleKeyDown);
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('keyup', handleKeyDown);
            document.removeEventListener('click', handleDocumentClick);
        }
    }, [startDate, endDate, filtersOpen]);

    useEffect(() => {
        const overlay = document.getElementById('emily-overlay');

        if (!overlay) return;

        if (filtersOpen) {
            overlay.style.display = 'block';
        } else {
            overlay.style.display = 'none';
        }
    }, [filtersOpen]);

    useEffect(() => {
        void load();
    }, [selectedUser])

    useEffect(() => {
        if (updatedTimer !== undefined) {
            void load(startDate, endDate);
        }
    }, [updatedTimer])

    useEffect(() => {
        void generateGrid();
    }, [selectedUser, startDate, endDate])

    async function generateGrid() {
        setIsLoading(true);

        if (startDate === null || endDate === null) {
            return;
        }

        // Get machine data from API
        const machineData = await loadMachineData();

        let grid = [];

        let dates = [];
        for (let d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
            dates.push(new Date(d));
        }


        // Loop machines
        let y = 0;
        let x = 0;
        // Used to choose colors
        let planProjects = [];

        for (const date of dates) {
            const formattedDate = new Date(date).toISOString().split('T')[0];
            x = 0;
            if (grid[y] === undefined) {
                grid[y] = {
                    date: formattedDate,
                }
            }

            // Add machines names
            let machines = [];
            for (const [machineKey, machineEntry] of Object.entries(machineData)) {
                let machine = {
                    machineId: machineKey,
                    name: machineEntry.name,
                    templatePart: machineEntry.templatePart,
                    projectKey: machineEntry.projectKey,
                    date: '',
                    project: []
                }

                for (const planningItem of machineEntry.planning) {
                    const itemName = planningItem.description;
                    for (const planning of planningItem.machine.planning) {
                        if (planning.date === formattedDate) {
                            machine.project.push({
                                id: planningItem.id,
                                name: itemName,
                                company: planningItem.company,
                                time: planning.time,
                                number: planningItem.number,
                                priority: planning.priority,
                                templatePart: planningItem.templatePart,
                                person: planning.persons[0]
                            });
                        }
                    }
                }

                machines.push(machine);
                x++;
            }

            if (grid[y]['machines'] === undefined) {
                grid[y]['machines'] = machines;
            }

            y++;
        }

        setIsLoading(false);
    }

    async function loadAllMachines() {

        return await authAxios.get(`/calculations/resources/machines/all?sort[1]=position:asc`, {
            params: {
                populate: "*"
            }
        }).then(({data}) => {
            let machines = {};

            for (const machine of data) {
                machines[machine.id] = machine
            }

            // Order machines
            let orderedMachines = [];
            let orderlessMachines = [];
            Object.keys(machines).forEach(function (key, index) {
                if (machines[key]?.position !== null) {
                    orderedMachines[machines[key]?.position] = machines[key];
                } else {
                    orderlessMachines.push(machines[key]);
                }
            });

            const allMachines = orderedMachines.concat(orderlessMachines);

            allMachines.push({
                id: 'handwerk',
                name: 'Handwerk',
                position: Math.max(...allMachines.map(machine => machine.position).filter(position => position)) + 1,
            })

            return allMachines;
        }).catch((exception) => {
            console.error(exception);
        });
    }

    async function loadMachineData() {

        // Get all machines to use for machine data
        const allMachines = await loadAllMachines();
        let machineData = {};

        const projectData = await loadProjects(startDate, endDate);
        const projectMachines = {};

        const timerData = await loadProjectTimers(projectData.map((el) => { return el.id }));

        for (const project of projectData) {
            for (const planningMachine of project.machines) {
                let runningTimer = undefined;
                const machine = allMachines.find(machine => machine?.id === planningMachine.machineId);

                if (machine === undefined) {
                    continue;
                }

                if (!projectMachines[machine.id]) {
                    projectMachines[machine.id] = {
                        machine: machine,
                        planItems: []
                    }
                }

                // check user timers for one that belongs to this project
                for (const timer of timerData) {
                    if (timer?.timerFor === planningMachine?.name) {
                        runningTimer = timer;
                    }

                }
                for (const planItem of planningMachine.planning) {
                    if (formatDateWithoutTime(planItem.date) !== formatDateWithoutTime(startDate.toISOString())) {
                        continue;
                    }

                    projectMachines[machine.id].planItems.push({
                        project: {
                            id: project.id,
                            number: project.number,
                            company: project.company,
                            description: project.description,
                        },
                        machineName: planningMachine.name,
                        machineChecklist: planningMachine.checklist,
                        orderChecklist: project.orderChecklist,
                        date: planItem.date,
                        time: planItem.time,
                        totalHours: planningMachine.totalHours,
                        persons: planItem.persons,
                        uuid: planItem.uuid,
                        priority: planItem.priority,
                        progress: planItem.progress,
                        note: planItem.note,
                        id: planItem.id,
                        quota: getProducedAmountLabelAndClass(planItem.amountProduced, project.amount, planItem.time, planningMachine.totalHours),
                        runningTimer: runningTimer,
                        amount: project.amount,
                        amountProduced: planItem.amountProduced
                    })
                }
            }
        }

        setUserProjects(projectMachines)

        for (const machine of allMachines) {
            // Skip undefined keys
            if (machine === undefined || machine.position === null) continue;

            let projectKey = 1;
            // Loop project data to add to planning overview
            for (const project of projectData) {
                // Loop machines in project
                for (const projectMachine of project.machines) {

                    // If project machine matches current machine
                    if (machine.id === projectMachine.machineId) {

                        // Build planning date object
                        const dateObject = {
                            machine: projectMachine,
                            company: project?.company ?? false,
                            description: <span><b>[{project.number}]</b> {(project?.company ?? <i>Geen klant</i>)}</span>,
                            number: project.number,
                            templatePart: project.description,
                            id: project.id,
                        }

                        // If key does not exist create else push planning
                        if (machineData[machine.position] === undefined) {
                            machineData[machine.position] = {
                                planning: [dateObject],
                                name: machine.name,
                                templatePart: project.description,
                                projectKey: projectKey
                            };
                        } else {
                            machineData[machine.position].planning.push(dateObject)
                        }
                    }
                }
                projectKey++;
            }
        }


        return machineData;
    }

    function getFirstDayOfWeek(d) {
        d = new Date(d);
        let day = d.getUTCDay(),
            diff = d.getUTCDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setUTCDate(diff));
    }

    async function load(startDate = new Date(), endDate = new Date()) {

        setStartDate(startDate);
        setEndDate(endDate);

        await loadMachineData(startDate, endDate);

        const users = await getUsers();
        setUsers(users?.map(user => ({
            label: user.username,
            value: user.id,
            user: user
        })) ?? []);

        setIsLoading(false);
    }

    async function getUsers() {
        return authAxios.get(`../users/all/limited`).then(({ data }) => {
            return data.filter((el) => el.blocked === false);
        }).catch((exception) => {
            console.error(exception);
        });
    }

    async function loadProjects(start, end) {
        start = new Date(start).toISOString().split('T')[0];
        end = new Date(end).toISOString().split('T')[0];

        return await authAxios.get(`/orders/planning?startDate=${start}&endDate=${end}&userId=${selectedUser}`)
            .then(({data}) => {
                return data
            }).catch((exception) => {
                console.error(exception);
            });
    }

    async function loadProjectTimers(projects) {
        if (!Array.isArray(projects)) return;

        if (projects.length === 0) {
            return [];
        }

        const query = qs.stringify({
            orders: projects
        }, { arrayFormat: 'comma', encodeValuesOnly: true });

        return await authAxios.get(`/orders/timers/running?${query}`).then(({ data }) => {
            return data
        }).catch((error) => {
            toast.error("Er is een fout opgetreden met het ophalen van informatie")
            console.error(error);
        });
    }

    function nextDay() {
        const date = new Date(startDate);

        date.setDate(date.getDate() + 1);

        setStartDate(date);
        setEndDate(date);
    }

    function previousDay() {
        const date = new Date(startDate);

        date.setDate(date.getDate() - 1);

        setStartDate(date);
        setEndDate(date);
    }

    function onUpdateMachine(e) {
        if (e?.preventDefault) {
            e.preventDefault();
        }

        const pendingToast = toast.loading('Planning aan het bijwerken...');

        const url = `/orders/${currentMachine.orderId}/planning/${currentMachine.uuid}`;
        const body = {}

        // If not all selected persons can operate the machine, ask for confirmation on whether to assign the persons
        if (popupFormData.operators && popupFormData.operators.some(person => !person.canOperate) && currentMachine.machineId !== 'handwerk') {
            if (window.confirm('Niet alle geselecteerde personen kunnen deze machine bedienen. Weet u zeker dat u deze personen wilt toewijzen?')) {
                addOperatorsToMachine(authAxios, popupFormData?.operators.filter(({canOperate}) => !canOperate), currentMachine.machineId, (operators) => {
                    setCurrentMachine((machine) => {
                        // machine.operators = operators;
                        return machine;
                    })
                });
            }
        }

        if (popupFormData.operators) {
            body.persons = popupFormData.operators.map(person => person.value);
        }

        if (popupFormData.priority) {
            body.priority = popupFormData.priority.value;
        }

        if (popupFormData.progress) {
            body.progress = popupFormData.progress.value;
        }

        if (popupFormData.time) {
            body.time = popupFormData.time;
        }

        if (popupFormData.note) {
            body.note = popupFormData.note;
        }

        if (popupFormData.amount) {
            body.amountProduced = Number(popupFormData.amount);
        }

        authAxios.put(url, body)
            .then((response) => {
                toast.update(pendingToast, {
                    render: 'Planning bijgewerkt!',
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000,
                });

                // void load();
                void generateGrid();
            })
            .catch((exception) => {
                console.error(exception);

                toast.update(pendingToast, {
                    render: 'Planning bijwerken mislukt!',
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000,
                });
            });
    }

    function getProgressName(progress) {
        if (progress === 0) {
            return 'Niet begonnen';
        } else if (progress === 100) {
            return 'Afgerond';
        } else {
            return 'Mee bezig';
        }
    }

    return (
        <>
            <UpdateMachinePlanningPopup
                onSubmit={onUpdateMachine}
                popupCurrentMachine={currentMachine}
                popupFormData={popupFormData}
                setPopupFormData={setPopupFormData}
                employeesLocked={true}
                setParentTimer={setUpdatedTimer}
            />
            <Block
                name={'dagPlanning'}
                key={'dagPlanning'}
                headerStyle={{ overflow: 'visible' }}
                title={`Dag planning ${(users.find(user => user.value === selectedUser)?.user?.firstName ?? user?.firstName ?? user?.username)}`}
                headerRightSideChildren={
                    <>
                        {width <= 750 && (
                            <button
                                id={'toggle-planning-filters'}
                                onClick={() => {
                                    setFiltersOpen((open) => !open);
                                }}
                            >
                                <Icon name={'filter'} />
                            </button>
                        )}
                
                        <Grid
                            className={filtersOpen && 'open'}
                            id={'daily-planning__filters'}
                            columns={{
                                750: 1,
                                default: 2
                            }}
                            customColTemplate={
                                (User.hasRole(['admin', 'management', 'productie manager']) ? {
                                    1300: '250px 250px',
                                    950: '200px 250px',
                                    900: '150px 200px',
                                    800: '150px 150px',
                                    750: '300px',
                                    default: '300px 300px'
                                }
                                :
                                {
                                    default: '300px'
                                })
                            }
                            gap='10px'
                        >
                            <Select
                                name='progressFilter'
                                id='daily-planning__progress-filter'
                                options={_PLANNING_PROGRESS_FILTERS}
                                value={_PLANNING_PROGRESS_FILTERS.find((filter) => filter.value === selectedProgressFilter)}
                                onChange={(selectedProgressFilter) => {
                                    setSelectedProgressFilter(selectedProgressFilter.value);
                                }}
                                styles={{
                                    menu: (provided) => ({ ...provided, zIndex: 9999 })
                                }}
                            />
                            
                            <HasRole roles={['admin', 'management', 'productie manager']}>
                                <Select
                                    name='user'
                                    id='daily-planning__user'
                                    isClearable={false}
                                    options={users}
                                    value={users.find((user) => user.value === selectedUser)}
                                    onChange={(selectedUser) => {
                                        setSelectedUser(selectedUser.value);
                                    }}
                                    styles={{
                                        menu: (provided) => ({ ...provided, zIndex: 9999, color: 'black' })
                                    }}
                                />
                            </HasRole>
                            
                            <button id={'daily-planning__close-filters'} onClick={() => setFiltersOpen(false)}>
                                Sluiten
                            </button>
                        </Grid>
                
                        <div className='daily-planning__date-selector'>
                            <div className={'daily-planning__date-button'} onClick={() => previousDay('PREV')}>
                                <Icon name='chevron-left' className='planning__header__date__content__icon__button' />
                            </div>
                            
                            <span className='daily-planning__date-text'>
                                <span>{new Date(startDate).toLocaleDateString('nl-NL', { month: 'short', day: 'numeric' })}</span>
                                <span>{new Date(endDate).toLocaleDateString('nl-NL', { year: 'numeric' })}</span>
                            </span>
                            
                            <div className={'daily-planning__date-button'} onClick={() => nextDay('NEXT')}>
                                <Icon name='chevron-right' className='planning__header__date__content__icon__button' />
                            </div>
                        </div>
                    </>
                }
            >
                <SpinnerOverlay key={'spinner'} visible={isLoading}>
                    <div className='daily-planning__container'>
                        <Grid gap={'0px'} colums={2} customColTemplate={'1fr 4fr'}>
                            <div className={'daily-planning__machine'}>
                                <b>Machine</b>
                            </div>
                            <div className={'daily-planning__machine'}>
                                <b>Taken</b>
                            </div>

                            {userProjects !== null &&
                                Object.entries(userProjects)
                                    // Sort the machines by position
                                    .sort(([_, a], [__, b]) => a.machine.position - b.machine.position)
                                    // Filter out any machines that don't have any plan items
                                    .filter(([machineId, machine]) => machine.planItems.length > 0)
                                    // Filter out any machines that don't have any plan items for the selected progress filter
                                    .filter(([machineId, machine]) => selectedProgressFilter !== '*' ? machine.planItems.some(item => getProgressName(item.progress).toLowerCase() === selectedProgressFilter.toLowerCase()) : true)
                                    .map(([machineId, {machine, planItems}], key) => <>
                                        <div key={`sunday-${key}`} className={`daily-planning__date`}>
                                            {machine.name}
                                        </div>
                                        <div key={`machineContainer-${key}`} className={`daily-planning__day`}>
                                            {planItems
                                                // Filter out the projects that don't match the selected progress filter
                                                .filter((item) => selectedProgressFilter !== '*' ? getProgressName(item.progress).toLowerCase() === selectedProgressFilter.toLowerCase() : true)
                                                // Sort the projects by priority
                                                .sort((a, b) => a.priority === b.priority ? a.project.id === b.project.id ? a.id - b.id : a.project.id - b.project.id : a.priority - b.priority)
                                                // Map the projects to a grid item
                                                .map((planItem, key) =>
                                                    <Grid
                                                        gap={'0px'}
                                                        className={`bg-${(planItem.project.number.match(/[1-9][0-9]*$/)?.[0] ?? 0) % 19 + 1}-two-tone daily-planning__project`}
                                                        onClick={() => {
                                                            setCurrentMachine({
                                                                machineId: machine.id,
                                                                machineName: planItem.machineName,
                                                                orderId: planItem.project.id,
                                                                name: machine.name,
                                                                uuid: planItem.uuid,
                                                                time: planItem.time,
                                                                totalHours: planItem.time,
                                                                priority: planItem.priority,
                                                                progress: planItem.progress,
                                                                persons: planItem.persons.map(person => person.id),
                                                                planningId: planItem.id,
                                                                note: planItem.note,
                                                                machineChecklist: planItem.machineChecklist,
                                                                orderChecklist: planItem.orderChecklist,
                                                                amount: planItem.amount,
                                                                amountProduced: planItem.amountProduced,
                                                                runningTimer: planItem.runningTimer?.user?.id === user?.id ? planItem.runningTimer : undefined
                                                            });

                                                            openPopup('draggable-click-popup');
                                                        }}
                                                    >
                                                        <Link
                                                            to={User.hasRole(['productie medewerker', 'sales']) ? `/orders/${planItem.project?.id}` : `/planning/orders/${planItem.project?.id}`}
                                                            key={key}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <b>[{planItem.project.number}] {planItem.project?.company}</b>
                                                        </Link>
                                                        <div className={'daily-planning__project__content'}>
                                                            <div>
                                                                <span>
                                                                    {(planItem.project.description || <i>Geen omschrijving</i>)}&nbsp;({formatTime(planItem.time)})
                                                                </span>
                                                            </div>

                                                            <div className={'daily-planning__middle-content'}>
                                                                <span style={{ textAlign: "left" }}>
                                                                    Actie: {planItem.machineName}
                                                                </span>
                                                            </div>
                                                            <div className={'daily-planning__bottom-content'}>
                                                                <div>

                                                                    {/* Priority */}
                                                                    <div
                                                                        className={'btn daily-planning__priority ' + getPriorityName(planItem.priority).en}
                                                                        data-title={'Prioriteit: ' + capitalize(getPriorityName(planItem.priority).nl)}
                                                                    >
                                                                        {capitalize(getPriorityName(planItem.priority).nl)}
                                                                    </div>

                                                                    {/* Status */}
                                                                    <div
                                                                        className={'btn daily-planning__progress ' + (planItem.progress === 0 ? 'not-started' : planItem.progress === 100 ? 'completed' : 'in-progress')}
                                                                        data-title={planItem.progress === 0 ? 'Niet begonnen' : planItem.progress === 100 ? 'Afgerond' : 'Mee bezig'}
                                                                    >
                                                                        <span>{planItem.progress === 0 ? 'Niet begonnen' : planItem.progress === 100 ? 'Afgerond' : 'Mee bezig'}</span>
                                                                    </div>

                                                                    {/* Quota */}
                                                                    <div
                                                                        className={`btn daily-planning__quantity ${planItem.quota?.class}`}
                                                                    >
                                                                        {planItem?.quota?.label}
                                                                    </div>

                                                                    {/* Note */}
                                                                    {planItem.note && planItem.note.length > 0  &&
                                                                        <div
                                                                            className={'btn daily-planning__icon'}
                                                                            data-title={planItem?.note.slice(0, 50) + (planItem?.note.length > 50 ? '...' : '')}
                                                                        >
                                                                            <Icon name={'fa-regular message-lines'} />
                                                                        </div>
                                                                    }

                                                                    {/* Timer */}
                                                                    {planItem?.runningTimer &&
                                                                        <div
                                                                            className={'btn daily-planning__icon'}
                                                                            data-title={"Er loop een timer"}
                                                                        >
                                                                            <Icon name={'fa-regular stopwatch'} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className={'planning__person__avatar__container'}>
                                                                    {
                                                                        planItem.persons
                                                                            .filter(person => person.firstName && person.lastName)
                                                                            .map((person) => {
                                                                                const firstNameInitial = person.firstName.charAt(0).toUpperCase();
                                                                                let lastNameInitial = "";

                                                                                // get the lastname parts
                                                                                let lastnameParts = person.lastName?.split(' ');

                                                                                // set the lastNameInitial if we have something to work with
                                                                                if (lastnameParts !== undefined && lastnameParts.length > 0) {
                                                                                    lastNameInitial = lastnameParts?.[lastnameParts.length - 1]?.charAt(0)?.toUpperCase();
                                                                                }
                                                                                return (
                                                                                    <span
                                                                                        key={person.id}
                                                                                        data-title={`${person.firstName} ${person.lastName}`}
                                                                                        className={`btn planning__person__avatar person__avatar-bg-${(planItem.project.number.match(/[1-9][0-9]*$/)?.[0] ?? 0) % 19 + 1}`}
                                                                                    >
                                                                                        {firstNameInitial}{lastNameInitial}
                                                                                    </span>
                                                                                )
                                                                            })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                )
                                            }
                                        </div>
                                    </>
                                )
                            }
                        </Grid>
                    </div>
                </SpinnerOverlay>
            </Block>
        </>
    )
}

import {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {stringify} from 'qs';
import {FetchContext} from 'App/Strapi/FetchContext';
import {formatDate} from 'App/Util/format';
import {usePagination} from 'UI/App/Components/Pagination/Pagination';
import Table from 'UI/App/Components/Table/Table';
import Icon from 'UI/App/Components/Icon/Icon';
import "./calculationOverview.scss";
import useWindowDimensions from "App/Util/useWindowDimensions";
import Container from "UI/App/Components/Container/Container";
import Popup, {closePopup, openPopup} from "../../../../Components/Popup/Popup";
import EditConfirmationPopup from './EditConfirmationPopup';
import '../../content.scss';
import Button from "../../../../Components/Button/Button";
import User from "App/Strapi/User";
import IF from "UI/App/Components/Conditional/IF";

export const statusses = [
    { label: 'Vul in', value: '', disabled: true },
    { label: 'Concept', value: 'concept' },
    { label: 'Offerte', value: 'offerte' },
    { label: 'Order', value: 'order' },
    { label: 'Verwerkt', value: 'verwerkt' },
    { label: 'Gevalideerd', value: 'gevalideerd' },
    { label: 'Archief', value: 'archief' },
    { label: 'Mislukt', value: 'mislukt' },
];

export default function CalculationOverview() {
    const [selectedCalculation, setSelectedCalculation] = useState(null);
    const [tableData, setTableData] = useState([]);
    const {authAxios} = useContext(FetchContext);
    const {width, height} = useWindowDimensions();
    const navigate = useNavigate();

    const [calculations, setCalculations] = useState([]);
    const [isInfoMessage, setIsInfoMessage] = useState(true);
    const [editCalcId, setEditCalcId] = useState(null);

    // Enable pagination
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery,
        paginationStateLoaded
    } = usePagination({
        storageKey: 'calculation',
        searchSettings: {
            enabled: true,
            strapiFields: [
                'name',
                'number',
                'owner',
                'customerReference',
                'projectDescription',
                '[company][name]'
            ]
        },
        filters: [
            {
                name: 'owner',
                type: 'select',
                options: [
                    { name: 'Beide', value: '*' },
                    { name: 'HJMG', value: 'hjmg' },
                    { name: 'Pretore', value: 'pretore' }
                ],
                defaultValue: '*',
                strapiFilterFields: ['owner']
            },
            {
                name: 'Status',
                type: 'select',
                options: [
                    {
                        name: 'Alle', value: '*'
                    },
                    {
                        name: 'Concept', value: 'concept'
                    },
                    {
                        name: 'Offerte', value: 'offerte'
                    },
                    {
                        name: 'Order', value: 'order'
                    },
                    {
                        name: 'Verwerkt', value: 'verwerkt'
                    },
                    {
                        name: 'Gevalideerd', value: 'gevalideerd'
                    },
                    {
                        name: 'Archief', value: 'archief'
                    },
                    {
                        name: 'Mislukt', value: 'mislukt'
                    },

                ],
                defaultValue: '*',
                strapiFilterFields: ['status']
            },
            {
                name: 'webshop',
                type: 'select',
                options: [
                    {name: 'Beide', value: '*'},
                    {name: 'Emily', value: 'emily'},
                    {name: 'Webshop', value: 'webshop'},
                ],
                enabled: User.hasRole(['admin', 'management']),
                defaultValue: 'emily',
                callbackFunction: (e) => {
                    // guard against no value or default value, if we have a callbackFilterValues we use that instead
                    if ((e.currentValue ?? e.defaultValue) === undefined || (e.currentValue ?? e.defaultValue) === "*") return;
                    // acquire the 'newest' value
                    let value = e.currentValue ?? e.defaultValue;

                    if (value === 'emily') {
                        return {
                            $or: [
                                {
                                    'isWebshopCalculation': {
                                        '$eq': false
                                    }
                                },
                                {
                                    '$not': {
                                        'projectDescription': {
                                            '$startsWith': 'EXTERN -'
                                        }
                                    }
                                }
                            ]
                        }
                    } else if (value === 'webshop') {
                        return {
                            $or: [
                                {
                                    'isWebshopCalculation': {
                                        '$eq': true
                                    }
                                },
                                {
                                    'projectDescription': {
                                        '$startsWith': 'EXTERN -'
                                    }
                                }
                            ]
                        }
                    }
                }
            }
        ],
        htmlElements: [
            height > 500 && width > 500 && <span className='pagination__filter addItem' key='link--add'>
                <Link to={`/calculations/hjmg/create`} className='btn btn--add btn--icon-right btn--hjmg'>
                    Toevoegen {width > 1725 ? '(HJMG)' : ''}
                    <Icon name='plus' />
                </Link>
            </span>,
            height > 500 && width > 500 && <span className='pagination__filter addItem' key='link--add'>
                <Link to={`/calculations/create`} className='btn btn--add btn--icon-right btn--pretore'>
                    Toevoegen {width > 1725 ? '(Pretore)' : ''}
                    <Icon name='plus' />
                </Link>
            </span>
        ],
        buttonCollapseBreakpoint: 700,
        resultsPerPageBreakpoint: 1600,
        paginationBreakpoint: 1350,
        filterPopupBreakpoint: 1000
    });

    // Run in dom update
    useEffect(() => {
        if (!paginationStateLoaded) return;

        loadTable();
    }, [authAxios, currentPage, resultsPerPage, setTotalPages, setTotalResults, filterQuery, paginationStateLoaded]);

    useEffect(() => {
        const tableData = [];

        // Used for preventing buttons not lining out correctly.
        // Non webshop calculations contain 4 buttons, while webshop calculations don't have the edit button.
        const hasEmilyCalculations = calculations.some(calc => !calc.isWebshopCalculation);

        // loop through all the records
        for (const [index, calculation] of Object.entries(calculations)) {

            // add a row for the company
            tableData.push({
                attributes: {
                    id: calculation.id
                },
                data: [
                    <div className={'owner-indicator'}>
                        {calculation.owner !== null ? calculation.owner.toLowerCase() === 'hjmg' ? <span className={'circle circle-hjmg'}></span> : <span className={'circle circle-pretore'}></span> : <></>}
                    </div>,
                    calculation.number,
                    calculation?.company?.name,
                    calculation?.projectDescription,
                    formatDate(calculation.createdAt),
                    <select
                        value={calculation?.status ?? ''}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        onChange={({target}) => {
                            updateStatus(target, calculation.id)
                        }}
                        style={{width: 'auto'}} // fix select box width
                    >
                        {statusses.map(({label, value, disabled}) => {
                            return (
                                <option key={value} value={value} disabled={disabled}>
                                    {label}
                                </option>
                            );
                        })}
                    </select>,
                    <div className={'table__action-buttons'}>
                        <Link data-title={'Calculatie bekijken'} className={'btn btn--transparent'} to={`/calculations/${calculation.id}/view`}><Icon name={'eye'}/></Link>
                        <IF condition={!calculation.isWebshopCalculation}>
                            <Button
                                data-title={calculation?.locked !== null && calculation.locked ? 'Calculatie geblokkeerd' : 'Calculatie bewerken'}
                                className={'btn btn--transparent'}
                                onClick={() => {
                                    if (calculation?.locked !== true) {
                                        setEditCalcId(calculation.id);
                                        openPopup('editConfirmationMessage');
                                    }
                                }}>
                                {(calculation?.locked !== null && calculation.locked) ?
                                    <Icon
                                        style={{
                                            color: '#808080',
                                            fontSize: '15pt',
                                            width:'20px'
                                        }}
                                        name={'fa-solid pencil-slash'}
                                    />
                                    : <Icon name={'pencil'}/>}
                            </Button>
                        </IF>

                        <IF condition={calculation.isWebshopCalculation === true && hasEmilyCalculations}>
                            <Button className={'btn btn--transparent'} disabled>
                                <Icon name={'pencil'} style={{ fill: 'transparent' }} />
                            </Button>
                        </IF>
                        <Button data-title={'Calculatie kopiëren'} className={'btn btn--transparent'} onClick={() => {
                            openPopup("CopyOrRevision")
                            setSelectedCalculation(calculation.id)
                        }}><Icon name={'copy'}/></Button>
                        {width >= 400 && <Link data-title={'Offerte maken'} className={'btn btn--transparent'} to={`/quotes/create/${calculation.id}`}><Icon name={'offer-solid'}/></Link>}
                    </div>
                ]
            });
        }

        setTableData(tableData);
    }, [calculations, width])

    const loadTable = () => {

        let preformattedParams = {
            sort: 'createdAt:desc',
            fields: ['id', 'name', 'locked','number', 'projectDescription', 'status', 'createdAt', 'customerReference', 'owner', 'isWebshopCalculation'],
            populate: {
                company: {
                    fields: ['name']
                },
            },
            pagination: {
                pageSize: resultsPerPage,
                page: currentPage
            },
            filters: {
                projectDescription: {
                    $nei: 'onderhoud'
                }
            }
        };

        const params = stringify(preformattedParams);

        authAxios
            .get(`/calculations?${params}&${filterQuery}`)
            .then(({ data }) => {
                setTotalPages(data.meta.pagination.pageCount);
                setTotalResults(data.meta.pagination.total);
                setCalculations(data.data);
            })
            .catch((exception) => {
                console.error(exception);
            });
    }

    const updateStatus = (target, calculationID) => {
        if (!target.value) return;

        // fix bug put data & toast unsynced data
        const value = target.value;

        authAxios
            .put(`/calculations/${calculationID}`, {
                data: {
                    status: value
                }
            })
            .then(({ data }) => {
                toast.success(
                    `Calculatie: ${data.data.number} status omgezet naar ${value}`
                );
                loadTable()
            })
            .catch((error) => {
                console.error(error);
                toast.error(`Er ging iets mis met het bewerken van offerte.`);
            });
    };

    const getInfoMessageGridTemplateColumns = () => {
        if(width > 350) return '30px 1fr 30px';
        if(width > 300) return '1fr 30px';
        return '1fr';
    }

    return (
        <div>
            {isInfoMessage && ((height <= 500 && width > height) || (width <= 500 && height > width)) && <div
                className="info__message"
                style={{
                    gridTemplateColumns: getInfoMessageGridTemplateColumns()
                }}
                onClick={() => {if(width < 300) setIsInfoMessage(false)}}
            >
                {width > 350 && <Icon style={{fill: 'var(--primary)', height: '20px'}} name={'information'}/>}
                Calculaties kunnen alleen aan worden gemaakt vanaf desktop
                {width > 300 && <Icon onClick={() => setIsInfoMessage(false)} style={{fill: 'var(--primary)', height: '20px'}} name={'times'}/>}
            </div>}
            <Popup popupID='CopyOrRevision' title={'Kopie of revisie'}>
                <Button onClick={() => {
                    closePopup('CopyOrRevision')
                    navigate(`/calculations/${selectedCalculation}/copy`)
                }}>
                    Kopie
                </Button>
                <Button onClick={() => {
                    closePopup('CopyOrRevision')
                    navigate(`/calculations/${selectedCalculation}/revision`)
                }}>
                    Revisie
                </Button>
            </Popup>
            <EditConfirmationPopup calculationId={editCalcId}/>
            {filtering}
            <Container>
                <Table
                    structure={{
                        350:       [0, '100%', 0, 0, 0, 0, '80px'],
                        400:       ['40px', '100%', 0, 0, 0, 0, '80px'],
                        550:       ['40px', '100%', 0, 0, 0, 0, '160px'],
                        800:       ['40px', '140px', '100%', 0, 0, 0, '160px'],
                        950:       ['40px', '140px', '100%', '200px', 0, 0, '160px'],
                        1400:       ['40px', '140px', '100%', '200px', 0, '160px', '160px'],
                        1500:       ['40px', '140px', '100%', '100%', 0, '160px', '160px'],
                        1700:       ['40px', '140px', '100%', '100%', 0, '160px', '160px'],
                        default:    ['40px', '140px', '100%', '100%', '170px', '160px', '160px']
                    }}
                    headers={[
                        '',
                        'Nummer',
                        'Klant',
                        'Omschrijving',
                        'Aangemaakt op',
                        'Status',
                        '',
                    ]}
                    data={tableData}
                    padding='s'
                    paddingBreakpoint={1400}
                    buttonCollapseBreakpoint={1400}
                    columnOverflowExceptions={[6]}
                    columnTranslationExceptions={[1, 2]}
                />
            </Container>
            {paging}
        </div>
    );
}

import {Link, useNavigate} from "react-router-dom";
import Table from "UI/App/Components/Table/Table";
import React, {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import capitalize from "lodash/capitalize";
import {formatSmallCurrency} from "../../../../../../App/Util/format";
import {usePagination} from "../../../../Components/Pagination/Pagination";
import {stringify} from "qs";
import Icon from "UI/App/Components/Icon/Icon";
import Button from "UI/App/Components/Button/Button";
import {toast} from "react-toastify";
import {download, generateCsv, mkConfig} from "export-to-csv";
import PopupImportCsv from 'UI/App/Components/Popup/Popups/ImportCatalog';
import {openPopup} from "UI/App/Components/Popup/Popup";
import Container from "UI/App/Components/Container/Container";
import ConfirmationPopup from "UI/App/Components/Popup/Popups/Util/ConfirmationPopup";
import {BooleanControlled} from "UI/App/Components/Form/Boolean";
import { exportObject } from "App/Util/exportObject";
import { AuthContext } from 'App/Strapi/AuthProvider';

export default function Catalog() {
    const { authAxios } = useContext(FetchContext);
    const authData = useContext(AuthContext);

    const navigate = useNavigate();

    const [materials, setMaterials] = useState([]);
    const [categoryFields, setCategoryFields] = useState([]);
    const [popupProps, setPopupProps] = useState({});
    // Enable pagination
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterValues,
        filterQuery,
        paginationStateLoaded
    } = usePagination({
        storageKey: 'catalog',
        searchSettings: {
            enabled: true,
            strapiFields: [
                'internalSKU',
                'category',
                'quotationNameEN',
                'quotationNameNL',
                'calculationDescription'
            ]
        },
        filters: [
            {
                name: 'active',
                type: 'select',
                options: [
                    { name: 'Actief', value: 'true' },
                    { name: 'Inactief', value: 'false' },
                ],
                strapiFilter: "$eq",
                strapiFilterFields: [
                    'active'
                ]
            },
            {
                name: 'category',
                type: 'select',
                options: categoryFields,
                strapiFilter: "$eq",
                strapiFilterFields: [
                    'category'
                ]
            }
        ], htmlElements: [
            <Button onClick={ExportMaterials} className='btn btn--icon-right btn--black'>Exporteren <Icon name='download' /></Button>,
            <Button onClick={ImportPopup} className='btn btn--icon-right btn--black'>Importeren <Icon name='upload' /></Button>,
            <Link className='btn btn--icon-right' to='./prices'>Bewerk prijzen <Icon name={'pencil'} /></Link>,
            <Link className='btn btn--icon-right' to='./materials/create'>Toevoegen <Icon name='plus' /></Link>,

        ],
        buttonCollapseBreakpoint: 2000,
        resultsPerPageBreakpoint: 1600,
    });


    useEffect(() => {
        // get the materials from the API
        authAxios.get(`/logistics/materials/categories`)
            .then(({ data }) => {
                setCategoryFields([
                    { name: 'Alle categorieën', value: '*' },
                    ...data.categories.map((category) => (
                        { name: capitalize(category), value: category }
                    ))
                ]);
            }).catch((reason) => {
                setCategoryFields([{ name: 'Alle categorieën', value: '*' }])
            })
    }, []);


    async function updateMaterialActiveState(materialId,boolean){
        const updateToast = toast.loading('Waarde aanpassen.')

        // update template
        authAxios.put(`/logistics/materials/${materialId}`, {data:{
                active: boolean
            }
        }).then(({ data }) => {
            console.log(data);
            loadMaterials(updateToast);
        }).catch((exception) => {
            console.log(exception);
            toast.error(`Er ging iets mis met het aanpassen van materiaal.`);
        });
    }

    useEffect(() => {
        if (!paginationStateLoaded) return;

        loadMaterials();
    }, [authAxios, currentPage, resultsPerPage, setTotalPages, setTotalResults, filterQuery, paginationStateLoaded]);

    async function loadMaterials(updateToast){
        const params = stringify({
            sort: ['category:asc', 'internalSKU:asc'],
            fields: [
                'id',
                'category',
                'internalSKU',
                'calculationDescription',
                'salePrice',
                'countPerUnit',
                'unit',
                'active'
            ],
            pagination: {
                pageSize: resultsPerPage,
                page: currentPage
            }
        });

        // get the materials from the API
        authAxios.get(`/logistics/materials?${params}&${filterQuery}`)
            .then(({ data: { data, meta } }) => {
                setTotalPages(meta.pagination.pageCount)
                setTotalResults(meta.pagination.total)

                if (data) {
                    setMaterials(data.map((material) => {
                        return {
                            attributes: {
                                onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const url = `/calculations/catalog/materials/${material.id}`;
                                    if (e?.ctrlKey || e?.metaKey) {
                                        window.open(url, '_blank');
                                    } else if (e.shiftKey) {
                                        window.open(url);
                                    } else {
                                        navigate(url);
                                    }
                                },
                                id: material.id,
                            },
                            data: [
                                capitalize(material.category),
                                `${material.calculationDescription} ${(material?.internalSKU) ? `(${material?.internalSKU})` : ""}`,
                                `${formatSmallCurrency(material.salePrice, 6)} per ${material.countPerUnit > 1 ? material.countPerUnit + ' ' : ''}${material.countPerUnit > 1 ? material.unit : (material.unit ?? '').replace(/s$/, '')}`,
                                <BooleanControlled
                                    leftLabel='Inactief'
                                    rightLabel='Actief'
                                    leftIsRed={true}
                                    value={material?.active}
                                    setValue={(value) => {
                                        if(material.active !== value){
                                            updateMaterialActiveState(material.id,value);
                                        }
                                    }}
                                />,,
                                <div className={'table__action-buttons'}>
                                    <Button data-title={'Bewerken'} className={'btn btn--transparent'} style={{ height: "0px", }} onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        const url = `/calculations/catalog/materials/${material.id}/update`;
                                        if (e?.ctrlKey || e?.metaKey) {
                                            window.open(url, '_blank');
                                        } else if (e.shiftKey) {
                                            window.open(url);
                                        } else {
                                            navigate(url);
                                        }
                                    }}>
                                        <Icon name={'pencil'} />
                                    </Button>

                                    <Button data-title={'Kopiëren'} className={'btn btn--transparent'} style={{ height: "0px", }} onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setPopupProps({ value: material.id, })
                                        openPopup("confirmationPopup")
                                    }}>
                                        <Icon name={'copy'} />
                                    </Button>
                                </div>
                            ]
                        }
                    }));
                }

                if(updateToast !== undefined){
                    toast.update(updateToast, {
                        render: `Machine status geupdate.`,
                        type: "success",
                        isLoading: false,
                        autoClose:1000
                    });
                }
            })
    }


    /**
     * Export all materials to csv.
     */
    async function ExportMaterials({ target }) {
        // disable the button to prevent spamming while this function is running
        target.disabled = true;

        // initialize empty csv data array
        let csvData = [];

        try {
            await authAxios.get(`/logistics/materials/all?sort=id:asc`).then(({ data }) => {
                for (const material of data) {
                    const row = {
                        'Id': material?.id ?? '',
                        'Categorie': material?.category ?? '',
                        "Workflow / artikelnummers": material?.internalSKU ?? '',
                        'Artikelnummer leverancier': material?.supplierSKU ?? '',
                        'Calculatie omschrijving': material?.calculationDescription ?? '',
                        'Offerte omschrijving NL': material?.quotationNameNL ?? '',
                        'Offerte omschrijving UK': material?.quotationNameEN ?? '',
                        'Lengte in meters': material?.lengthInMeters ?? '',
                        'Breedte in meters': material?.widthInMeters ?? '',
                        'Hoogte in meters': material?.heightInMeters ?? '',
                        'Dikte in millimeters': material?.thicknessInMM ?? '',
                        'Gewicht in grammen': material?.weightInGram ?? '',
                        'Min. aantal per bestelling': material?.unitsPerOrder ?? '',
                        'Order eenheid': material?.unitsPerOrderName ?? '',
                        'Looprichting': material?.runningDirection ?? '',
                        'Inkoopprijs': material?.purchasePrice ?? '',
                        'Actuele inkoop': material?.regularPrice ?? '',
                        'Verkoopprijs': material?.salePrice ?? '',
                        'aantal per eenheid': material?.countPerUnit ?? '',
                        'eenheid': material?.unit ?? '',
                        'Coated': (material?.coated ?? null) !== null ? (material?.coated ? 'Ja' : 'Nee') : '',
                        'Datum laatste prijs': material?.lastPriceUpdate ?? '',
                        'Actief': (material?.active ?? null) !== null ? (material?.active ? 'Ja' : 'Nee') : '',
                    };

                    csvData.push(row);
                }
            });

            // download csv
            exportObject(csvData, {
                filename: `Materiaallijst_export-${new Date().toLocaleDateString("nl", { year: "numeric", month: "2-digit", day: "2-digit" })}`, // date in DD-MM-YYY
                fieldSeparator: ';',
                decimalSeparator: 'locale',
                useKeysAsHeaders: true,
                showColumnHeaders: true,
                useBom: true,
            }, authAxios, authData, "export.calculationsCatalog")

        } catch (error) {
            toast.error('Materiaallijst kon niet geëxporteerd worden');

            process.env.NODE_ENV === 'development' && console.error(error);
        };


        // enable the button again
        target.disabled = false;
    }

    function ImportPopup() {
        openPopup('import-catalog');
    }

    function createCopyOfCatalog(id) {
        if (id === undefined) {
            toast.error("Er is iets misgegaan met het kopieeren van de catalogus")
            return
        }
        navigate(`/calculations/catalog/materials/copy/${id}`)
    }
    return (
        <div>
            <PopupImportCsv />
            <ConfirmationPopup
                title={"Materiaal kopiëren"}
                description={"Weet u zeker dat u een kopie wilt maken?"}
                functionOnConfirm={(e) => { createCopyOfCatalog(e?.target?.value) }}
                confirmButtonProps={popupProps}
                declineButtonProps={popupProps}
            />
            {filtering}
            <Container>
                <Table headers={["Categorieën", "Naam", "Verkoopprijs", '',""]}
                    structure={['250px', '60%', '40%', '200px','90px']}
                    data={materials}
                    columnOverflowExceptions={[5]}
                />
            </Container>
            {paging}
        </div>
    )
}

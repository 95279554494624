import { download, generateCsv, mkConfig } from "export-to-csv";
import { addLog } from "./addLog";

export function exportObject(csvData, csvFileOptions = {
    filename: `Unknow_export-${new Date().toLocaleDateString("nl-NL", { year: "numeric", month: "2-digit", day: "2-digit" })}`, // date in DD-MM-YYYY
    fieldSeparator: ';',
    decimalSeparator: 'locale',
    useKeysAsHeaders: true,
    showColumnHeaders: true,
    useBom: true,
},
    authAxios,
    authData,
    action = undefined,
) {
    // available options at: https://github.com/alexcaza/export-to-csv#api
    const csv = generateCsv(csvFileOptions)(csvData);

    // download csv
    download(csvFileOptions)(csv);

    if (authAxios === undefined || authData === undefined) {
        console.warn("Not all context provided to add a log for export: ", csvFileOptions.filename)
    } else {
        addLog({
            authAxios: authAxios,
            authData: authData,
            payload: {
                occurredAt: new Date(),
                csvOptions: csvFileOptions
            },
            status: "200",
            user: authData.authState.user.id,
            apiToken: null,
            action: action ?? "export"
        })
    }

}

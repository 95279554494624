import {useEffect} from 'react';
import isEmpty from 'lodash/isEmpty';
import {formatName} from 'App/Util/format';
import translate from 'App/Util/translate';
import Button from 'UI/App/Components/Button/Button';
import IF from 'UI/App/Components/Conditional/IF';
import Grid from 'UI/App/Components/Grid/Grid';
import Icon from 'UI/App/Components/Icon/Icon';
import PDF, {PDFSection, PDFTextArea} from 'UI/App/Components/PDF/PDFMockup';

export default function OrderPackingSlipPdfEditor({
    pdfData,
    setPdfData,
    template,
    order,
    company,
    contactPerson,
    isEdit,
    deliveryAmount = null
}) {
    // set data in seperate effects to not override edited data and to only set the related data when it's changed

    // set template data. template can change on `isEdit=true`
    useEffect(() => {
        if (template) {
            setPdfData((currentPdfData) => {
                currentPdfData.backgroundImage = template?.backgroundImage ?? null;
                currentPdfData.backgroundImage2 = template?.backgroundImage2 ?? null;
                currentPdfData.template = template.template ?? 1;
                currentPdfData.language = template?.language;

                return { ...currentPdfData };
            });
        }
    }, [template, setPdfData]);

    // set company data. pdf data will not change on `isEdit=true`
    useEffect(() => {
        if (!isEdit && company) {
            setPdfData((currentPdfData) => {
                currentPdfData.companyName = company?.name ?? '';
                currentPdfData.address = {
                    addressLine1: company?.address?.addressLine1 ?? '',
                    // set optional address lines to `null` if are empty here
                    addressLine2: (company?.address?.addressLine2 ?? '') === '' ? null : company?.address?.addressLine2,
                    addressLine3: (company?.address?.addressLine3 ?? '') === '' ? null : company?.address?.addressLine3,
                    postalCode: company?.address?.postalCode ?? '',
                    city: company?.address?.city ?? '',
                    province: company?.address?.province ?? '',
                    country: company?.address?.country ?? ''
                };

                return { ...currentPdfData };
            });
        }
    }, [company, isEdit, setPdfData]);

    // set contactperson data. pdf data will not change on `isEdit=true`
    useEffect(() => {
        if (!isEdit && contactPerson) {
            setPdfData((currentPdfData) => {
                // contactperson name propper trimmed and spacing
                currentPdfData.contactPersonName = formatName(
                    contactPerson?.firstName,
                    contactPerson?.prefixToSurname,
                    contactPerson?.surname
                );

                return { ...currentPdfData };
            });
        }
    }, [contactPerson, isEdit, setPdfData]);

    // set order data. pdf data will not change on `isEdit=true`
    useEffect(() => {
        if (!isEdit && order) {
            setPdfData((currentPdfData) => {
                const customerReference =
                    order?.calculation?.customerReference ??
                    (order?.calculation?.data?.checklist ?? []).find(
                        (item) =>
                            item?.name?.toLowerCase()?.includes('kenmerk') ||
                            item?.name?.toLowerCase()?.includes('referentie') ||
                            item?.name?.toLowerCase()?.includes('reference')
                    )?.value ??
                    '';
                currentPdfData.customerReference = customerReference;

                currentPdfData.projectNumber = order?.project?.number ?? order?.number ?? '';

                currentPdfData.checklist = (order?.calculation?.data?.checklist ?? []).filter(
                    (item) =>
                        !isEmpty(item.value) &&
                        !(
                            item?.name?.toLowerCase()?.includes('kenmerk') ||
                            item?.name?.toLowerCase()?.includes('referentie') ||
                            item?.name?.toLowerCase()?.includes('reference')
                        )
                );

                currentPdfData.totalAmount = deliveryAmount ?? order?.shipping?.total ?? order?.chosenAmount ?? '';
                currentPdfData.boxesAmount = order?.shipping?.boxes ?? '';
                currentPdfData.palletsAmount = order?.shipping?.pallets ?? '';

                return { ...currentPdfData };
            });
        }
    }, [isEdit, order, setPdfData]);

    if (typeof pdfData != 'object' || isEmpty(pdfData)) {
        return (
            <PDF pdfFor='packing-slip' pageTopBreakLines='0'>
                <PDFSection />
            </PDF>
        );
    } else if ((pdfData?.template ?? 1) === 1) {
        return <PDFTemplate1 pdfData={pdfData} setPdfData={setPdfData} />;
    } else if (pdfData?.template === 2) {
        return <PDFTemplate2 pdfData={pdfData} setPdfData={setPdfData} />;
    }
}

function PDFTemplate1({ pdfData, setPdfData }) {
    return (
        <PDF pdfFor='packing-slip' pdfData={pdfData} pageTopBreakLines='0'>
            {/* title */}
            <PDFSection style={{ marginBottom: '2lh' }}>
                <strong>
                    {translate('delivery note', pdfData?.language).toUpperCase()}
                    {/* {pdfData?.slipNumber && ` - ${pdfData?.slipNumber}`} */}
                </strong>
            </PDFSection>

            {/* company, contactperson, address */}
            <PDFSection style={{ marginBottom: '2lh' }}>
                <Grid columns='1' gap='0'>
                    <input
                        type='text'
                        defaultValue={pdfData?.companyName ?? ''}
                        onBlur={({ target }) => {
                            if (target.value === target.defaultValue) return;

                            setPdfData((data) => ({ ...data, companyName: target.value }));
                        }}
                        placeholder='Bedrijfsnaam'
                    />

                    <input
                        type='text'
                        defaultValue={pdfData?.contactPersonName ?? ''}
                        onBlur={({ target }) => {
                            if (target.value === target.defaultValue) return;

                            setPdfData((data) => ({ ...data, contactPersonName: target.value }));
                        }}
                        placeholder="Contactpersoon's naam"
                    />

                    <Grid customColTemplate='1fr 15px' gap='0'>
                        <input
                            type='text'
                            defaultValue={pdfData?.address?.addressLine1 ?? ''}
                            onBlur={({ target }) => {
                                if (target.value === target.defaultValue) return;

                                setPdfData((data) => {
                                    data.address.addressLine1 = target.value;

                                    return { ...data };
                                });
                            }}
                            placeholder='Adres regel 1'
                        />
                        <div style={{ alignSelf: 'end' }}>
                            <Button
                                className='btn--transparent btn--round btn--icon-green'
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)'
                                }}
                                onClick={() => {
                                    addAddressLine(setPdfData, pdfData?.address?.addressLine3 === null);
                                }}
                                title='Regel toevoegen'
                            >
                                <Icon name='plus-circle' />
                            </Button>
                        </div>
                    </Grid>

                    {/* optional address lines */}
                    <IF condition={pdfData?.address?.addressLine2 !== null}>
                        <Grid customColTemplate='1fr 15px' gap='0'>
                            <input
                                type='text'
                                defaultValue={pdfData?.address?.addressLine2 ?? ''}
                                onBlur={({ target }) => {
                                    if (target.value === target.defaultValue) return;

                                    setPdfData((data) => {
                                        data.address.addressLine2 = target.value;

                                        return { ...data };
                                    });
                                }}
                                placeholder='Adres regel 2'
                            />
                            <div style={{ alignSelf: 'end' }}>
                                <Button
                                    className='btn--transparent btn--round btn--icon-red'
                                    style={{
                                        padding: 0,
                                        backgroundColor: 'var(--white)'
                                    }}
                                    onClick={() => removeAddressLine(setPdfData)}
                                    title='Regel verwijderen'
                                >
                                    <Icon name='times' />
                                </Button>
                            </div>
                        </Grid>
                    </IF>

                    <IF condition={pdfData?.address?.addressLine3 !== null}>
                        <Grid customColTemplate='1fr 15px' gap='0'>
                            <input
                                type='text'
                                defaultValue={pdfData?.address?.addressLine3 ?? ''}
                                onBlur={({ target }) => {
                                    if (target.value === target.defaultValue) return;

                                    setPdfData((data) => {
                                        data.address.addressLine3 = target.value;

                                        return { ...data };
                                    });
                                }}
                                placeholder='Adres regel 3'
                            />
                            <div style={{ alignSelf: 'end' }}>
                                <Button
                                    className='btn--transparent btn--round btn--icon-red'
                                    style={{
                                        padding: 0,
                                        backgroundColor: 'var(--white)'
                                    }}
                                    onClick={() => removeAddressLine(setPdfData)}
                                    title='Regel verwijderen'
                                >
                                    <Icon name='times' />
                                </Button>
                            </div>
                        </Grid>
                    </IF>

                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            flexDirection: 'row'
                        }}
                    >
                        <input
                            type='text'
                            defaultValue={pdfData?.address?.postalCode ?? ''}
                            onBlur={({ target }) => {
                                if (target.value === target.defaultValue) return;

                                setPdfData((data) => {
                                    data.address.postalCode = target.value;

                                    return { ...data };
                                });
                            }}
                            style={{ minWidth: '8ch', width: '8ch', maxWidth: '14ch' }}
                            placeholder='Postcode'
                        />
                        <span>&nbsp;&nbsp;</span>
                        <input
                            type='text'
                            defaultValue={pdfData?.address?.city ?? ''}
                            onBlur={({ target }) => {
                                if (target.value === target.defaultValue) return;

                                setPdfData((data) => {
                                    data.address.city = target.value;

                                    return { ...data };
                                });
                            }}
                            placeholder='Plaats'
                        />
                    </div>

                    <input
                        type='text'
                        defaultValue={pdfData?.address?.country ?? ''}
                        onBlur={({ target }) => {
                            if (target.value === target.defaultValue) return;

                            setPdfData((data) => {
                                data.address.country = target.value;

                                return { ...data };
                            });
                        }}
                        placeholder='Land'
                    />
                </Grid>
            </PDFSection>

            {/* place and date */}
            <PDFSection style={{ marginBottom: '2lh' }}>
                <input
                    type='text'
                    defaultValue={pdfData?.locationInput}
                    onBlur={({ target }) => {
                        if (target.value === target.defaultValue) return;

                        setPdfData((data) => {
                            data.locationInput = target.value;

                            return { ...data };
                        });
                    }}
                    style={{
                        width: 'auto'
                    }}
                />
                <span>,&nbsp;</span>
                <input
                    type='date'
                    min={new Date().toLocaleDateString('fr-ca')}
                    defaultValue={pdfData?.dateInput ?? ''}
                    onBlur={({ target }) => {
                        if (target.value === target.defaultValue) return;

                        setPdfData((data) => {
                            data.dateInput = target.value;

                            return { ...data };
                        });
                    }}
                    style={{
                        width: 'calc(10ch + 1em)'
                    }}
                />
            </PDFSection>

            {/* reference */}
            <PDFSection justifyContent='flex-start'>
                <Grid customColTemplate='45mm 2.5mm 1fr' gap='0'>
                    <strong>{translate('reference', pdfData?.language, true)}</strong>
                    <span>:</span>
                    <span>{pdfData?.customerReference}</span>
                </Grid>
            </PDFSection>

            {/* project number */}
            <PDFSection justifyContent='flex-start'>
                <Grid customColTemplate='45mm 2.5mm 1fr' gap='0'>
                    <strong>Order</strong>
                    <span>:</span>
                    <span>{pdfData?.projectNumber}</span>
                </Grid>
            </PDFSection>

            {/* checklist */}
            {(pdfData?.checklist ?? []).map((checklistItem, index) => (
                <PDFSection key={`checklist-${index}`} justifyContent='flex-start'>
                    <Grid customColTemplate='45mm 2.5mm 1fr 34px' gap='0'>
                        <input
                            type='text'
                            defaultValue={(pdfData?.language === 'nl' ? checklistItem?.dutchName : checklistItem?.englishName) ?? checklistItem?.name ?? ''}
                            onBlur={({ target }) => {
                                if (target.value === target.defaultValue) return;

                                setPdfData((data) => {
                                    data.checklist[index].name = target.value;

                                    return { ...data };
                                });
                            }}
                            className='strong'
                        />
                        <span>:</span>
                        <PDFTextArea
                            defaultValue={checklistItem.value}
                            onBlur={({ target }) => {
                                if (target.value === target.defaultValue) return;

                                setPdfData((data) => {
                                    data.checklist[index].value = target.value;

                                    return { ...data };
                                });
                            }}
                            minRows={1}
                        />
                        <div style={{ alignSelf: 'end' }}>
                            <Button
                                className='btn--transparent btn--round btn--icon-red'
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)'
                                }}
                                onClick={() => removeChecklistRow(setPdfData, index)}
                                title='Regel verwijderen'
                            >
                                <Icon name='times' />
                            </Button>
                            <Button
                                className='btn--transparent btn--round btn--icon-green'
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)',
                                    marginLeft: '4px'
                                }}
                                onClick={() => {
                                    addChecklistRow(setPdfData, index);
                                }}
                                title='Regel toevoegen'
                            >
                                <Icon name='plus-circle' />
                            </Button>
                        </div>
                    </Grid>
                </PDFSection>
            ))}

            {/* amounts */}
            <PDFSection style={{ marginTop: '2lh', marginBottom: '2lh' }}>
                <Grid customColTemplate='45mm 2.5mm 45mm' gap='0' style={{ whiteSpace: 'break-spaces' }}>
                    <strong>{translate('copies amount', pdfData?.language, true)}</strong>
                    <span>:</span>
                    <input
                        type='number'
                        min='0'
                        step='1'
                        defaultValue={pdfData?.totalAmount ?? ''}
                        onBlur={({ target }) => {
                            if (target.value === target.defaultValue) return;

                            setPdfData((data) => {
                                data.totalAmount = target.value;

                                return { ...data };
                            });
                        }}
                    />

                    <strong>{translate('number of boxes', pdfData?.language, true)}</strong>
                    <span>:</span>
                    <input
                        type='number'
                        min='0'
                        step='1'
                        defaultValue={pdfData?.boxesAmount ?? ''}
                        onBlur={({ target }) => {
                            if (target.value === target.defaultValue) return;

                            setPdfData((data) => {
                                data.boxesAmount = target.value;

                                return { ...data };
                            });
                        }}
                    />

                    <strong>{translate('number of pallets', pdfData?.language, true)}</strong>
                    <span>:</span>
                    <input
                        type='number'
                        min='0'
                        step='1'
                        defaultValue={pdfData?.palletsAmount ?? ''}
                        onBlur={({ target }) => {
                            if (target.value === target.defaultValue) return;

                            setPdfData((data) => {
                                data.palletsAmount = target.value;

                                return { ...data };
                            });
                        }}
                    />
                </Grid>
            </PDFSection>

            {/* signature */}
            <PDFSection>
                <Grid columns='1' gap='3lh'>
                    <span>{translate('receipt signature', pdfData?.language, true)}:</span>

                    <hr
                        style={{
                            width: '40%'
                        }}
                    />
                </Grid>
            </PDFSection>
        </PDF>
    );
}

function PDFTemplate2({ pdfData, setPdfData }) {
    return (
        <PDF pdfFor='packing-slip' pdfData={pdfData} pageTopBreakLines='7'>
            {/* title */}
            <PDFSection style={{ marginBottom: '2lh' }}>
                <strong>
                    {translate('delivery note', pdfData?.language).toUpperCase()}
                    {/* {pdfData?.slipNumber && ` - ${pdfData?.slipNumber}`} */}
                </strong>
            </PDFSection>

            {/* company, contactperson, address */}
            <PDFSection style={{ marginBottom: '2lh' }}>
                <Grid columns='1' gap='0'>
                    <input
                        type='text'
                        defaultValue={pdfData?.companyName ?? ''}
                        onBlur={({ target }) => {
                            if (target.value === target.defaultValue) return;

                            setPdfData((data) => ({ ...data, companyName: target.value }));
                        }}
                        placeholder='Bedrijfsnaam'
                    />

                    <input
                        type='text'
                        defaultValue={pdfData?.contactPersonName ?? ''}
                        onBlur={({ target }) => {
                            if (target.value === target.defaultValue) return;

                            setPdfData((data) => ({ ...data, contactPersonName: target.value }));
                        }}
                        placeholder="Contactpersoon's naam"
                    />

                    <Grid customColTemplate='1fr 15px' gap='0'>
                        <input
                            type='text'
                            defaultValue={pdfData?.address?.addressLine1 ?? ''}
                            onBlur={({ target }) => {
                                if (target.value === target.defaultValue) return;

                                setPdfData((data) => {
                                    data.address.addressLine1 = target.value;

                                    return { ...data };
                                });
                            }}
                            placeholder='Adres regel 1'
                        />
                        <div style={{ alignSelf: 'end' }}>
                            <Button
                                className='btn--transparent btn--round btn--icon-green'
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)'
                                }}
                                onClick={() => {
                                    addAddressLine(setPdfData, pdfData?.address?.addressLine3 === null);
                                }}
                                title='Regel toevoegen'
                            >
                                <Icon name='plus-circle' />
                            </Button>
                        </div>
                    </Grid>

                    {/* optional address lines */}
                    <IF condition={pdfData?.address?.addressLine2 !== null}>
                        <Grid customColTemplate='1fr 15px' gap='0'>
                            <input
                                type='text'
                                defaultValue={pdfData?.address?.addressLine2 ?? ''}
                                onBlur={({ target }) => {
                                    if (target.value === target.defaultValue) return;

                                    setPdfData((data) => {
                                        data.address.addressLine2 = target.value;

                                        return { ...data };
                                    });
                                }}
                                placeholder='Adres regel 2'
                            />
                            <div style={{ alignSelf: 'end' }}>
                                <Button
                                    className='btn--transparent btn--round btn--icon-red'
                                    style={{
                                        padding: 0,
                                        backgroundColor: 'var(--white)'
                                    }}
                                    onClick={() => removeAddressLine(setPdfData)}
                                    title='Regel verwijderen'
                                >
                                    <Icon name='times' />
                                </Button>
                            </div>
                        </Grid>
                    </IF>

                    <IF condition={pdfData?.address?.addressLine3 !== null}>
                        <Grid customColTemplate='1fr 15px' gap='0'>
                            <input
                                type='text'
                                defaultValue={pdfData?.address?.addressLine3 ?? ''}
                                onBlur={({ target }) => {
                                    if (target.value === target.defaultValue) return;

                                    setPdfData((data) => {
                                        data.address.addressLine3 = target.value;

                                        return { ...data };
                                    });
                                }}
                                placeholder='Adres regel 3'
                            />
                            <div style={{ alignSelf: 'end' }}>
                                <Button
                                    className='btn--transparent btn--round btn--icon-red'
                                    style={{
                                        padding: 0,
                                        backgroundColor: 'var(--white)'
                                    }}
                                    onClick={() => removeAddressLine(setPdfData)}
                                    title='Regel verwijderen'
                                >
                                    <Icon name='times' />
                                </Button>
                            </div>
                        </Grid>
                    </IF>

                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            flexDirection: 'row'
                        }}
                    >
                        <input
                            type='text'
                            defaultValue={pdfData?.address?.postalCode ?? ''}
                            onBlur={({ target }) => {
                                if (target.value === target.defaultValue) return;

                                setPdfData((data) => {
                                    data.address.postalCode = target.value;

                                    return { ...data };
                                });
                            }}
                            style={{ minWidth: '8ch', width: '8ch', maxWidth: '14ch' }}
                            placeholder='Postcode'
                        />
                        <span>&nbsp;&nbsp;</span>
                        <input
                            type='text'
                            defaultValue={pdfData?.address?.city ?? ''}
                            onBlur={({ target }) => {
                                if (target.value === target.defaultValue) return;

                                setPdfData((data) => {
                                    data.address.city = target.value;

                                    return { ...data };
                                });
                            }}
                            placeholder='Plaats'
                        />
                    </div>

                    <input
                        type='text'
                        defaultValue={pdfData?.address?.country ?? ''}
                        onBlur={({ target }) => {
                            if (target.value === target.defaultValue) return;

                            setPdfData((data) => {
                                data.address.country = target.value;

                                return { ...data };
                            });
                        }}
                        placeholder='Land'
                    />
                </Grid>
            </PDFSection>

            {/* place and date */}
            <PDFSection style={{ marginBottom: '2lh' }}>
                <input
                    type='text'
                    defaultValue={pdfData?.locationInput}
                    onBlur={({ target }) => {
                        if (target.value === target.defaultValue) return;

                        setPdfData((data) => {
                            data.locationInput = target.value;

                            return { ...data };
                        });
                    }}
                    style={{
                        width: 'auto'
                    }}
                />
                <span>,&nbsp;</span>
                <input
                    type='date'
                    min={new Date().toLocaleDateString('fr-ca')}
                    defaultValue={pdfData?.dateInput ?? ''}
                    onBlur={({ target }) => {
                        if (target.value === target.defaultValue) return;

                        setPdfData((data) => {
                            data.dateInput = target.value;

                            return { ...data };
                        });
                    }}
                    style={{
                        width: 'calc(10ch + 1em)'
                    }}
                />
            </PDFSection>

            {/* reference */}
            <PDFSection justifyContent='flex-start'>
                <Grid customColTemplate='45mm 2.5mm 1fr' gap='0'>
                    <strong>{translate('reference', pdfData?.language, true)}</strong>
                    <span>:</span>
                    <span>{pdfData?.customerReference}</span>
                </Grid>
            </PDFSection>

            {/* project number */}
            <PDFSection justifyContent='flex-start'>
                <Grid customColTemplate='45mm 2.5mm 1fr' gap='0'>
                    <strong>Order</strong>
                    <span>:</span>
                    <span>{pdfData?.projectNumber}</span>
                </Grid>
            </PDFSection>

            {/* checklist */}
            {(pdfData?.checklist ?? []).map((checklistItem, index) => (
                <PDFSection key={`checklist-${index}`} justifyContent='flex-start'>
                    <Grid customColTemplate='45mm 2.5mm 1fr 34px' gap='0'>
                        <input
                            type='text'
                            defaultValue={(pdfData?.language === 'nl' ? checklistItem?.dutchName : checklistItem?.englishName) ?? checklistItem?.name ?? ''}
                            onBlur={({ target }) => {
                                if (target.value === target.defaultValue) return;

                                setPdfData((data) => {
                                    data.checklist[index].name = target.value;

                                    return { ...data };
                                });
                            }}
                            className='strong'
                        />
                        <span>:</span>
                        <PDFTextArea
                            defaultValue={checklistItem.value}
                            onBlur={({ target }) => {
                                if (target.value === target.defaultValue) return;

                                setPdfData((data) => {
                                    data.checklist[index].value = target.value;

                                    return { ...data };
                                });
                            }}
                            minRows={1}
                        />
                        <div style={{ alignSelf: 'end' }}>
                            <Button
                                className='btn--transparent btn--round btn--icon-red'
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)'
                                }}
                                onClick={() => removeChecklistRow(setPdfData, index)}
                                title='Regel verwijderen'
                            >
                                <Icon name='times' />
                            </Button>
                            <Button
                                className='btn--transparent btn--round btn--icon-green'
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)',
                                    marginLeft: '4px'
                                }}
                                onClick={() => {
                                    addChecklistRow(setPdfData, index);
                                }}
                                title='Regel toevoegen'
                            >
                                <Icon name='plus-circle' />
                            </Button>
                        </div>
                    </Grid>
                </PDFSection>
            ))}

            {/* amounts */}
            <PDFSection style={{ marginTop: '2lh', marginBottom: '2lh' }}>
                <Grid customColTemplate='45mm 2.5mm 45mm' gap='0' style={{ whiteSpace: 'break-spaces' }}>
                    <strong>{translate('copies amount', pdfData?.language, true)}</strong>
                    <span>:</span>
                    <input
                        type='number'
                        min='0'
                        step='1'
                        defaultValue={pdfData?.totalAmount ?? ''}
                        onBlur={({ target }) => {
                            if (target.value === target.defaultValue) return;

                            setPdfData((data) => {
                                data.totalAmount = target.value;

                                return { ...data };
                            });
                        }}
                    />

                    <strong>{translate('number of boxes', pdfData?.language, true)}</strong>
                    <span>:</span>
                    <input
                        type='number'
                        min='0'
                        step='1'
                        defaultValue={pdfData?.boxesAmount ?? ''}
                        onBlur={({ target }) => {
                            if (target.value === target.defaultValue) return;

                            setPdfData((data) => {
                                data.boxesAmount = target.value;

                                return { ...data };
                            });
                        }}
                    />

                    <strong>{translate('number of pallets', pdfData?.language, true)}</strong>
                    <span>:</span>
                    <input
                        type='number'
                        min='0'
                        step='1'
                        defaultValue={pdfData?.palletsAmount ?? ''}
                        onBlur={({ target }) => {
                            if (target.value === target.defaultValue) return;

                            setPdfData((data) => {
                                data.palletsAmount = target.value;

                                return { ...data };
                            });
                        }}
                    />
                </Grid>
            </PDFSection>

            {/* signature */}
            <PDFSection>
                <Grid columns='1' gap='3lh'>
                    <span>{translate('receipt signature', pdfData?.language, true)}:</span>

                    <hr
                        style={{
                            width: '40%'
                        }}
                    />
                </Grid>
            </PDFSection>
        </PDF>
    );
}

function addAddressLine(setPdfData, canAddLine = true) {
    if (typeof setPdfData !== 'function' || !canAddLine) return;

    setPdfData((currentPdfData) => {
        // add line 2 if it's set to `null`
        if (currentPdfData?.address?.addressLine2 === null) {
            currentPdfData.address.addressLine2 = '';
        }
        // else if add line 3 if it's set to `null`
        else if (currentPdfData?.address?.addressLine3 === null) {
            currentPdfData.address.addressLine3 = '';
        }

        return { ...currentPdfData };
    });
}
function removeAddressLine(setPdfData) {
    if (typeof setPdfData !== 'function') return;

    setPdfData((currentPdfData) => {
        // remove line 3 if it's not set to `null`
        if (currentPdfData?.address?.addressLine3 !== null) {
            // if addressLine2 is an empty string we can put whatever value was in line 3 into line 2
            if (currentPdfData?.address?.addressLine2 === '') {
                currentPdfData.address.addressLine2 = currentPdfData.address.addressLine3;
            }

            currentPdfData.address.addressLine3 = null;
        }
        // else if remove line 2 if it's not set to `null`
        else if (currentPdfData?.address?.addressLine2 !== null) {
            currentPdfData.address.addressLine2 = null;
        }

        return { ...currentPdfData };
    });
}

function addChecklistRow(setPdfData, index) {
    if (typeof setPdfData !== 'function' || index === null || index === undefined) return;

    setPdfData((currentPdfData) => {
        currentPdfData.checklist.splice(index + 1, 0, { name: '', value: '' });

        return { ...currentPdfData };
    });
}
function removeChecklistRow(setPdfData, index) {
    if (typeof setPdfData !== 'function' || index === null || index === undefined) return;

    setPdfData((currentPdfData) => {
        currentPdfData.checklist.splice(index, 1);

        return { ...currentPdfData };
    });
}

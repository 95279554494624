import {createContext, useContext, useEffect, useState} from 'react';

import './planning.scss'
import IF from "UI/App/Components/Conditional/IF";
import TimeSelector from './ViewOptions/TimeSelector';
import Weekly from './ViewOptions/Week/Weekly';
import WeeklyHeader from './ViewOptions/Week/WeeklyHeader';
import Monthly from './ViewOptions/Month/Monthly';
import MonthHeader from './ViewOptions/Month/MonthlyHeader';
import Daily from './ViewOptions/Day/Daily';
import DailyHeader from './ViewOptions/Day/DailyHeader';
import MachineDropdown from "./MachineDropdown";
import {useNavigate, useParams} from 'react-router-dom';
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import {SpinnerOverlay} from "UI/App/Components/Spinner";
import isEmpty from "lodash/isEmpty";
import {stringify} from "qs";
import Popup, {closePopup, openPopup} from 'UI/App/Components/Popup/Popup';
import Icon from "UI/App/Components/Icon/Icon";
import Boolean from "../../../Components/Form/Boolean";
import useWindowDimensions from 'App/Util/useWindowDimensions';
import UpdateMachinePlanningPopup from "UI/App/Partials/Content/Planning/UpdateMachinePlanningPopup";
import Button from "UI/App/Components/Button/Button";
import ConfirmationPopup from "UI/App/Components/Popup/Popups/Util/ConfirmationPopup";
import HasRole from "UI/App/Components/Auth/HasRole";

const priorities = [
    {
        label: '1 (Hoog)',
        value: 1,
    },
    {
        label: '2',
        value: 2,
    },
    {
        label: '3',
        value: 3,
    },
    {
        label: '4',
        value: 4,
    },
    {
        label: '5 (Normaal)',
        value: 5,
    },
    {
        label: '6',
        value: 6,
    },
    {
        label: '7',
        value: 7,
    },
    {
        label: '8',
        value: 8,
    },
    {
        label: '9 (Laag)',
        value: 9,
    }
]

const progress = [
    {label: 'Niet begonnen', value: 0},
    {label: 'Mee bezig', value: 50},
    {label: 'Afgerond', value: 100}
]

/**
 *
 * @type {React.Context<{machines: any}>}
 */
export const PlanningContext = createContext({
    machines: [],
    startDate: new Date(),
    endDate: new Date(),
});

export default function Planning() {

    // Current time
    const now = new Date();

    const dateFormatOptionsMonthly = {
        month: 'long',
        year: 'numeric',
    };

    const dateFormatOptionsWeekly = {
        day: 'numeric',
        month: 'short',

    };

    const dateFormatOptionsDaily = {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    };

    const {type, id} = useParams();

    const [planningData, setPlanningData] = useState({});
    const [selectedTime, setSelectedTime] = useState(type === 'all' ? "WEEKLY" : localStorage.getItem("selected-time") || "MONTHLY");
    const [selectedType, setSelectedType] = useState("MACHINES");
    const [selectedDateMonthly, setSelectedDateMonthly] = useState(localStorage.getItem("selected-date-monthly") || now.toLocaleDateString('nl-NL', dateFormatOptionsMonthly));
    const [monday, setMonday] = useState(getInitialStartDate());
    const [sunday, setSunday] = useState(getInitialEndDate());
    const [selectedDateDaily, setSelectedDateDaily] = useState(localStorage.getItem("planning-selected-date-daily") || now);
    const [isShowMode, setIsShowMode] = useState(false);
    const [planningError, setPlanningError] = useState(false);
    const [frontendStatus, setFrontendStatus] = useState({
        isLoading: true,
        loadingMessage: 'Planning ophalen...',
        frontendReady: false,
    });
    const [showModeActivator, setShowModeActivator] = useState(null)

    // machine popup states
    const [popupFormData, setPopupFormData] = useState({});
    const [assignToMachineOptions, setAssignToMachineOptions] = useState([]);
    const [popupCurrentMachine, setPopupCurrentMachine] = useState(null);
    const [visualizeDependencies, setVisualizeDependencies] = useState(true)

    //add machine popup data
    const [addMachinePopupData, setAddMachinePopupData] = useState(null);
    const [addMachinePopupFormData, setAddMachinePopupFormData] = useState(null);

    const {authAxios} = useContext(FetchContext);
    const {height, width} = useWindowDimensions();

    const navigate = useNavigate();

    const days = Array(7).fill('').map((_, i) => {
        const date = new Date(monday);
        date.setUTCDate(date.getUTCDate() + i);
        const options = {weekday: 'long', day: 'numeric', month: 'short'};
        return date.toLocaleDateString('nl-NL', options);
    });

    useEffect(() => {
        function saveWithKeyBind(e) {
            if (window.location.pathname.includes('/planning')) {
                if ((e.metaKey || e.ctrlKey) && e.key === 's') {
                    e.preventDefault();
                }

                if ((e.metaKey || e.ctrlKey) && e.key === 's') {
                    savePlanning();
                }
            }
        }

        document.addEventListener("keydown", saveWithKeyBind);

        return () => {
            document.removeEventListener("keydown", saveWithKeyBind);
        }
    }, [planningData])

    useEffect(() => {
        async function fetchData() {
            const planningData = await getData();
            setPlanningData(planningData);
        }

        fetchData().catch((error) => {
            setPlanningError(String(error));
        });
    }, [authAxios, id, type, selectedType]);

    useEffect(() => {
        if (!isEmpty(planningData)) {
            updateFrontendStatus('frontendReady', true);
            updateFrontendStatus('isLoading', false);
        }
    }, [planningData]);

    useEffect(() => {
        setPlanningData([])
    }, [selectedType])

    useEffect(() => {
        localStorage.setItem("selected-time", selectedTime);
    }, [selectedTime])

    useEffect(() => {
        localStorage.setItem("selected-type", selectedType);
    }, [selectedType])

    useEffect(() => {
        localStorage.setItem("selected-date-monthly", selectedDateMonthly);
    }, [selectedDateMonthly])

    useEffect(() => {
        localStorage.setItem("selected-date-weekly-monday", monday.toISOString());
    }, [monday])

    useEffect(() => {
        localStorage.setItem("selected-date-weekly-sunday", sunday.toISOString());
    }, [sunday])

    useEffect(() => {
        localStorage.setItem("planning-selected-date-daily", selectedDateDaily);
    }, [selectedDateDaily]);

    function deletePlanning() {
        authAxios.delete(`/orders/${id}/planning`).then(() => {
            // navigate
            navigate(`/orders/${id}`)
            toast.success('Order uit planning verwijderd.');
        }).catch((error) => {
            console.error(error);
            toast.error('Er is iets misgegaan bij het verwijderen van de order uit de planning.');
        });
    }

    function getInitialStartDate() {
        const localStorageDate = new Date(localStorage.getItem("selected-date-weekly-monday"));
        if (localStorageDate && !isNaN(new Date(localStorageDate).getTime())) {
            localStorageDate.setUTCHours(0, 0, 0, 0);
            return localStorageDate;
        } else {
            return setWeeklyMonday(now);
        }
    }

    function getInitialEndDate() {
        const localStorageDate = new Date(localStorage.getItem("selected-date-weekly-sunday"));
        if (localStorageDate && !isNaN(new Date(localStorageDate).getTime())) {
            localStorageDate.setUTCHours(0, 0, 0, 0);
            return localStorageDate;
        } else {
            return setWeeklySunday(now);
        }
    }

    function updateFrontendStatus(key, value) {
        setFrontendStatus(prev => ({
            ...prev,
            [key]: value,
        }));
    }

    async function getAllMachines() {
        return await authAxios
            .get(`/calculations/resources/machines/all?populate=name`)
            .then((data) => {
                let machinesById = {};

                for (const machine of data.data) {
                    machinesById[machine.id] = machine;
                }

                return machinesById
            })
    }

    // Gets data from endpoints and return as usable data
    async function getData(givenType, givenScope) {
        updateFrontendStatus('isLoading', true);

        // Get matching data set
        const dataSet = await getDataSet(type, givenScope);

        updateFrontendStatus('frontendReady', true);
        updateFrontendStatus('isLoading', false);

        return dataSet;
    }

    async function getDataSet(givenType, givenScope) {
        switch (givenType) {
            case 'machine':
                return await getMachineData(givenType, givenScope);
            case 'machines':
                updateFrontendStatus('loadingMessage', 'Machine ophalen...');
                return await getMachinesData(givenType, givenScope);
            case 'orders':
                updateFrontendStatus('loadingMessage', 'Order ophalen...');
                return await getOrdersData(givenScope);
            case 'all':
                updateFrontendStatus('loadingMessage', 'Orders ophalen...');
                return await getAllPlanning(givenScope);
            default:
                toast.error(`Onbekend planning type ${givenType || type}!`);
                return false;
        }
    }

    async function getAllPlanning(givenScope) {
        updateFrontendStatus('loadingMessage', 'Orders ophalen...');
        const allMachines = await getAllMachines();
        return await authAxios
            .get(`/orders/planning`)
            .then(({data}) => {

                function formatMachine(plannedMachine, number) {
                    const colorIndex = (number.match(/[1-9][0-9]*$/)?.[0] ?? 0) % 19 + 1;
                    return {
                        ...plannedMachine,
                        colorIndex: colorIndex,
                        planning: plannedMachine.planning.map((planningItem) => {
                            return {
                                ...planningItem,
                                colorIndex: colorIndex,
                            }
                        })
                    };
                }


                data.machines = [];
                let orderKey = 0;
                for (const order of data) {
                    for (const machine of order.machines) {
                        const formattedMachine = formatMachine(machine, order.number);
                        formattedMachine.colorIndex = orderKey;
                        formattedMachine.number = order.number;
                        formattedMachine.description = order.description;

                        data.machines.push({
                            ...formattedMachine,
                        })
                    }
                    orderKey++;
                }

                // Gather machine data
                data.machines = data?.machines?.map((machine, index) => ({
                    ...machine,
                    index: parseInt(machine.index),
                    company: data.company,
                    name: allMachines[machine.machineId] !== undefined ? allMachines[machine.machineId].name : machine.name,
                    planning: machine.planning.map((day) => ({
                        ...day,
                        projectId: data.id,
                        name: machine.name,
                        machineId: machine.machineId,
                        company: data.company,
                        number: machine.number,
                        info: machine.name,
                        parentId: machine.id,
                        persons: (day.persons ?? []).map(person => person.id)
                    }))
                }));

                return data;
            })
            .catch((err) => {
                console.error(err);
                throw new Error('Er is iets misgegaan bij het ophalen van de planning!');
            });
    }

    async function getOrdersData(givenScope) {
        updateFrontendStatus('loadingMessage', 'Orders ophalen...');

        const allMachines = await getAllMachines();

        return await authAxios
            .get(`/orders/${givenScope || id}/planning`)
            .then(({data}) => {
                let machineDataCopy = {...data}

                machineDataCopy.machines = data.machines.map(plannedMachine => {
                    return {
                        ...plannedMachine,
                    };
                });

                // Gather machine data
                data.machines = machineDataCopy?.machines?.map((machine, index) => ({
                    ...machine,
                    colorIndex: (index + 1) % 19 + 1,
                    index: parseInt(machine.index),
                    company: data.company,
                    name: allMachines[machine.machineId] !== undefined ? allMachines[machine.machineId].name : machine.name,
                    planning: machine.planning.map((day) => ({
                        ...day,
                        projectId: data.id,
                        name: machine.name,
                        machineId: machine.machineId,
                        colorIndex: (index + 1) % 19 + 1,
                        company: data.company,
                        number: data.number,
                        info: machine.name,
                        parentId: machine.id,
                        persons: (day.persons ?? []).map(person => person.id)
                    }))
                }));

                return data;
            })
            .catch((err) => {
                console.error(err);
                throw new Error('Er is iets misgegaan bij het ophalen van de planning!');
            });
    }

    async function getMachine(id) {
        const machinesQuery = stringify({
            fields: ['name'],
            sort: ['name:ASC'],
            noCalculate: true,
        });

        return await authAxios
            .get(`/calculations/resources/machines/${id}?${machinesQuery}`)
            .then(({data}) => {
                return {data};
            })
            .catch((err) => {
                console.error(err);
                throw new Error('Er is iets misgegaan bij het ophalen van de planning!');
            });
    }

    async function getMachinesData(givenType, givenScope) {

        function getModifiedProjectData(project, machine) {
            return {
                id: project.id,
                name: project.description,
                startDate: machine.startDate,
                endDate: machine.endDate,
                colorIndex: (project.number.match(/[1-9][0-9]*$/)?.[0] ?? 0) % 19 + 1,
                number: project.number,
                index: project.id,
                info: machine.name,
                company: project.company,
                totalHours: machine.totalHours,
                planning: machine.planning.map((day) => ({
                    ...day,
                    name: project.description,
                    machineId: machine.machineId,
                    colorIndex: (project.number.match(/[1-9][0-9]*$/)?.[0] ?? 0) % 19 + 1,
                    company: project.company,
                    parentId: project.id,
                    number: project.number,
                    info: machine.name,
                }))
            }
        }

        const data = await authAxios
            .get(`/calculations/resources/machines/${id}/planning`)
            .then(({data}) => {
                return data;
            })
            .catch((err) => {
                console.error(err);
                throw new Error('Er is iets misgegaan bij het ophalen van de planning!');
            });

        // const machine = await getData('machine', givenScope || id);
        const machine = await getMachine(givenScope || id);
        let projectList = [];
        data.forEach((project) => {
            project.machines.forEach((machine) => {
                projectList.push(getModifiedProjectData(project, machine));
            })
        })

        return {
            ...data,
            name: machine.name,
            machines: projectList,
            id: machine.id,
        }
    }

    async function getMachineData(givenType, givenScope) {
        // updateFrontendStatus('loadingMessage', Machine ophalen'...');
        const machinesQuery = stringify({
            fields: ['name'],
            sort: ['name:ASC'],
            noCalculate: true,
        });

        return await authAxios
            .get(`/calculations/resources/machines/${givenScope || id}?${machinesQuery}`)
            .then(({data}) => {
                return data;
            })
            .catch((err) => {
                console.error(err);
                throw new Error('Er is iets misgegaan bij het ophalen van de planning!');
            });
    }

    function setWeeklyMonday(date) {

        const newDate = new Date(date);
        let currentDayOfWeek = newDate.getUTCDay();
        if (currentDayOfWeek === 0) {
            currentDayOfWeek = 7
        }
        newDate.setUTCDate(newDate.getUTCDate() + 1 - currentDayOfWeek);
        newDate.setUTCHours(0, 0, 0, 0);
        return newDate;
    }

    function setWeeklySunday(date) {

        const newDate = new Date(date);
        let currentDayOfWeek = newDate.getUTCDay();
        if (currentDayOfWeek === 0) {
            currentDayOfWeek = 7
        }
        newDate.setUTCDate(newDate.getUTCDate() + (7 - currentDayOfWeek));
        return newDate;
    }

    function updatePlanningData(data) {
        if (type === 'orders') {
            const newData = {...data};
            // update start and end dates of the machines
            newData.machines = newData?.machines?.map((machine) => {
                // sort planningitems on date (asc)
                const sortedPlanning = machine?.planning?.sort((a, b) => new Date(a.date) - new Date(b.date));

                // first entry is first date
                const startDate = new Date(sortedPlanning[0]?.date) === 'Invalid Date' ? machine?.startDate : new Date(sortedPlanning[0]?.date);
                // last entry is last date
                const endDate = new Date(sortedPlanning[sortedPlanning.length - 1]?.date) === 'Invalid Date' ? machine.endDate : new Date(sortedPlanning[sortedPlanning.length - 1]?.date);
                // return the machine with new dates
                return {
                    ...machine,
                    startDate,
                    endDate,
                }
            })
            setPlanningData(newData);
        } else {
            setPlanningData(data);
        }
    }

    function getStartDate() {
        // If hidden order than start this week
        if (JSON.parse(process.env.REACT_APP_HIDDEN_ORDER_IDS).includes(String(planningData.id))) {
            return getMonday(new Date());
        }

        // If no end date start monday this week
        if (planningData.endDate === undefined) {
            return getMonday(new Date());
        }

        // if end is set and after today start at beginnen of project
        if (new Date() > new Date(planningData.endDate)) {
            return getMonday(new Date(planningData.startDate))
        }

        // default to monday this week
        return getMonday(new Date());
    };

    function getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    async function savePlanning() {

        updateFrontendStatus('loadingMessage', 'Planning bijwerken...');
        updateFrontendStatus('isLoading', true);

        try {
            if (type === 'all') {
                const pendingStorage = toast.loading("Aan het opslaan...");
                const orders = [];
                let totalOrders = 0;
                // Get orders by number

                let ordersByNumber = [];
                Object.entries(planningData).map(async ([key, order]) => {
                    if (key !== 'machines') {
                        order.machines = [];
                        ordersByNumber[order.number] = order
                    }
                })

                for (const machine of planningData.machines) {
                    const order = ordersByNumber[machine.number];
                    if (orders[order.id] === undefined) {
                        orders[order.id] = order;
                        totalOrders++;
                    }

                    orders[order.id].machines.push(machine);
                }

                let currentUpdate = 1;
                for (const order of orders) {
                    if (order === undefined) continue;

                    await authAxios.put(`/orders/${order.id}/planning`, {
                        machines: order.machines
                    }).then(() => {
                        toast.update(pendingStorage, {
                            render: `Order planning updaten (${currentUpdate}/${totalOrders}).`,
                            type: "info",
                            isLoading: true
                        });
                        currentUpdate++;
                    });
                }

                toast.update(pendingStorage, {
                    render: `Klaar met opslaan: ${totalOrders} orders planningen geupdate.`,
                    type: "success",
                    isLoading: false,
                    autoClose: 4500
                });

            } else if (type === 'machines') {

                const {machines} = planningData;
                await authAxios.put(`/orders/${id}/planning`, {
                    machines: machines
                });

                return
            } else if (!Array.isArray(planningData)) {
                const {machines} = planningData;

                await authAxios.put(`/orders/${id}/planning`, {
                    machines: machines
                }).then(() => {
                    toast.success('Planning bijgewerkt!');
                });
            } else {
                console.log('store to simple order');
            }

        } catch (error) {
            console.error(error);
            toast.error('Er is iets misgegaan bij het opslaan van de planning!');
        } finally {
            updateFrontendStatus('isLoading', false);
        }
    }

    function addMachine(machine) {
        let newPlanningData = {...planningData};

        // Get the dates from the form and force UTC
        const start = new Date(addMachinePopupFormData.startDate + 'T00:00:00.000Z');
        const end = new Date(addMachinePopupFormData.endDate + 'T00:00:00.000Z');

        if (start > end) {
            toast.error('De startdatum kan niet na de einddatum zijn!');
            return;
        }

        const totalHours = addMachinePopupFormData.totalHours;
        const daysBetween = Math.round((end - start) / (24 * 60 * 60 * 1000));
        let totalWorkingDaysBetween = 0;

        for (let i = 0; i <= daysBetween; i++) {
            const currentDate = new Date(start);
            currentDate.setUTCDate(start.getUTCDate() + i);

            if (currentDate.getUTCDay() === 0 || currentDate.getUTCDay() === 6) {
                continue;
            }

            totalWorkingDaysBetween++;
        }

        const timePerItem = totalHours / (totalWorkingDaysBetween || 1);
        const planningItems = [];

        let prevUUID = null;
        if (daysBetween === 0) {
            const currentDate = new Date(start);

            if (currentDate.getUTCDay() === 0) {
                toast.warn('enkele dag planning mag niet op zondag vallen.');
                return;
            }

            const planningItem = {
                date: currentDate.toISOString().substring(0, 10),
                machineId: machine.id,
                name: machine.name,
                time: timePerItem,
                uuid: crypto.randomUUID(),
                persons: [],
                dependsOn: prevUUID ? [prevUUID] : [],
            };

            prevUUID = planningItem.uuid;

            planningItems.push(planningItem);
        } else {
            for (let i = 0; i <= daysBetween; i++) {

                const currentDate = new Date(start);
                currentDate.setUTCDate(start.getUTCDate() + i);

                if (currentDate.getUTCDay() === 0 || currentDate.getUTCDay() === 6) {
                    continue;
                }

                const planningItem = {
                    date: currentDate.toISOString().substring(0, 10),
                    machineId: machine.id,
                    name: machine.name,
                    time: timePerItem,
                    uuid: crypto.randomUUID(),
                    persons: [],
                    dependsOn: prevUUID ? [prevUUID] : [],
                };

                prevUUID = planningItem.uuid;

                planningItems.push(planningItem);
            }
        }

        newPlanningData.machines.push({
            machineId: machine.id,
            index: newPlanningData.machines.length,
            endDate: end,
            name: addMachinePopupFormData.alias,
            planning: planningItems,
            startDate: start,
            totalHours: totalHours,
        });

        try {
            setPlanningData(newPlanningData);
            setTimeout(async () => {
                try {
                    await savePlanning()

                    updateFrontendStatus('loadingMessage', 'Planning ophalen...');
                    updateFrontendStatus('isLoading', true);

                    const planningData = await getData();
                    setPlanningData(planningData);

                    updateFrontendStatus('isLoading', false);
                } catch (e) {
                    console.error(e);
                    toast.error('Er is iets misgegaan bij het ophalen van de planning!');
                }
            }, 100)
        } catch (e) {
            console.error(e);
            toast.error('Er is iets misgegaan bij het toevoegen van de machine!');
        }
    }

    if (!frontendStatus.frontendReady) return <SpinnerOverlay visible={true} message={frontendStatus.loadingMessage}/>

    if (planningError) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                textAlign: 'center'
            }}>
                <span style={{
                    color: 'red',
                    fontSize: '14pt'
                }}>{planningError}</span>
            </div>
        )
    }

    function updateMachineSettings(event) {
        event.preventDefault();

        const updateMachineSettings = (machine) => {
            const planningDayIndex = machine.planning.findIndex(planningDay => planningDay.uuid === popupCurrentMachine.uuid);
            if (planningDayIndex !== -1) {

                if (popupFormData?.time && machine.planning[planningDayIndex].time) {
                    machine.planning[planningDayIndex].time = popupFormData?.time;
                }

                if (popupFormData?.priority && machine.planning[planningDayIndex]?.priority !== undefined) {
                    machine.planning[planningDayIndex].priority = popupFormData?.priority?.value;
                }

                if (popupFormData?.progress && machine.planning[planningDayIndex]?.progress !== undefined) {
                    machine.planning[planningDayIndex].progress = popupFormData?.progress?.value;
                }

                if (popupFormData?.note && machine.planning[planningDayIndex]?.note !== undefined) {
                    machine.planning[planningDayIndex].note = popupFormData?.note;
                }

                if (popupFormData.amount && machine.planning[planningDayIndex]?.amount !== undefined) {
                    machine.planning[planningDayIndex].amountProduced = Number(popupFormData?.amount);
                }

                if (popupFormData?.operators) {
                    if (popupFormData?.operators?.some(({canOperate}) => canOperate)) {

                        if (!window.confirm('Niet alle gekozen operators kunnen deze machine bedienen! Wil je deze operators toevoegen als operator?')) {
                            return;
                        }

                        addOperatorsToMachine(authAxios, popupFormData?.operators.filter(({canOperate}) => !canOperate), machine.machineId, (operators) => {
                            setAssignToMachineOptions((options) => {
                                return options.map((option) => {
                                    if (operators.some(({value}) => value === option.value)) {
                                        return {
                                            ...option,
                                            canOperate: true
                                        }
                                    }
                                    return option;
                                })
                            });
                        });
                    }

                    machine.planning[planningDayIndex].persons = popupFormData?.operators.map(({value}) => value);
                }
            }

            return machine;
        }

        setPlanningData(planningData => {
            try {
                // For updating in "all" view
                if (type === 'all') {
                    for (const [orderKey, order] of Object.entries(planningData)) {
                        for (const [machineKey, machine] of Object.entries(order.machines ?? [])) {
                            planningData[orderKey].machines[machineKey] = updateMachineSettings(machine);
                        }
                    }

                    for (const machine of planningData.machines) {
                        updateMachineSettings(machine);
                    }

                    return {...planningData};
                }

                // Settings for a single project
                else if (type === 'orders') {
                    for (const machine of planningData.machines) {
                        updateMachineSettings(machine);
                    }

                    return {...planningData}
                }

                // For every thing else
                else {
                    throw new Error('Unknown planning type: ' + type);
                }
            } catch (error) {
                console.error(error);
                toast.error('Er is iets misgegaan bij het bijwerken van de machine!');
            } finally {
                closePopup();
            }

            return planningData;
        })
    }

    function showAllDependencyOptions(item, isShowingOptions) {
        const newData = [...planningData];

        if (!isEmpty(newData)) {
            const updatedData = newData.map((machine) => {
                if (!isEmpty(machine.machines)) {
                    return {
                        ...machine,
                        machines: machine.machines.map((machineItem) => {
                            if (!isEmpty(machineItem.planning)) {
                                return {
                                    ...machineItem,
                                    planning: machineItem.planning.map((planning) => {
                                        planning.isDependencyOption = true;
                                        if (isShowingOptions) {
                                            planning.isDependencyOption = false;
                                        } else if (
                                            planning.projectId === item.projectId &&
                                            !planning.dependsOn.some(dependency => dependency === item.uuid) &&
                                            item.uuid !== planning.uuid
                                        ) {
                                            planning.isDependencyOption = true;
                                        } else {
                                            planning.isDependencyOption = false;
                                        }
                                        return planning;
                                    }),
                                };
                            }
                            return machineItem;
                        }),
                    };
                }
                return machine;
            });
            setPlanningData(updatedData);
        }
    }

    function createAllDependency(clickedItem) {
        const newData = [...planningData];

        if (!isEmpty(newData)) {
            const updatedData = newData.map((machine) => {
                if (!isEmpty(machine.machines)) {
                    return {
                        ...machine,
                        machines: machine.machines.map((machineItem) => {
                            if (!isEmpty(machineItem.planning)) {
                                return {
                                    ...machineItem,
                                    planning: machineItem.planning.map((planning) => {
                                        if (planning.uuid === clickedItem.uuid) {
                                            planning.dependsOn.push(showModeActivator.uuid);
                                        }
                                        return planning;
                                    }),
                                };
                            }
                            return machineItem;
                        }),
                    };
                }
                return machine;
            });
            setPlanningData(updatedData);
        }
        setIsShowMode(false);
    }

    function removeAllDependency(dependency, location) {
        const newData = [...planningData];
        newData.forEach((machines) => {
            if (!isEmpty(machines.machines)) {
                machines.machines.forEach((machine) => {
                    if (!isEmpty(machine.planning)) {
                        machine.planning.forEach((planning) => {
                            if (location === planning.uuid && !isEmpty(planning.dependsOn)) {
                                planning.dependsOn = planning.dependsOn.filter((dependent) => dependent !== dependency);
                            }
                        })
                    }
                })
            }
        })
        setPlanningData(newData);
    }

    const getInfoMessageGridTemplateColumns = () => {
        if (width > 350) return '30px 1fr 30px';
        if (width > 300) return '1fr 30px';
        return '1fr';
    }

    return (
        (width <= 1100 || height <= 300) ?
            <>
                <div
                    className="info__message"
                    style={{
                        gridTemplateColumns: getInfoMessageGridTemplateColumns()
                    }}
                >
                    {width > 350 && <Icon style={{fill: 'var(--primary)', height: '20px'}} name={'information'}/>}
                    Deze planning kan alleen worden bekeken vanaf desktop
                </div>
            </> :
            <SpinnerOverlay visible={frontendStatus.isLoading} message={frontendStatus.loadingMessage}>
                <PlanningContext.Provider value={{
                    machines: planningData.machines,
                    startDate: monday,
                    endDate: sunday
                }}>
                    <div className="planning__container">
                        <div className="planning__header">
                            <div className="planning__header__filters">
                                <TimeSelector
                                    selectedTime={selectedTime}
                                    setSelectedTime={setSelectedTime}
                                />
                                <IF condition={type.toLowerCase() !== 'machines'}>
                                    <Boolean
                                        displayFalse='View'
                                        displayTrue='Edit'
                                        value={visualizeDependencies}
                                        field={{name: 'visualizeDependencies'}}
                                        setValue={(name, value) => {
                                            setVisualizeDependencies(value)
                                        }}/>
                                </IF>
                            </div>

                            <div className="planning__header__date">
                                <div className='planning__header__date__content'>
                                    <IF condition={selectedTime === "DAILY"}>
                                        <DailyHeader
                                            now={now}
                                            selectedDateDaily={selectedDateDaily}
                                            setSelectedDateDaily={setSelectedDateDaily}
                                            formatOptions={dateFormatOptionsDaily}
                                        />
                                    </IF>
                                    <IF condition={selectedTime === "WEEKLY"}>
                                        <WeeklyHeader
                                            now={now}
                                            monday={monday}
                                            setMonday={setMonday}
                                            setWeeklyMonday={setWeeklyMonday}
                                            sunday={sunday}
                                            setSunday={setSunday}
                                            setWeeklySunday={setWeeklySunday}
                                            formatOptions={dateFormatOptionsWeekly}
                                            startDate={getStartDate()}
                                        />
                                        <div className="planning__header__save">
                                            <IF condition={type.toLowerCase() !== 'machines'}>
                                                <button onClick={() => savePlanning()} className={'btn btn--black btn--round'}><Icon name={'save'}/></button>
                                            </IF>
                                        </div>
                                    </IF>
                                    <IF condition={selectedTime === "MONTHLY"}>
                                        <MonthHeader
                                            selectedDateMonthly={selectedDateMonthly}
                                            setSelectedDateMonthly={setSelectedDateMonthly}
                                            formatOptions={dateFormatOptionsMonthly}
                                            startDate={getStartDate()}
                                        />
                                        <IF condition={type.toLowerCase() !== 'machines'}>
                                            <div className="planning__header__save">
                                                <button onClick={() => savePlanning()} className={'btn btn--black btn--round'}><Icon name={'save'}/></button>
                                            </div>
                                        </IF>
                                    </IF>
                                    <HasRole roles={['admin', 'management', 'productie manager']}>
                                        <IF condition={type.toLowerCase() === 'orders'}>
                                            <Button
                                                style={{marginLeft: '5px'}}
                                                data-title={'Planning verwijderen'}
                                                className={'btn btn--black btn--round'}
                                                onClick={() => openPopup("deletePlanning")}
                                            >
                                                <Icon name={'calendar-circle-minus'}/>
                                            </Button>
                                        </IF>
                                    </HasRole>
                                </div>
                            </div>
                        </div>
                        <div className="planning__content">
                            <IF condition={isShowMode}>
                                <div
                                    className="show__overlay"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsShowMode(false);
                                    }}
                                />
                            </IF>
                            <IF condition={selectedTime === "DAILY"}>
                                <Daily
                                    type={type}
                                    data={planningData}
                                    setData={(data) => updatePlanningData(data)}
                                    selectedDateDaily={selectedDateDaily}
                                />
                            </IF>
                            <IF condition={selectedTime === "WEEKLY"}>
                                {(() => {
                                    if (type === 'all' && selectedType === 'MACHINES') {
                                        return <>
                                            <div className="planning__content__header__date__container__all">
                                                <div className="planning__content__header__date__filler"/>
                                                <div className="planning__content__header__date__filler"/>
                                                {
                                                    days.map((day, index) => {
                                                        const [dayDate, date, month] = day.split(' ');
                                                        return (
                                                            <span
                                                                key={date + '-' + month}
                                                                className="planning__content__header__date__item"
                                                            >
                                                            <span
                                                                className="planning__content__header__date__item__day"
                                                            >
                                                                {dayDate}
                                                            </span>
                                                            <span
                                                                className="planning__content__header__date__item__date"
                                                            >
                                                                <span className="date">
                                                                    {date}
                                                                </span>
                                                            </span>
                                                        </span>
                                                        )
                                                    })
                                                }
                                                <div className="planning__content__header__date__filler"/>
                                            </div>

                                            <Weekly
                                                visualizeDependencies={visualizeDependencies}
                                                key={`weekly-planning-1`}
                                                type={type}
                                                id={id}
                                                data={planningData}
                                                setData={(data) => updatePlanningData(data, 1)}
                                                allData={planningData}
                                                monday={monday}
                                                sunday={sunday}
                                                planningIndex={1}
                                                selectedType={selectedType}
                                                setPopupCurrentMachine={setPopupCurrentMachine}
                                                setPopupFormData={setPopupFormData}
                                                setIsShowMode={setIsShowMode}
                                                isShowMode={isShowMode}
                                                showAllDependencyOptions={showAllDependencyOptions}
                                                createAllDependency={createAllDependency}
                                                removeAllDependency={removeAllDependency}
                                                setShowModeAllActivator={setShowModeActivator}
                                            />
                                        </>
                                    } else if (type !== 'all') {
                                        return (
                                            <Weekly
                                                visualizeDependencies={visualizeDependencies}
                                                type={type}
                                                id={id}
                                                data={planningData}
                                                setData={(data) => updatePlanningData(data)}
                                                monday={monday}
                                                sunday={sunday}
                                                planningIndex={0}
                                                selectedType={type ? type.toUpperCase() : null}
                                                setPopupCurrentMachine={setPopupCurrentMachine}
                                                setPopupFormData={setPopupFormData}
                                                setIsShowMode={setIsShowMode}
                                                isShowMode={isShowMode}
                                            />
                                        );
                                    }
                                })()}
                            </IF>
                            <IF condition={selectedTime === "MONTHLY"}>
                                <Monthly
                                    visualizeDependencies={visualizeDependencies}
                                    type={type}
                                    id={id}
                                    data={planningData}
                                    setData={(data) => updatePlanningData(data)}
                                    selectedDateMonthly={selectedDateMonthly}
                                    setPopupCurrentMachine={setPopupCurrentMachine}
                                    setPopupFormData={setPopupFormData}
                                    setIsShowMode={setIsShowMode}
                                    isShowMode={isShowMode}
                                />
                            </IF>
                        </div>
                    </div>
                </PlanningContext.Provider>

                <IF condition={type === 'orders' && selectedTime === 'WEEKLY'}>
                    <MachineDropdown
                        plannedMachines={planningData.machines}
                        setAddMachinePopupData={setAddMachinePopupData}
                    />
                </IF>

                {/* Update machine popup */}
                <UpdateMachinePlanningPopup
                    onSubmit={updateMachineSettings}
                    popupFormData={popupFormData}
                    setPopupFormData={setPopupFormData}
                    popupCurrentMachine={popupCurrentMachine}
                />

                <ConfirmationPopup
                    popupID={"deletePlanning"}
                    title="Verwijderen"
                    subject="Order uit planning verwijderen"
                    description="Weet u zeker dat u deze order uit de planning wilt verwijderen?"
                    functionOnConfirm={() => deletePlanning()}
                />

                {/* add machine popup form */}
                <Popup
                    title={`Voeg ${addMachinePopupData?.name} toe`}
                    popupID='addMachineToPlanningPopup'
                >
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            addMachine(addMachinePopupData);
                            closePopup();
                        }}
                        onKeyDown={e => e.stopPropagation()}
                    >
                        <div className="input-group">
                            <label htmlFor="machine-alias">
                                Alias
                            </label>
                            <input
                                required
                                type="text"
                                id="machine-alias"
                                onChange={(event) => {
                                    setAddMachinePopupFormData((prev) => ({
                                        ...prev,
                                        alias: event.target.value,
                                    }))
                                }}
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="machine-date-start">
                                Begindatum
                            </label>
                            <input
                                required
                                type="date"
                                id="machine-date-start"
                                // defaultValue={(new Date).toISOString().split('T')[0]}
                                onChange={(event) => {
                                    setAddMachinePopupFormData((prev) => ({
                                        ...prev,
                                        startDate: event.target.value,
                                    }))
                                }}
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="machine-date-end">
                                Einddatum
                            </label>
                            <input
                                required
                                type="date"
                                id="machine-date-end"
                                // defaultValue={(new Date).toISOString().split('T')[0]}
                                onChange={(event) => {
                                    setAddMachinePopupFormData((prev) => ({
                                        ...prev,
                                        endDate: event.target.value,
                                    }))
                                }}
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="machine-hours">
                                Totaal aantal uur
                            </label>
                            <input
                                required
                                type="number"
                                step={0.01}
                                id="machine-hours"
                                onChange={(event) => {
                                    setAddMachinePopupFormData((prev) => ({
                                        ...prev,
                                        totalHours: event.target.value,
                                    }))
                                }}
                            />
                        </div>
                        <button type='submit'>Toevoegen</button>
                    </form>
                </Popup>
            </SpinnerOverlay>
    )
}

export function addOperatorsToMachine(authAxios, operators, machineId, callback) {
    const updateToast = toast.info('Operators toevoegen...', {
        isLoading: true,
        autoClose: false
    });

    authAxios.put(`/calculations/resources/machines/${machineId}/operators`, {
        operators: operators.map(operator => operator.value)
    }).then(() => {
        toast.update(updateToast, {
            render: 'Operators toegevoegd!',
            isLoading: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 5000
        });

        callback(operators);
    }).catch((error) => {
        toast.update(updateToast, {
            render: 'Er is iets misgegaan bij het toevoegen van de operators!',
            isLoading: false,
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });
    });
}

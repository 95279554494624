import Block from "../../../../Components/Block/Block";
import Popup, {closePopup, openPopup} from "../../../../Components/Popup/Popup";
import React, {useContext, useEffect, useState} from "react";
import WidgetCollection from "../Widgets/Widgets/WidgetCollection";
import {toast} from "react-toastify";
import {FetchContext} from "App/Strapi/FetchContext";
import WidgetFormRenderer from "../Widgets/Widgets/WidgetFormRenderer";
import Boolean from "../../../../Components/Form/Boolean";
import {useNavigate, useParams} from "react-router-dom";
import Grid from "../../../../Components/Grid/Grid";
import Icon from "UI/App/Components/Icon/Icon";
import Table from "UI/App/Components/Table/Table";
import LayoutEditor from "UI/App/Partials/Content/Calculations/Template/LayoutEditor";
import ChecklistEditor from "UI/App/Partials/Content/Calculations/Template/ChecklistEditor";
import {SpinnerOverlay} from "UI/App/Components/Spinner";
import Select from "react-select";
import strapiToast from "App/Util/errorHandle";
import {clone} from "lodash";
import {getWidgetValues} from "UI/App/Partials/Content/Calculations/Widgets/Widgets/WidgetBase";

export default function EditCalculationTemplate({type}) {

    const [addMachineContent, setAddMachineContent] = useState([]);
    const [calculationResult, setCalculationResult] = useState([]);
    const [tabButtonData, setTabButtonData] = useState([]);
    const [debug, setDebugMode] = useState(false);
    const fetchContext = useContext(FetchContext);
    const {authAxios} = useContext(FetchContext);
    const [amounts, setAmounts] = useState("1000");
    const [limitToOneAmount, setLimitToOneAmount] = useState(false);
    const [name, setName] = useState("");
    const [tabs, setTabs] = useState([]);
    const [tabsEditing, setTabsEditing] = useState(false);

    const [companiesWithAccess, setCompaniesWithAccess] = useState([]);

    const [companies, setCompanies] = useState([]);
    const [chosenCompany, setChosenCompany] = useState(null);

    // Webshop constants
    const [webshopTemplate, setWebshopTemplate] = useState(false);
    const [hasFixedSize, setHasFixedSize] = useState(true);
    // const [sizeData, setSizeData] = useState([[100, 200]]);
    const [sizeData, setSizeData] = useState([
        {
            "width": 148,
            "height": 210
        }
    ]);

    // const [amountData, setAmountData] = useState([[100, 150]])
    const [amountData, setAmountData] = useState([
        {
            "amount": 100,
            "misses": 50
        }
    ])


    // For margin
    const [hasFixedMargin, setHasFixedMargin] = useState(false);
    // const [marginData, setMarginData] = useState([5])
    const [marginData, setMarginData] = useState([
        {
            "amount": 100,
            "margin": 50
        }
    ])
    const [marginIsPercentage, setMarginIsPercentage] = useState(false);

    // For thirdParty work
    const [hasFixedThirdParty, setHasFixedThirdParty] = useState(false);
    // const [thirdPartyData, setThirdPartyData] = useState([5])
    const [thirdPartyData, setThirdPartyData] = useState([
        {
            "price": 0,
            "amount": 100
        }
    ])

    // For manual labour
    const [hasFixedManualLabour, setHasFixedManualLabour] = useState(false);
    // const [manualLabourData, setManualLabourData] = useState([5])
    const [manualLabourData, setManualLabourData] = useState([
        {
            "price": 0,
            "amount": 100
        }
    ])

    const [availableWidgets, setAvailableWidgets] = useState({});
    const [allWidgets, setAllWidgets] = useState({});


    const [checklistData, setChecklistData] = useState({});
    const [chosenChecklist, setChosenChecklist] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [chosenChecklistTemplate, setChosenChecklistTemplate] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [layout, setLayout] = useState([]);

    const params = useParams();
    const navigate = useNavigate();
    let init = true;

    useEffect(() => {
        if (!isLoading) {
            calculateForm();
        }
    }, [debug, webshopTemplate])

    useEffect(() => {
        loadCompanies()
    }, [companiesWithAccess]);

    /**
     * Use effect on page load and for debug calculate
     */
    useEffect(() => {
        // Default tab data for hot reload
        setTabs([]);
        setTabButtonData([]);

        // loadCompanies();
        getCompaniesWithAccess();

        /**
         * Get customers for customer select
         */
        if (type === 'update') {
            if (init) {
                init = false;
                authAxios.get(`/calculations/templates/${params.templateId}?populate=*`).then(({data: {data, meta}}) => {
                    let buttons = [];
                    let buttonData = [];
                    setTabs([]);
                    setName(data.name)
                    setAmounts(data.data.amounts);
                    setLimitToOneAmount(data.data.limitToOneAmount ?? false);
                    setWebshopTemplate(data.isWebshopTemplate);
                    // loadCompanies()

                    // add calculation settings
                    if (data.isWebshopTemplate) {

                        setHasFixedMargin(data.data.templateSettings?.margin);
                        setMarginIsPercentage(data.data.templateSettings?.marginIsPercentage);
                        setHasFixedSize(data.data.templateSettings?.size);
                        setHasFixedThirdParty(data.data.templateSettings?.thirdParty);
                        setHasFixedManualLabour(data.data.templateSettings?.manualLabor)

                        // Add checklist data
                        setChecklistData(data.data.checklist.values);
                        setSelectedLanguage(data.data.checklist.language);
                        setChosenChecklistTemplate(data.data.checklist.template);

                        setMarginData(data.data.templateSettingsData.margin)
                        setSizeData(data.data.templateSettingsData.sizes)
                        setAmountData(data.data.templateSettingsData.amounts)

                        setManualLabourData(data.data.templateSettingsData.manualLabour)
                        setThirdPartyData(data.data.templateSettingsData.thirdParty)

                        setLayout(data.data.widgetLayout);
                    }

                    const tabData = Object.entries(data.data)
                        .filter(([key, tab]) => /\d+-\d+/.test(key))
                        .map(([key, tab]) => {
                            tab.position = tab?.position ?? key.split('-')[0];
                            return [key, tab];
                        })
                        .sort((a, b) => a[1]?.position - b[1]?.position);

                    let machineAmount = 0;
                    for (const [key, value] of tabData) {

                        const prefix = key.split('-')[0];
                        const machineId = key.split('-')[1];
                        /**
                         * Get name
                         */
                        let name = null;
                        for (const constant of value.constants) {
                            if (constant.id.includes("const-name")) {
                                name = constant.value
                            }
                        }

                        if (name === null) {
                            name = value?.name ?? `${prefix}-${machineId}`
                        }

                        /**
                         * Set tab buttons
                         */
                        buttons.push(<button machineName={name} className="tablinks calculationTablinks" id={`tablink_${prefix}-${machineId}`} onClick={() => openTab(`${prefix}-${machineId}`)}>{name}</button>);

                        buttonData.push({
                            machineName: name,
                            id: `tablink_${prefix}-${machineId}`,
                            openTab: `${prefix}-${machineId}`,
                            position: machineAmount
                        })

                        /**
                         * Create options
                         * @type {JSX.Element}
                         */
                        let tabOptions = <Grid className={'tab-settings'} columns={2} key={`machine-options-${prefix}-${machineId}`} customColTemplate={"65px 1fr"} style={{margin: "10px 0"}}>
                            <div>
                                <label>Optioneel:</label>
                                <input className={"machineOptional"} type={"checkbox"} defaultChecked={value.optional}/>
                            </div>
                            <div>
                                <label>Tab benaming:</label>
                                <input placeholder={'Tab benaming'} className={"machineTitle"} type={"text"} defaultValue={value.name}/>
                            </div>
                        </Grid>

                        /**
                         * Add tabs
                         */
                        const constants = formatConstants(value.constants);
                        setTabs(prevState => [...prevState, <div key={`${prefix}-${machineId}`} onChange={calculateForm} className={"tabcontent hidden"} machineid={machineId} id={`${prefix}-${machineId}`}>{tabOptions}
                            <hr/>
                            <br/>
                            {constants}</div>])

                        /**
                         * Add widgets
                         */
                        setTimeout(function () { //Start the timer
                            new WidgetFormRenderer().renderForm(value.widgets, debug, prefix, `${prefix}-${machineId}`, true) //After 1 second, set render to true
                        }, 500)

                        setTimeout(function () { //Start the timer
                            openTab(`${prefix}-${machineId}`);
                        }, 500)
                        machineAmount++;
                    }

                    setTimeout(() => {
                        // Update constants when completely done
                        setTimeout(() => {
                            updateTemplateConstants();
                        }, 500)
                    }, (tabData.length * 75 + 500))
                    setTabButtonData(buttonData);

                    setTimeout(() => {
                        void calculateForm()
                    }, 1500)

                }).catch((e) => {
                    if (process.env.NODE_ENV === 'development') console.error(e);
                    toast.error(`Er is iets fout gegaan bij het ophalen van de widgets! ${e?.response?.status && `(${e.response.status})`}`);
                })
            }
        }
        else {
            setIsLoading(false);
        }
    }, []);

    async function giveCompanyAccess(){

        // First get company
        let company = await fetchContext.authAxios.get(`/crm/companies/${chosenCompany.value}?populate=templates`, {
            params: {}
        }).then(({data}) => {

            return data.data
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het ophalen van bedrijven!`);
        });

        // Valide if compnay has uuid
        company = await validateCompanyUUID(company)

        // Add template to company
        let companyCopy  = clone(company);

        if(companyCopy.templates === undefined) companyCopy.templates = [];

        companyCopy.templates.push(params.templateId);

        await authAxios.put(`/crm/companies/${company.id}`, {
            data:companyCopy
        }).then(({data}) => {
            // Add updated company data to company state
            getCompaniesWithAccess();
            toast.success('Bedrijf toegevoegd.');
        }).catch((e) => {
            strapiToast(e)
            console.error(e);
        });
    }

    async function getCompaniesWithAccess(){

        if(params.templateId === undefined) return

        await authAxios.get(`/calculations/templates/${params.templateId}?populate=companies`).then(({data}) => {

            let companiesWithAccessData = [];

            for(const company of data.data.companies ?? []){

                companiesWithAccessData.push({
                    data: [
                        company.name,
                        <button className={'btn btn--transparent btn--icon-red'} onClick={(e) => removeCompanyFromTemplate(e, company.id)}>
                            <Icon name='times' />
                        </button>
                    ],
                })
            }

            setCompaniesWithAccess(companiesWithAccessData)
            // return data.data
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het ophalen van bedrijven!`);
        });
    }

    async function removeCompanyFromTemplate(e,companyID){
        e.preventDefault();

        await authAxios.get(`/calculations/templates/${params.templateId}?populate=companies`).then(({data}) => {

            let companies = []
            for(const company of data.data.companies){

                if(company.id !== companyID){
                    companies.push(company);
                }
            }

            data.data.companies = companies

            fetchContext.authAxios.put(`/calculations/templates/${params.templateId}`, data).then(({data}) => {
                toast.success(`Toegang ingetrokken.`);
                getCompaniesWithAccess()
            }).catch((exception) => {
                console.error(exception);
                toast.error(`Er ging iets mis met het intrekken van toegang!`);
            });

            // return data.data
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het ophalen van bedrijven!`);
        });

    }

    async function validateCompanyUUID(company){
        // If UUID is null or undefined generate a UUID and update company
        if(company.UUID === null || company.UUID === undefined){
            company.UUID = crypto.randomUUID();

            return await authAxios.put(`/crm/companies/${company.id}`, {
                data:company
            }).then(({data}) => {
                // Add updated company data to company state
                toast.success('UUID toegevoegd.');
                return data.data
            }).catch((e) => {
                strapiToast(e)
                console.error(e);
            });
        }

        return company
    }

    async function loadCompanies(){

        fetchContext.authAxios.get(`/crm/companies/all`, {
            params: {}
        }).then(({data}) => {

            data = data.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));

            let options = []
            for(const company of data){

                let hasAccess = false
                for(const companyWithAccess of companiesWithAccess){
                    if(companyWithAccess.data[0] == company.name){
                        hasAccess = true;
                        break;
                    }
                }

                if(hasAccess || company.archived) continue

                options.push({
                    label: company.name,
                    value: company.id
                })
            }

            setCompanies(options)
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het ophalen van bedrijven!`);
        });
    }

    function removeSize(keyToRemove) {
        // Creating copies for removing keys
        let sizeDataCopy = [...sizeData];

        // Remove keys
        sizeDataCopy.splice(keyToRemove, 1);

        // Store margin before amount to make sure rendering does not break
        setSizeData(sizeDataCopy)
    }

    function removeAmount(keyToRemove) {
        // Creating copies for removing keys
        let amountDataCopy = [...amountData];
        let marginDataCopy = [...marginData];
        let thirdPartyDataCopy = [...thirdPartyData];
        let manualLabourDataCopy = [...manualLabourData];

        // Remove keys
        amountDataCopy.splice(keyToRemove, 1);
        marginDataCopy.splice(keyToRemove, 1);
        thirdPartyDataCopy.splice(keyToRemove, 1);
        manualLabourDataCopy.splice(keyToRemove, 1);

        // Store margin before amount to make sure rendering does not break
        setMarginData(marginDataCopy);
        setAmountData(amountDataCopy);
        setThirdPartyData(thirdPartyDataCopy);
        setManualLabourData(manualLabourDataCopy);
    }

    function addSize() {
        // Copy to add amount
        let sizeDataCopy = [...sizeData];

        // Add amount to array
        sizeDataCopy.push({
            "width": 210,
            "height": 297
        })

        setSizeData(sizeDataCopy)
    }

    // Adds amount and add corresponding data
    function addAmount() {
        // Add amount
        let amountDataCopy = [...amountData];
        amountDataCopy.push({
            amount: 100,
            misses: 50
        })
        setAmountData(amountDataCopy)

        const lastAmount = amountDataCopy[amountDataCopy.length - 1].amount

        // Add amount to margin data
        let marginDataCopy = [...marginData];
        marginDataCopy.push({
            amount: lastAmount,
            margin: 50
        })
        setMarginData(marginDataCopy);

        // Add thirdParty
        let thirdPartyDataCopy = [...thirdPartyData];
        thirdPartyDataCopy.push({
            price: 0,
            amount: lastAmount
        })
        setThirdPartyData(thirdPartyDataCopy);

        // add manualLabour
        let manualLabourDataCopy = [...manualLabourData];
        manualLabourDataCopy.push({
            price: 0,
            amount: lastAmount
        })
        setManualLabourData(manualLabourDataCopy);
    }

    /**
     * Opens the add machine popup
     */
    const addMachine = () => {
        fetchContext.authAxios.get(`/calculations/resources/templates/all?active=true`, {
            params: {}
        }).then(({data}) => {
            let machineOptions = [];
            for (const machine of data) {
                machineOptions.push(<option value={machine.id}>{machine.name}</option>)
            }

            const tabs = [...document.getElementsByClassName('tabcontent')];
            const calculationTabs = tabs?.filter(tab => /^\d+-\d+$/.test(tab?.id));

            const addedMachines = [
                <option value={false}>[Standaard] Laatste machine</option>
            ];
            for (const calculationTab of calculationTabs) {
                const tabName = calculationTab.querySelector('.machineTitle')?.value;
                const machineName = calculationTab.querySelector('.machine-constant[class$="const-name"]')?.value;

                addedMachines.push(<option value={calculationTab.id}>{(tabName || machineName || "Geen naam")}</option>);
            }

            setAddMachineContent(<span>
                <form onSubmit={chooseMachine}>
                    <label htmlFor={"machineSelect"}>Machine</label>
                    <select id={"machineSelect"} name={"machineSelect"}>{machineOptions}</select>
                    <label htmlFor={"machineSelect"}>Invoegen na</label>
                    <select id={"machinePositionSelect"} name={"machinePositionSelect"}>{addedMachines}</select>
                    <button>Machine toevoegen</button>
                </form>
            </span>)

        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het ophalen van widget-collections!`);
        });

        openPopup('addMachine', true);
    }

    /**
     * Adds chosen machine to interface
     * @param e
     */
    const chooseMachine = (e) => {
        e.preventDefault();
        // const machineId = e.target.machineSelect.value;
        addMachineToFlow(e.target.machineSelect.value, e.target.machinePositionSelect.value)
    }

    const addMachineToFlow = (id, insertAfter = null) => {

        let machineData = [];
        /**
         * Get data from machine
         */
        fetchContext.authAxios.get(`/calculations/resources/templates/${id}`, {
            params: {
                populate: '*'
            }
        }).then(({data: {data}}) => {
            machineData = data;

            const tabs = [...document.getElementsByClassName('tabcontent')];
            const calculationTabs = tabs?.filter(tab => /^\d+-\d+$/.test(tab?.id));
            const calculationTabIds = calculationTabs.map(tab => parseInt(tab?.id?.match(/(?<tabId>\d+)-(?<machineId>\d+)/)?.groups?.tabId));
            const lastId = Math.max(...calculationTabIds);

            const prefix = isNaN(lastId) || !isFinite(lastId) ? 1 : lastId + 1;

            /**
             * Get name
             */
            let name = null;
            for (const constant of machineData.widgetData.constants) {
                if (constant.id.includes("const-name")) {
                    name = constant.data
                }
            }

            if (name === null) {
                name = machineData?.name ?? `${prefix}-${id}`;
            }

            let lastHighestPos = 0;
            if(tabButtonData.length !== 0){
                lastHighestPos = Math.max(...tabButtonData.map(tab => tab.position));
            }

            const buttonData = {
                machineName: name,
                id: `tablink_${prefix}-${id}`,
                openTab: `${prefix}-${id}`,
                position: lastHighestPos + 1
            }

            if (insertAfter !== null && /\d+-\d+/.test(insertAfter)) {

                const tabBeforeTargetPosition = tabButtonData.find(tab => tab.openTab === insertAfter);

                buttonData.position = tabBeforeTargetPosition.position + 1;

                setTabButtonData(tabButtonData => {
                    tabButtonData.sort((a, b) => a.position - b.position);

                    const insertIndex = tabButtonData.findIndex(tab => tab.openTab === insertAfter);

                    for (const tabButton of tabButtonData) {
                        if (tabButton.position >= tabBeforeTargetPosition.position + 1) {
                            tabButton.position++;
                        }
                    }

                    tabButtonData.splice(insertIndex + 1, 0, buttonData);

                    return [...tabButtonData];
                });
            } else {
                setTabButtonData(tabButtonData => [...tabButtonData, buttonData]);
            }


            /**
             * Create options
             * @type {JSX.Element}
             */
            let tabOptions = <Grid className={'tab-settings'} columns={2} key={`machine-options-${prefix}-${id}`} customColTemplate={"50px 1fr"} style={{margin: "10px 0"}}>
                <div>
                    <label>Optioneel:</label>
                    <input className={"machineOptional"} type={"checkbox"}/>
                </div>
                <div>
                    <label>Tab benaming:</label>
                    <input placeholder={'Tab benaming'} className={"machineTitle"} type={"text"}/>
                </div>
            </Grid>

            const constants = formatConstants(machineData.widgetData.constants, prefix);

            /**
             * Add tabs
             */
            setTabs(prevState => [
                ...prevState,
                <div className={"tabcontent hidden"} machineid={id} id={`${prefix}-${id}`}>{tabOptions}
                    <hr/>
                    {constants}
                </div>
            ])

            /**
             * Add widgets
             */
            setTimeout(function () { //Start the timer
                new WidgetFormRenderer().renderForm(machineData.widgetData.widgets, debug, prefix, `${prefix}-${id}`, true) //After 1 second, set render to true
            }, 500)

            closePopup()
            setTimeout(function () { //Start the timer
                calculateForm();
                openTab(`${prefix}-${id}`);
            }, 500)

            toast.success(`Machine "${name}" is toegevoegd!`);
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het ophalen van widget-collection: ${id}!`);
        });
    }

    function formatConstants(constants, prefix = null) {
        let consts = [];

        for (const constant of constants) {
            consts.push(<input
                key={prefix !== null ? `${prefix}-${constant.id}` : constant.id}
                type={"hidden"}
                value={constant.data !== undefined ? constant.data : constant.value}
                className={prefix !== null ? `machine-constant ${prefix}-${constant.id}` : `machine-constant ${constant.id}`}
                id={prefix !== null ? `${prefix}-${constant.id}` : constant.id}
            />)
        }

        return consts;
    }

    /**
     * Opens the chosen tab
     * @param element
     */
    const openTab = (id) => {
        /**
         *
         * Reset tabs
         */
        for (const tablink of document.getElementsByClassName('tablinks')) {
            tablink.classList.remove("currentTab")
        }

        document.getElementById(`tablink_${id}`).classList.add('currentTab');
        document.getElementById(`tablink_${id}`).classList.add('visitedTab');

        /**
         * Reset tabs
         */
        for (const tab of document.getElementsByClassName('tabcontent')) {
            tab.classList.add("hidden")
        }

        /**
         * Display chosen tab
         */
        document.getElementById(id).classList.remove('hidden');
    }


    // SLeep helper function
    const sleep = m => new Promise(r => setTimeout(r, m))

    /**
     * Run calculation logic
     */
    async function calculateForm() {
        setIsLoading(true);

        const loading = toast.loading("Widgets toevoegen...");

        let result = {};
        if (amounts !== "") {
            /**
             * Loop amounts
             */
            for (const amount of amounts.split(",")) {

                /**
                 * Loop tabs and set value
                 */
                let tabCount = 1;
                let tabs = document.getElementsByClassName('tabcontent');
                for (const tab of tabs) {
                    await sleep(50);
                    toast.update(loading, {
                        render: `Machine: ${tabCount}/${tabs.length}`,
                        type: "info",
                        isLoading: true
                    });

                    for (const widget of tab.querySelectorAll('.calculationWidget')) {
                        const widgetData = JSON.parse(widget.getAttribute('widgetdata'))
                        /**
                         * If input
                         */
                        if (widgetData.data.type === "Input") {
                            /**
                             * If amount set current amount
                             */
                            const widget = document.getElementById(`${widgetData.prefix}${widgetData.id}`)
                            if (widgetData.data.title.toLowerCase() === "aantal" && widget) {
                                widget.querySelector('input').value = amount
                            }
                        }
                    }
                    /**
                     * Run calculation with given amount
                     */
                    for (const widget of tab.querySelectorAll('.calculationWidget')) {
                        await sleep(1);
                        const widgetData = JSON.parse(widget.getAttribute('widgetdata'))

                        const res = new WidgetCollection(null, null, null, widgetData, debug, undefined, true, false).runCalculation(widgetData.data.type);
                        if (res !== undefined) {
                            if (result[res.titel] === undefined) {
                                result[res.titel] = {};
                            }

                            if (result[res.titel][amount] === undefined) {
                                result[res.titel][amount] = res.result
                            } else {
                                result[res.titel][amount] += res.result
                            }
                        }
                    }
                    tabCount++;
                }
            }

            setTimeout(() => {
                if (debug) {
                    const inputAndSelectWidgets = document.querySelectorAll('.calculationWidget:has(select.valueSetterSelect)');

                    if (inputAndSelectWidgets.length > 0) {
                        const widgetValues = getWidgetValues();
                        let options = "";
                        let oldTab = '';

                        options += `<option value="" selected>Kies waarde</option>`;
                        options += `<option value="0">Leeg (0)</option>`;
                        options += `<option value="1">Leeg (1)</option>`;

                        for (const widgetValue of widgetValues) {
                            for (const value of widgetValue.options) {
                                // Continue if blank label
                                if (value.label === 'label') continue;

                                const tabNumber = value.label.match(/\[(\d+-\d+).*]/)?.[1];
                                const tabHandleId = tabNumber !== undefined ? `tablink_${tabNumber}` : undefined;
                                const calculationTabHandleId = tabNumber !== undefined ? `calculationTablink_${tabNumber}` : undefined;
                                const tabHandleName = tabHandleId !== undefined ? document.getElementById(tabHandleId)?.innerText : undefined;
                                const calculationTabHandleName = calculationTabHandleId !== undefined ? document.getElementById(calculationTabHandleId)?.innerText : undefined;
                                const tabCustomName = tabNumber !== undefined ? document.getElementById(tabNumber)?.querySelector('input.machineTitle')?.value : undefined;

                                if (oldTab === '' && tabHandleId !== undefined) {
                                    options += `<optgroup label="${(tabCustomName ?? tabHandleName ?? calculationTabHandleName)}">`;
                                    oldTab = tabHandleId;
                                }

                                if (tabHandleId !== oldTab && tabHandleId !== undefined) {
                                    options += `</optgroup><optgroup label="${(tabCustomName ?? tabHandleName ?? calculationTabHandleName)}">`;
                                    oldTab = tabHandleId;
                                }

                                options += `<option value="${value.value}"{{selected-${value.value}}}>${(tabCustomName ?? tabHandleName ?? calculationTabHandleName)} ${value.label}</option>`
                            }
                        }

                        for (const inputAndSelectWidget of inputAndSelectWidgets) {

                            // Get the select box in the widget
                            const valueSetterSelect = inputAndSelectWidget.querySelector('select.valueSetterSelect');

                            // Get the value of the widget
                            const widgetData = JSON.parse(inputAndSelectWidget.getAttribute('widgetdata'));

                            let widgetOptions = options;

                            if (widgetData.data.selectedValue !== undefined) {
                                widgetOptions = widgetOptions.replace(`{{selected-${widgetData.data.selectedValue}}}`, " selected");
                            }

                            // Add the options to the select box
                            valueSetterSelect.innerHTML = widgetOptions.replaceAll(/{{selected-\d+-\d+-widget-\d+(\.\w+)?}}/gi, '');

                            if (widgetData.data.selectedValue !== undefined) {
                                valueSetterSelect.value = widgetData.data.selectedValue;
                            }
                        }
                    }
                }
            }, 0);
        }

        /**
         * Add manual labor
         */
        for (const priceResult of document.querySelectorAll('.handwerk-price-result')) {
            if (priceResult.dataset?.quantity === undefined || priceResult.dataset?.price === undefined || priceResult.dataset?.time === undefined) continue;
            if (result['Handwerk kosten'] === undefined) result['Handwerk kosten'] = [];
            if (result['Handwerk tijd'] === undefined) result['Handwerk tijd'] = [];

            if (result['Handwerk kosten'][parseInt(priceResult.dataset.quantity)] === undefined) result['Handwerk kosten'][parseInt(priceResult.dataset.quantity)] = 0;
            if (result['Handwerk tijd'][parseInt(priceResult.dataset.quantity)] === undefined) result['Handwerk tijd'][parseInt(priceResult.dataset.quantity)] = 0;

            result['Handwerk kosten'][parseInt(priceResult.dataset.quantity)] += parseFloat(priceResult.dataset?.price);
            result['Handwerk tijd'][parseInt(priceResult.dataset.quantity)] += parseFloat(priceResult.dataset?.time);
        }

        /**
         * Add third party work
         */
        for (const priceResult of document.querySelectorAll('.werk-derden-price-result')) {

            if (priceResult.dataset?.quantity === undefined || priceResult.dataset?.price === undefined || priceResult.dataset?.total === undefined) continue;
            if (result['Werk derden'] === undefined) result['Werk derden'] = [];

            if (result['Werk derden'][parseInt(priceResult.dataset.quantity)] === undefined) result['Werk derden'][parseInt(priceResult.dataset.quantity)] = 0;

            result['Werk derden'][parseInt(priceResult.dataset.quantity)] += parseFloat(priceResult.dataset?.total);
        }

        setCalculationResult(result)

        gatherAvailableWidgets();

        toast.update(loading, {
            render: `Calculatie geladen.`,
            type: "success",
            isLoading: false,
            autoClose: 1500,
            closeOnClick: true
        });

        setIsLoading(false);
    }

    // Gather available widgets
    function gatherAvailableWidgets() {

        const usableWidgets = {};
        const allWidgets = [];
        // Loop tabs to get widgets
        for (const tab of document.getElementsByClassName('tabcontent')) {

            // Loop widgets to get data
            for (const widget of tab.querySelectorAll('.calculationWidget')) {

                // Collect widgetData
                const widgetData = JSON.parse(widget.getAttribute('widgetdata'))

                if (document.getElementById(`tablink_${widgetData.parentId}`) !== null) {
                    const machineName = document.getElementById(`tablink_${widgetData.parentId}`).querySelector('span').innerHTML;
                    widgetData.machineName = machineName;
                }

                // Add to all widgets collection
                allWidgets[widgetData.id] = widgetData;

                // Only get input types
                if (widgetData.data.type === "Input" || widgetData.data.type === "SelectWidget" || widgetData.data.type === "MaterialChoice") {

                    // Get widgetElement to check if widget is available
                    const widgetElement = document.getElementById(`${widgetData.prefix}${widgetData.id}`);

                    // Check visibility checkbox and if true add widget to available array
                    if (widgetElement.querySelector('input.visibleInputCheckbox') !== null) {
                        if (!widgetElement.querySelector('input.visibleInputCheckbox').checked) {
                            usableWidgets[widgetData.id] = widgetData
                        }
                    }
                }
            }
        }

        // Add available widgets to state
        setAvailableWidgets(usableWidgets);
        setAllWidgets(allWidgets);
    }

    async function storeWidgetTemplate() {

        await calculateForm();

        try {
            const json = gatherData();

            if (type === 'update') {
                fetchContext.authAxios.put(`/calculations/templates/${params.templateId}`, json).then(({data}) => {
                    toast.success(`Calculatie template opgeslagen!`);
                }).catch((exception) => {
                    console.error(exception);
                    toast.error(`Er ging iets mis met het opslaan van widget-templates!`);
                });
            } else {
                fetchContext.authAxios.post(`/calculations/templates`, json).then(({data}) => {
                    toast.success(`Calculatie template opgeslagen!`);
                    navigate(`../${data.data.id}/edit`);
                }).catch((exception) => {
                    console.error(exception);
                    toast.error(`Er ging iets mis met het opslaan van widget-templates!`);
                });
            }
        } catch (e) {
            toast.error(e.message);
            if (e.cause) {
                e.cause.forEach(cause => toast.error(cause));
            }
        }
    }

    const gatherData = () => {
        let json = {};
        json.data = {};
        json.data.data = {};
        json.data.name = name;
        json.data.data.result = calculationResult;
        json.data.data.amounts = amounts;
        json.data.data.limitToOneAmount = limitToOneAmount;
        json.data.isWebshopTemplate = webshopTemplate;

        const errors = [];
        const tabNames = [];

        let position = Math.max(...tabButtonData.map(tab => tab.position)) + 1;
        for (const tab of document.getElementsByClassName('tabcontent')) {

            const tabButton = tabButtonData.find(tabButton => tabButton.openTab === tab.id);

            if (['order', 'quote', 'order-confirmation'].includes(tab.id)) continue;

            if (json.data.data[tab.id] === undefined) {
                json.data.data[tab.id] = {
                    position: tabButton?.position ?? position,
                };

                if (!tabButton?.position) {
                    position++;
                }
            }

            /**
             * Check if widgets and constants are added if not add
             */
            if (json.data.data[tab.id].widgets === undefined || json.data.data[tab.id].widgets === undefined) {
                json.data.data[tab.id].widgets = [];
                json.data.data[tab.id].constants = [];
            }

            /**
             * Check if machine optional
             * machineOptional
             * machineTitle
             */
            if (tab.querySelector('.machineOptional') !== null) {
                json.data.data[tab.id].optional = tab.querySelector('.machineOptional').checked;
            }

            /**
             * Gather all widget data
             */
            for (const widget of tab.querySelectorAll('.calculationWidget')) {
                const widgetData = JSON.parse(widget.getAttribute('widgetdata'))

                // add visibiliy to widgetData
                if (widgetData.data.type === "Input" || widgetData.data.type === "SelectWidget" || widgetData.data.type === "MaterialChoice") {
                    const widgetElement = document.getElementById(`${widgetData.prefix}${widgetData.id}`);
                    widgetData.data.hidden = widgetElement.querySelector('input.visibleInputCheckbox').checked;
                }

                // Add widget data
                json.data.data[tab.id].widgets.push(widgetData)
            }

            /**
             * Gather constants
             */
            for (const constant of tab.querySelectorAll('.machine-constant')) {
                json.data.data[tab.id].constants.push({id: constant.id, value: constant.value})
            }

            /**
             * Check if custom machine title is set
             */
            if (tab.querySelector('.machineTitle') !== null) {
                const name = tab.querySelector('.machineTitle').value;
                const machineName = json.data.data[tab.id].constants.find(constant => constant.id.endsWith("const-name"))?.value;

                if (tabNames.includes(name)) {
                    errors.push(`Tab ${machineName} heeft een benaming die al bestaat (${name})!`);
                }

                tabNames.push(name);
                json.data.data[tab.id].name = name;

                if (!(name.trim())) {
                    errors.push(`Tab ${machineName} heeft geen naam!`);
                }
            }
        }

        if (webshopTemplate) {
            json = addWebshopData(json);

            if (json.data.data.templateSettingsData.amounts.length === 0) {
                errors.push('Voeg minimaal 1 aantal toe!');
            }
        }

        if (errors.length > 0) {
            throw new Error(`Er ${errors.length === 1 ? 'is' : 'zijn'} ${errors.length} ${errors.length === 1 ? 'fout' : 'fouten'} gevonden`, {
                cause: errors
            });
        }

        return json;
    }

    function addWebshopData(json) {

        json.data.data.templateSettings = {
            margin: hasFixedMargin,
            marginType: !hasFixedMargin ? 'percentage' : 'price',
            size: hasFixedSize,
            thirdParty: hasFixedThirdParty,
            manualLabor: hasFixedManualLabour,
        }

        json.data.data.templateSettingsData = {
            sizes: sizeData,
            amounts: amountData,
            margin: marginData,
            manualLabour: manualLabourData,
            thirdParty: thirdPartyData,
        }

        // Add layout
        json.data.data.widgetLayout = layout;
        json.data.data.checklist = {
            values: checklistData,
            language: selectedLanguage,
            template: chosenChecklistTemplate
        }

        return json;
    }

    const updateTemplateConstants = () => {
        for (const tab of document.getElementsByClassName('tabcontent')) {
            if (tab.querySelector(`[id*="const-id"]`) === null) {
                continue;
            }
            const machineId = tab.querySelector(`[id*="const-id"]`).value;
            const name = tab.querySelector(`[id*="const-name"]`).value;
            if (machineId != null) {
                fetchContext.authAxios.get(`/calculations/resources/machines/${machineId}`, {
                    params: {
                        populate: '*'
                    }
                }).then(({data}) => {
                    let updates = 0;
                    for (const [key, value] of Object.entries(data)) {
                        for (const input of document.getElementsByClassName(`${tab.id.split('-')[0]}-const-${key}`)) {
                            if (value === null && input.value === '' || typeof value === 'object') {
                                continue;
                            }

                            if (String(input.value) !== String(value)) {
                                updates++;
                                toast.warning(`${machineId}-const-${key} geupdate van: ${input.value} naar ${value}`);

                                input.value = value;
                            }
                        }
                    }

                    if (updates === 0) {
                        toast.success(`Alle constants van de ${name} zijn up to date!`, {autoClose: 500});
                    }
                }).catch((exception) => {
                    console.error(exception)
                    toast.error(`Er ging iets mis met het ophalen van de date van de: ${name}!`);
                });
            }
        }
    }

    function moveTabLeft(e) {
        e.preventDefault();
        e.stopPropagation();

        moveTab(e.currentTarget.parentNode, 'left');
    }

    function moveTabRight(e) {
        e.preventDefault();
        e.stopPropagation();

        moveTab(e.currentTarget.parentNode, 'right');
    }

    function moveTab(button, direction) {
        const tab = document.getElementById(button.dataset.targetTab);
        const currentIndex = tabButtonData.findIndex(tabButton => tabButton.openTab === tab.id);
        const buttonData = tabButtonData[currentIndex];

        if (tab === null) {
            return;
        }

        if (direction === 'left' && buttonData.position === 0) {
            return;
        }

        if (direction === 'right' && buttonData.position === tabButtonData.length - 1) {
            return;
        }

        const oldPos = buttonData.position;
        const newPos = direction === 'left' ? oldPos - 1 : oldPos + 1;

        const targetButtonIndex = tabButtonData.findIndex(tabButton => tabButton.position === newPos);
        const targetButtonData = tabButtonData[targetButtonIndex];

        const newTabButtonData = [...tabButtonData];

        newTabButtonData[currentIndex].position = newPos;
        newTabButtonData[targetButtonIndex].position = oldPos;

        setTabButtonData(newTabButtonData);

        const currentTab = document.getElementById(buttonData.openTab);
        const targetTab = document.getElementById(targetButtonData.openTab);

        if (direction === 'left') {
            targetTab.before(currentTab);
        } else if (direction === 'right') {
            targetTab.after(currentTab);
        }
    }

    function removeTab(e) {
        e.preventDefault();
        e.stopPropagation();

        const parentButton = e.currentTarget.parentNode;
        const machineName = parentButton.attributes['machine-name']?.value;
        const prevTab = parentButton.previousSibling;
        const nextTab = parentButton.nextSibling;

        if (!window.confirm(`Weet je zeker dat je de machine "${machineName}" wilt verwijderen?`)) {
            return;
        }

        const tabElement = document.getElementById(parentButton.dataset.targetTab);

        if (tabElement === null) {
            toast.error(`De tab "${parentButton.dataset.targetTab}" voor machine "${machineName}" kon niet gevonden en verwijderd worden!`);
            return;
        }

        tabElement.remove();

        const newTabButtonData = [...tabButtonData.filter(button => button.openTab !== tabElement.id)];
        setTabButtonData(newTabButtonData.map((button, index) => {
            button.position = index;
            return button
        }));

        if (prevTab !== null && typeof prevTab?.dataset?.targetTab === "string") {
            openTab(prevTab?.dataset?.targetTab)
        } else if (nextTab !== null && typeof nextTab?.dataset?.targetTab === "string") {
            openTab(nextTab?.dataset?.targetTab)
        }
    }

    return (
        <>
            <Block
                name='default__settings'
                className="chooseMachines"
                title={"Basis instellingen"}
                useSmallerPadding
                headerRightSideChildren={!isLoading && (
                    <Grid columns={4} style={{alignItems: 'center'}} customColTemplate={'115px 210px 150px 100px'}>
                        <Boolean
                            value={webshopTemplate}
                            displayFalse={"Emily"}
                            displayTrue={"API"}
                            style={{backgroundColor: '#E2E2E2'}}
                            field={{name: 'debug'}}
                            setValue={(name, value) => {
                                setWebshopTemplate(value)
                            }}
                        />
                        <Boolean
                            value={debug}
                            displayFalse={"Debug uit"}
                            displayTrue={"Debug aan"}
                            style={{backgroundColor: '#E2E2E2'}}
                            field={{name: 'debug'}}
                            setValue={(name, value) => {
                                setDebugMode(value)
                            }}
                        />
                        <button onClick={updateTemplateConstants}>Update constants</button>
                        <button className={'btn--black btn--icon-right'} onClick={storeWidgetTemplate}>Opslaan <Icon name={'save'}/></button>
                    </Grid>
                )}
            >
                <label>
                    Template benaming:
                    <input
                        defaultValue={name}
                        onChange={e => setName(e.target.value)}
                        className={"globalValue"}
                        id={"global-name"}
                    />
                </label>

                {!webshopTemplate ?
                <Grid columns={2} customColTemplate={'360px 1fr'} gap={'10px'} style={{ alignItems: "end" }}>
                    <div>
                        Aantal beperking:
                        <Boolean
                            value={limitToOneAmount}
                            displayTrue={"Maximaal 1 aantal"}
                            displayFalse={"Meerdere aantallen"}
                            style={{ backgroundColor: '#E2E2E2', marginBottom: '5px', marginTop: '5px', height: '38px' }}
                            valueStyle={{ margin: '3px', padding: '3px 10px', height: '32px', lineHeight: '24px' }}
                            field={{name: 'debug'}}
                            setValue={(name, value) => {
                                setLimitToOneAmount(value)
                            }}
                        />
                    </div>
                    <div>
                        {limitToOneAmount ? "Aantal" : "Aantallen"}:
                        <input
                            defaultValue={amounts}
                            onChange={e => setAmounts(e.target.value)}
                            type={"textarea"}
                            className={"globalValue"}
                            id={"global-amounts"}
                        />
                    </div>
                </Grid>
                    :
                <Grid style={{alignItems: 'center'}} columns={4} customColTemplate={'250px 200px 180px 180px'} gap={'10px'}>
                    <Boolean
                        value={hasFixedMargin}
                        displayFalse="Percentage"
                        displayTrue="Vaste prijs"
                        style={{backgroundColor: '#E2E2E2'}}
                        setValue={(name, value) => {
                            setHasFixedMargin(value)
                        }}
                        field={{
                            name: 'marginType'
                        }}
                    />
                    <button style={{ height: '100%' }} onClick={() => openPopup('accessPopup')}>
                        <Icon name={'lock'} />
                        Toegang aanpassen
                    </button>
                </Grid>}
            </Block>

            {webshopTemplate ?
                <>
                    <br/>
                    <br/>
                    <Grid columns={2} customColTemplate={'1fr 3fr'}>
                        <Table
                            structure={['50%', '50%', '64px']}
                            headers={['Breedte', 'Hoogte', <>
                                <button className="btn btn--black" onClick={() => addSize()}>
                                    <Icon name="plus"/>
                                </button>
                            </>]}
                            data={sizeData.map(function (amount, key) {

                                return [
                                    <input
                                        value={amount.width}
                                        type='number'
                                        onChange={(e) => {
                                            let copySizeData = [...sizeData];

                                            copySizeData[key].width = e.target.value;

                                            setSizeData(copySizeData);
                                        }}
                                    />,
                                    <input
                                        value={amount.height}
                                        type='number'
                                        onChange={(e) => {
                                            let copySizeData = [...sizeData];

                                            copySizeData[key].height = Number(e.target.value);

                                            setSizeData(copySizeData);
                                        }}
                                    />,
                                    <button onClick={() => removeSize(key)}><Icon name={'times'}/></button>
                                ];
                            })}
                            hover
                            border='row'
                            className='table--padding-s'
                        />

                        <Table
                            structure={['20%', '20%', '20%', '20%', '20%', '70px']}
                            headers={['Aantal', 'Inschiet', `Marge (${hasFixedMargin ? 'Vaste prijs' : 'Percentage'})`, 'Handwerk', 'Werkderden', <>
                                <button className="btn btn--black" onClick={() => addAmount()}>
                                    <Icon name="plus"/>
                                </button>
                            </>]}

                            scroll
                            data={amountData.map(function (amount, key) {
                                return [
                                    <input // Aantal
                                        value={amount.amount}
                                        type='number'
                                        onChange={(e) => {
                                            let copyAmountData = [...amountData];
                                            let copyMarginData = [...marginData];
                                            let copyManualLabourData = [...manualLabourData];
                                            let copyThirdPartyData = [...thirdPartyData];

                                            const value = Number(e.target.value);
                                            e.target.value = String(value);

                                            copyAmountData[key].amount = value;
                                            copyMarginData[key].amount = value;
                                            copyManualLabourData[key].amount = value;
                                            copyThirdPartyData[key].amount = value;

                                            setAmountData(copyAmountData);
                                            setMarginData(copyMarginData);
                                            setManualLabourData(copyManualLabourData);
                                            setThirdPartyData(copyThirdPartyData);
                                        }}
                                    />,
                                    <input // Inschiet
                                        value={amount.misses}
                                        type='number'
                                        onChange={(e) => {
                                            let copyAmountData = [...amountData];

                                            const value = Number(e.target.value);
                                            e.target.value = String(value);

                                            copyAmountData[key].misses = value;

                                            setAmountData(copyAmountData);
                                        }}
                                    />,
                                    <div className={'input-group input-group--text margin-0'}>
                                        {hasFixedMargin &&
                                            <span className={'input-group-text'}>€</span>
                                        }
                                        <input // Marge
                                            value={marginData[key].margin}
                                            type='number'
                                            onChange={(e) => {
                                                let marginDataCopy = [...marginData];

                                                const value = Number(e.target.value);
                                                e.target.value = String(value);

                                                marginDataCopy[key].margin = value;

                                                setMarginData(marginDataCopy);
                                            }}
                                        />
                                        {!hasFixedMargin &&
                                            <span className={'input-group-text'}>%</span>
                                        }
                                    </div>,
                                    <div className={'input-group input-group--text margin-0'}>
                                        <span className={'input-group-text'}>
                                            €
                                        </span>
                                        <input // Handwerk
                                            value={manualLabourData[key].price}
                                            type='number'
                                            onChange={(e) => {
                                                let manualLabourDataCopy = [...manualLabourData];

                                                const value = Number(e.target.value);
                                                e.target.value = String(value);

                                                manualLabourDataCopy[key].price = value;

                                                setManualLabourData(manualLabourDataCopy);
                                            }}
                                        />
                                    </div>,
                                    <div className={'input-group input-group--text margin-0'}>
                                        <span className={'input-group-text'}>
                                            €
                                        </span>
                                        <input // Werkderden
                                            value={thirdPartyData[key].price}
                                            type='number'
                                            onChange={(e) => {
                                                let thirdPartyDataCopy = [...thirdPartyData];

                                                const value = Number(e.target.value);
                                                e.target.value = String(value);

                                                thirdPartyDataCopy[key].price = value;

                                                setThirdPartyData(thirdPartyDataCopy);
                                            }}
                                        />
                                    </div>,
                                    <button onClick={() => removeAmount(key)}><Icon name={'times'}/></button>
                                ];
                            })}
                            hover
                            border='row'
                            className='table--padding-s'
                        />
                    </Grid>
                </>
                : <></>}

            <br/>

            <Block name="main" className="main" title={'Machines'} useSmallerPadding headerRightSideChildren={<>
                <button className={'btn--icon-right btn--black'} onClick={addMachine}>Machine toevoegen <Icon name={'plus'}/></button>
                <button className={'btn--icon-right'} onClick={
                    () => {
                        if (tabsEditing) {
                            toast.info("Volgorde toegepast. Klik op opslaan om definitief toe te passen.")
                        }
                        setTabsEditing(prev => !prev);
                    }
                }>{tabsEditing ? 'Volgorde toepassen' : 'Bewerk volgorde'} <Icon name={'flow'}/></button>
                <button className={'btn--icon-right'} onClick={calculateForm}>Bereken <Icon name={'calculator'}/></button>
            </>}>
                <div className={`calculationTabButtons ${tabsEditing ? 'editing' : ''}`}>
                    <div>
                        {tabButtonData.sort((a, b) => a.position - b.position).map((button, index) =>
                            <button key={button.id} machine-name={button.machineName} className="tablinks calculationTablinks" id={button.id} data-target-tab={button.openTab} onClick={() => openTab(button.openTab)}>
                                <button style={{display: (tabsEditing ? "block" : 'none')}} onClick={moveTabLeft}><Icon name={'chevron-left'}/></button>
                                <span>{button.machineName}</span>
                                <button style={{display: (tabsEditing ? "block" : 'none')}} onClick={removeTab}><Icon name={'trash-can-duo-tone'}/></button>
                                <button style={{display: (tabsEditing ? "block" : 'none')}} onClick={moveTabRight}><Icon name={'chevron-right'}/></button>
                            </button>
                        )}
                    </div>
                </div>

                <SpinnerOverlay visible={isLoading && !debug}>
                    {tabs}
                </SpinnerOverlay>
            </Block>


            <Popup popupID='addMachine' title={"Machine toevoegen"}>
                {addMachineContent}
            </Popup>

            <Popup popupID='accessPopup' title={"Toegang"}>
                <Grid style={{alignItems: 'center', marginBottom: '10px' }}  columns={2} customColTemplate={'1fr 150px'} gap={'10px'}>
                    <Select
                        value={chosenCompany}
                        onChange={(e) => setChosenCompany(e)}
                        options={companies}
                        styles={{
                            container: (provided) => ({
                                ...provided,
                                width: '100%',
                                marginBottom: '0 !important',
                                marginTop: '0 !important'
                            })
                        }}
                    />
                    <button style={{ height: '100%' }} onClick={() => giveCompanyAccess()}>
                        <Icon name={'plus'} /> Toevoegen
                    </button>
                </Grid>

                <Table
                    padding={'s'}
                    structure={['100%', '64px']}
                    headers={['Bedrijven met toegang', '']}
                    data={companiesWithAccess} />
            </Popup>

            <br/>

            {webshopTemplate ? <>
                <ChecklistEditor
                    widgets={allWidgets}
                    checklistData={checklistData}
                    setChecklistData={setChecklistData}
                    chosenChecklist={chosenChecklist}
                    setChosenChecklist={setChosenChecklist}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    chosenChecklistTemplate={chosenChecklistTemplate}
                    setChosenChecklistTemplate={setChosenChecklistTemplate}
                />
                <br/>
                <SpinnerOverlay visible={isLoading && !debug}>
                    <LayoutEditor availableWidgets={availableWidgets} layout={layout} setLayout={setLayout}/>
                </SpinnerOverlay>
            </> : <></>}
        </>
    )
}

import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import Inputs, {getFormData} from "UI/App/Components/Form/Inputs";
import {getCountriesList} from "App/Util/countries";
import Icon from "UI/App/Components/Icon/Icon";
import Container from "UI/App/Components/Container/Container";
import useWindowDimensions from "App/Util/useWindowDimensions";
import Popup, {closePopup} from 'UI/App/Components/Popup/Popup';
import {stringify} from 'qs';
import Grid from "../../Grid/Grid";

export default function AddCompanyBranchPopup({ companyId, setBranches, setChosenBranch, isSupplier = false }) {
    const { authAxios } = useContext(FetchContext);
    const { width } = useWindowDimensions();

    const [inputs, setInputs] = useState([]);
    const [countryNames, setCountryNames] = useState([]);
    const [countryLandCodes, setCountryLandCodes] = useState([]);
    const [company, setCompany] = useState({});

    useEffect(() => {
        getCountriesList().then((result) => {
            setCountryNames(result.countries);
            setCountryLandCodes(result.countryPhoneCodes);
        });
        getCompany();
    }, []);

    useEffect(() => {
        if (countryNames.length !== 0 && countryLandCodes.length !== 0 && company?.address) {
            setInputData();
        }
    }, [countryNames, countryLandCodes, countryLandCodes, company, width])

    function getCompany() {

        authAxios.get(`/crm/${isSupplier ? 'suppliers' : 'companies'}/` + companyId, {
            params: {
                populate: "address,telephone"
            }
        }).then(({ data: { data } }) => {
            setCompany(data);
        }).catch((exception) => {
            toast.error("Er is iets fout gegaan bij het ophalen van het bedrijf!");
            console.error(exception);
        });
    }

    function setInputData() {
        const inputs = [
            {
                type: 'hidden',
                name: !isSupplier ? 'company' : 'supplier',
                value: companyId,
                required: true,
            },
            {
                type: 'text',
                name: 'name',
                label: 'Naam vestiging',
                placeholder: 'Hoofdkantoor...',
                required: true
            },
            {
                type: 'text',
                name: 'locationManager',
                label: 'Aanspreekpunt op locatie',
                placeholder: 'John Doe...',
                required: false,
            },
            {
                type: 'multiple',
                name: 'address',
                label: 'Adres',
                required: true,
                subFields: [
                    {
                        type: 'text',
                        name: 'address[addressLine1]',
                        placeholder: 'Adres regel 1...',
                        required: true,
                        defaultValue: company?.address?.addressLine1 ?? "",
                        style: {
                            width: '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[addressLine2]',
                        placeholder: 'Adres regel 2...',
                        defaultValue: company?.address?.addressLine2 ?? "",
                        style: {
                            width: '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[addressLine3]',
                        placeholder: 'Adres regel 3...',
                        defaultValue: company?.address?.addressLine3 ?? "",
                        style: {
                            width: '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[postalCode]',
                        placeholder: 'Postcode...',
                        defaultValue: company?.address?.postalCode ?? "",
                        required: true,
                        style: {
                            width: width > 500 ? '30%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[city]',
                        placeholder: 'Stad...',
                        required: true,
                        defaultValue: company?.address?.city ?? "",
                        style: {
                            width: width > 500 ? '70%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[province]',
                        placeholder: 'Provincie...',
                        defaultValue: company?.address?.province ?? "",
                        style: {
                            width: width > 500 ? '30%' : '100%'
                        },
                    },
                    {
                        type: 'select',
                        name: 'address[country]',
                        placeholder: 'Land...',
                        value: company?.address?.country ?? "",
                        options: countryNames,
                        required: true,
                        style: {
                            width: width > 500 ? '70%' : '100%'
                        },
                    },
                ]
            },
            {
                type: 'multiple',
                name: 'telephone',
                label: 'Telefoonnummer',
                required: false,
                subFields: [
                    {
                        type: 'select',
                        name: 'telephone[dialingCode]',
                        placeholder: 'Landcode...',
                        defaultValue: company?.telephone?.dialingCode ?? 31,
                        options: countryLandCodes,
                        required: false,
                        style: {
                            width: width > 600 ? '20%' : '100%'
                        }
                    },
                    {
                        type: 'tel',
                        name: 'telephone[telephoneNumber]',
                        placeholder: 'Telefoonnummer...',
                        defaultValue: company?.telephone?.telephoneNumber ?? 0,
                        required: false,
                        style: {
                            width: width > 600 ? '80%' : '100%'
                        }
                    }
                ]
            },
            {
                type: 'text',
                name: 'email',
                label: 'Email',
                placeholder: 'Email...',
                required: false,
                defaultValue: company?.generalEmail ?? "",
            }
        ];

        setInputs(inputs);
    }

    async function handleSubmit(e) {
        e.preventDefault();

        const data = await getFormData(e, authAxios);

        const query = stringify({
            populate: {
                address: true,
                telephone: true,
                managingCompany: true,

            }
        })
        authAxios.post(`/crm/companies/branches?${query}`, {
            data
        }).then((data) => {
            toast.success('Vestiging opgeslagen');
            const branch = data.data.data;
            let formatedBranch = { id: branch.id, value: branch.id, label: branch.name, name: branch.name, address: branch.address, locationManager: branch.locationManager }
            setBranches((prev) => ([
                ...prev,
                formatedBranch
            ]))

            setChosenBranch(formatedBranch)
            closePopup();
        }).catch((e) => {
            toast.error("Er is iets fout gegaan bij het aanmaken van de vestiging!")
            console.error(e);
        });
    }

    return (

        <Popup title="Branch toevoegen" popupID="createBranchPopup">
            <Container>
                <form onSubmit={handleSubmit}>
                    <Inputs inputs={inputs} key={crypto.randomUUID()} />
                    <Grid>
                        <button value="normalSubmit" className={'btn'}>Opslaan en gebruiken <Icon name={'save'} /></button>
                    </Grid>
                </form>
            </Container>
        </Popup>

    );
}

import Container from 'UI/App/Components/Container/Container';
import Grid from 'UI/App/Components/Grid/Grid';
import Memos from 'UI/App/Partials/Content/Dashboard/Blocks/Memos/Memos';
import Notes from 'UI/App/Partials/Content/Dashboard/Blocks/Notes/Notes';
import './dashboard.scss';
import PowerBI from "UI/App/Partials/Content/Dashboard/Blocks/PowerBI/PowerBI";
import IF from 'UI/App/Components/Conditional/IF';
import Timer from "UI/App/Partials/Content/Dashboard/Blocks/Timer/Timer";
import HasRole from "UI/App/Components/Auth/HasRole";
import OrderTimer from "UI/App/Partials/Content/Dashboard/Blocks/OrderTimer/OrderTimer";
import Block from "UI/App/Components/Block/Block";

export default function Dashboard() {
    return (
        <Container>
            <Grid
                columns={{
                    1000: 1,
                    default: 2
                }}
                customColTemplate={{
                    1000: '1fr',
                    default: '400px 1fr'
                }}
            >
                <Timer />
                <Grid
                    columns={{
                        1500: 1,
                        default: 2
                    }}
                >
                    <Grid columns={1}>
                        <Notes />
                        <Block title='Start order timer' name='start-timer'>
                            <OrderTimer />
                        </Block>
                    </Grid>
                    <Memos />
                </Grid>
            </Grid>

            <HasRole roles={['admin', 'management']} >
                <IF condition={localStorage.getItem("isTerminalUser") === "false"}>
                    <PowerBI />
                </IF>
            </HasRole>

        </Container>
    );
}

import { useNavigate } from 'react-router-dom';
import { FetchContext } from 'App/Strapi/FetchContext';
import { AuthContext } from 'App/Strapi/AuthProvider';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import './NotFound.scss';

export default function NotFound() {
    const authData = useContext(AuthContext);
    const { authAxios } = useContext(FetchContext);
    const navigate = useNavigate();
    const [gear, setGear] = useState({ enabeld: false, element: <></> });
    const locationParams = useLocation();

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <h1>
                <b className='highlight'>404:</b> Not Found{' '}
                <svg
                    onClick={gearPressed}
                    viewBox='0 0 32 32'
                    width={'25px'}
                    height={'25px'}
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path d='m14 2-.6 4c-.9.2-1.8.6-2.6 1.1l-3.3-2.4-2.8 2.8 2.4 3.3c-.5.8-.9 1.7-1.1 2.6l-4 .6v4l4 .6c.2.9.6 1.8 1.1 2.6l-2.4 3.3 2.8 2.8 3.3-2.4c.8.5 1.7.9 2.6 1.1l.6 4h4l.6-4c.9-.2 1.8-.6 2.6-1.1l3.3 2.4 2.8-2.8-2.4-3.3c.5-.8.9-1.7 1.1-2.6l4-.6v-4l-4-.6c-.2-.9-.6-1.8-1.1-2.6l2.4-3.3-2.8-2.8-3.3 2.4c-.8-.5-1.7-.9-2.6-1.1l-.6-4zm2 8c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6z' />
                </svg>
            </h1>
            Het ziet er naar uit dat deze pagina niet bestaat. <br />
            Om terug te gaan klikt u
            <b style={{ cursor: 'pointer' }} className='pulseHighlight' onClick={logError}>
                {' hier.'}
            </b>
            {gear.element}
        </div>
    );
    // send user back where they came from, or home
    function backToLastUrl() {
        // log fault

        // get route
        let route = sessionStorage.getItem('lastRoute') === undefined ? '/' : sessionStorage.getItem('lastRoute');
        navigate(route);
    }
    function gearPressed(e) {
        let updateGear = {};
        updateGear.enabeld = gear.enabeld === false ? true : true;
        updateGear.element = gear.element;

        let X = randomIntFromInterval(5, 95) + '%';
        let Y = randomIntFromInterval(5, 95) + '%';
        if ((updateGear.enabeld && e.target.id === 'active') || !gear.enabeld) {
            updateGear.element = (
                <svg
                    id='active'
                    style={{ position: 'absolute', left: X, top: Y }}
                    onMouseOver={gearPressed}
                    viewBox='0 0 32 32'
                    width={'25px'}
                    height={'25px'}
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        id='active'
                        d='m14 2-.6 4c-.9.2-1.8.6-2.6 1.1l-3.3-2.4-2.8 2.8 2.4 3.3c-.5.8-.9 1.7-1.1 2.6l-4 .6v4l4 .6c.2.9.6 1.8 1.1 2.6l-2.4 3.3 2.8 2.8 3.3-2.4c.8.5 1.7.9 2.6 1.1l.6 4h4l.6-4c.9-.2 1.8-.6 2.6-1.1l3.3 2.4 2.8-2.8-2.4-3.3c.5-.8.9-1.7 1.1-2.6l4-.6v-4l-4-.6c-.2-.9-.6-1.8-1.1-2.6l2.4-3.3-2.8-2.8-3.3 2.4c-.8-.5-1.7-.9-2.6-1.1l-.6-4zm2 8c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6z'
                    />
                </svg>
            );
        }

        setGear(updateGear);
    }
    function randomIntFromInterval(min, max) {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function logError() {
        if (authData?.authState?.user === undefined) {
            return;
        }

        let packet = {};
        packet.routeInfo = locationParams;
        packet.routeInfo.lastPathName = sessionStorage.getItem('lastRoute');
        packet.user = authData.authState.user.id;
        packet.occurredAt = new Date();

        //create the data payload
        let data = {
            user: authData.authState.user.id,
            payload: packet,
            status: '404',
            action: 'Error',
            apiToken: null
        };

        // make a public post reqeust
        authAxios
            .post('/admin/logs', {
                data
            })
            .then((data) => {
                // send user back to home or if possible last visisted page
                backToLastUrl();
            })
            .catch((exception) => {
                console.error(exception);
                // send user back to home or if possible last visisted page
                backToLastUrl();
            });
    }
}

import Popup, {closePopup, openPopup} from "UI/App/Components/Popup/Popup";
import {formatNumberValue} from "App/Util/format";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import Icon from "UI/App/Components/Icon/Icon";
import "./timerPopup.scss";
import Grid from "UI/App/Components/Grid/Grid";
import OrderTimer from "./OrderTimer";
import {toast} from "react-toastify";
import IF from "UI/App/Components/Conditional/IF";
import Breaks from "UI/App/Partials/Content/Dashboard/Blocks/Timer/Breaks";
import LiveTimer from "UI/App/Partials/Content/Dashboard/Blocks/Timer/LiveTimer";
import TimerCustomEndTimePopup from "UI/App/Partials/Content/Dashboard/Blocks/Timer/TimerCustomEndTimePopup";
import {SpinnerOverlay} from "UI/App/Components/Spinner";

export default function TimerPopup({
    timerFor,
    orderId,
    id = 'order-timer',
    setParentTimer = undefined,
}) {
    const { authAxios } = useContext(FetchContext);

    const [timers, setTimers] = useState([]);
    const [currentTimer, setCurrentTimer] = useState(null);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    const orderTimer = useRef(null);
    if (orderTimer.current === null) {
        orderTimer.current = new OrderTimer(orderId, authAxios);
    }

    /**
     * Trigger a custom event and dispatch it on the window
     * @type {(function(*, {}=): void)|*}
     */
    const triggerEvent = useCallback((eventName, data = {}) => {
        const event = new CustomEvent(eventName, data);
        window.dispatchEvent(event);
    }, []);

    /**
     * Fetch all my timers for this order
     * @type {function(): Promise<axios.AxiosResponse<any>>}
     */
    const getMyTimersForOrder = useCallback(() => {
        return authAxios.get(`/orders/${orderId}/timers/mine`)
            .then(response => {
                return response.data;
            });
    }, [authAxios, orderId]);

    const updateMyOrderTimers = useCallback(async () => {
        return await getMyTimersForOrder()
            .then(timers => {
                if (setParentTimer !== undefined) {
                    setParentTimer(timers)
                }
                setTimers(timers);
                triggerEvent('timer::update');
            });
    }, [getMyTimersForOrder, triggerEvent]);

    useEffect(() => {
        orderTimer.current = new OrderTimer(orderId, authAxios);
    }, [authAxios, orderId]);

    /**
     * Fetch all timers for this order
     */
    useEffect(() => {
        void updateMyOrderTimers();
    }, [updateMyOrderTimers]);

    useEffect(() => {
        setCurrentTimer(timers.find(timer => timer?.timerFor === timerFor?.labor && !timer?.end));
    }, [timerFor, timers]);

    function openManualEndTimePopup() {
        let popups = document.querySelectorAll('.popup-order-timer, .emily-overlay');
        popups.forEach(node => node.style.display = 'none');

        openPopup('timer__custom-end-time');
    }

    function stopTimer(timerId, correctedEnd = null) {
        const end = new Date(correctedEnd ?? new Date());
        setButtonsDisabled(true);

        orderTimer.current.stop(timerId, end)
            .then(updateMyOrderTimers)
            .then(closePopup)
            .catch((e) => toast.error(`Er is iets fout gegaan bij het stoppen van de timer: ${e.message}`))
            .finally(() => setButtonsDisabled(false));
    }

    function startTimer(timerFor) {
        setButtonsDisabled(true);

        orderTimer.current.start(timerFor)
            .then(updateMyOrderTimers)
            .catch((e) => toast.error(`Er is iets fout gegaan bij het starten van de timer: ${e.message}`))
            .finally(() => setButtonsDisabled(false));
    }

    return (
        <>
            <TimerCustomEndTimePopup
                currentTimer={currentTimer}
                onSubmit={(endTime) => stopTimer(currentTimer.id, endTime)}
            />

            <Popup
                popupID={id}
                title={timerFor?.labor || 'Handwerk'}
                style={{
                    width: '400px',
                    left: 'calc(50% - 200px)',
                }}
            >
                <div className={'order--timer_popup--content'}>
                    <span className={'timer_popup--total_time'}><strong>GEPLANDE TIJD:</strong> {formatNumberValue(timerFor?.timeInMinutes, 'minuten')}</span>

                    <SpinnerOverlay visible={buttonsDisabled}>
                        <Grid columns={currentTimer?.end === null ? 2 : 1} className={'timer_popup--buttons'}>
                            {currentTimer && currentTimer.end === null &&
                                <>
                                    <button
                                        onClick={() => void stopTimer(currentTimer.id)}
                                        className={'btn btn--round timer_popup--button stop'}
                                        data-title={'Stop timer'}
                                        disabled={buttonsDisabled}
                                    >
                                        <Icon name={'fa-solid stop'} />
                                    </button>
                                    <button
                                        onClick={() => void openManualEndTimePopup()}
                                        className={'btn btn--round timer_popup--button stop'}
                                        data-title={'Stop timer met handmatige eindtijd'}
                                        disabled={buttonsDisabled}
                                    >
                                        <Icon name={'fa-solid square-pen'} />
                                    </button>
                                </>
                            }
                            {(!currentTimer || currentTimer?.end !== null) &&
                                <button
                                    onClick={() => startTimer(timerFor?.labor)}
                                    className={'btn btn--round timer_popup--button'}
                                    data-title={'Start timer'}
                                    disabled={buttonsDisabled}
                                >
                                    <Icon name={'play'} />
                                </button>
                            }
                            {currentTimer && <LiveTimer start={new Date(currentTimer?.start)} end={currentTimer?.end} breaks={currentTimer?.breaks} paused={currentTimer?.end !== null} />}
                        </Grid>

                        <IF condition={currentTimer?.breaks?.length > 0}>
                            <Breaks breaks={currentTimer?.breaks} />
                        </IF>
                    </SpinnerOverlay>
                </div>
            </Popup>
        </>
    )
}
import React, {useContext, useEffect, useState} from "react";
import Boolean from "../../../../../Components/Form/Boolean";
import WidgetFormRenderer from "../../Widgets/Widgets/WidgetFormRenderer";
import {FetchContext} from "App/Strapi/FetchContext";
import WidgetCollection from "../../Widgets/Widgets/WidgetCollection";
import {getResultFromWidget} from "../../Widgets/Widgets/WidgetBase";
import HandwerkTab from "../../Template/Tabs/HandwerkTab";
import HasRole from "../../../../../Components/Auth/HasRole";
import CalculationDataProvider from "../../Data/CalculationDataProvider";
import Grid from "UI/App/Components/Grid/Grid";
import WerkderdenPurchaseTab from "../../Template/Tabs/WerkderdenPurchaseTab";
import {toast} from "react-toastify";
import {SpinnerOverlay} from "UI/App/Components/Spinner";
import ChecklistSidebar from "UI/App/Components/Sidebar/Sidebars/ChecklistSidebar";

export default function StepFour({
    calculationData,
    activeStep,
    chosenCustomerId,
    totalResultJson,
    setAmounts,
    setLimitToOneAmount,
    setWerkDerdenData,
    setJson,
    length,
    width,
    amountRows,
    chosenTemplate,
    checklistData,
    checklistTemplateId,
    calculationId,
    language,
    editInfo,
    setIsLoading,
    isLoading,
    copy,
    werkDerdenData,
    originalCalculation,
    json
}) {
    const [calculationButtonData, setCalculationButtonData] = useState([]);
    const [calculationTabs, setCalculationTabs] = useState([]);
    const [resultTemplates, setResultTemplates] = useState({});
    const [subTotal, setSubTotal] = useState(0);
    const [rawTemplateData, setRawTemplateData] = useState(null)
    const [thirdParty, setThirdParty] = useState([]);
    const [manualLabor, setManualLabor] = useState(false);
    const [activeMachineTab, setActiveMachineTab] = useState(null);

    const fetchContext = useContext(FetchContext);
    const [debug, setDebugMode] = useState(false);
    const [formula, setFormula] = useState(false);


    useEffect(() => {
        if (chosenTemplate !== null) {
            void loadTemplate(chosenTemplate);
        }
    }, [chosenTemplate])

    useEffect(() => {
        if (formula) {
            renderFormula();
        } else {
            if (document.querySelector('.formula-container') !== null) {
                document.querySelector('.formula-container').innerHTML = ''
            }
        }

    }, [formula])

    useEffect(() => {
        // If calculation data is provided fill data
        if (calculationData) {
            const thirdPartyData = [];

            if (Array.isArray(calculationData.data?.thirdParty)) {
                thirdPartyData.push(...calculationData.data?.thirdParty);
            }

            if (calculationData?.purchaseQuotes !== undefined) {
                thirdPartyData.push(...calculationData.purchaseQuotes);
            }

            setThirdParty(thirdPartyData);

            // FOR BACKWARDS COMPATIBILITY
            if (Array.isArray(calculationData.data?.manualLabor)) {
                setManualLabor(calculationData.data.manualLabor);
            }
        }
    }, [setThirdParty, calculationData])

    const renderFormula = () => {
        const tabData = Object.entries(rawTemplateData.data)
            .filter((key, tab) => /\d+-\d+/.test(key))
            .sort((a, b) => a[1]?.position - b[1]?.position);

        for (const [key, value] of tabData) {
            /**
             * Check if key machine
             */
            const prefix = key.split('-')[0];
            const machineId = key.split('-')[1];

            /**
             * Get formula
             */
            let formula = '';
            setTimeout(function () { //Start the timer
                formula = new WidgetFormRenderer().renderFormula(value.widgets, debug, prefix, `${prefix}-${machineId}`) //After 1 second, set render to true

                parseFormula(formula, prefix, `${prefix}-${machineId}`);
                addValuesToFormula();
            }, 100)
        }
    }

    const addValuesToFormula = () => {
        for (const tab of document.getElementsByClassName('calculationTab')) {
            for (const widget of tab.querySelectorAll('.calculationWidget')) {
                const widgetData = JSON.parse(widget.getAttribute('widgetdata'))
                const resultElement = document.getElementById(`result_${widget.id}`);
                if (resultElement !== null) {
                    let result = '';
                    if (widgetData.data.type === 'Input') {
                        result = widget.querySelector('.inputWidgetInput').value;
                    } else if (widgetData.data.type === 'MaterialChoice') {
                        result = widget.querySelector('select').value;
                    } else if (widgetData.data.type === 'SelectWidget') {
                        result = widget.querySelector('select').value;
                    } else if (widgetData.data.type === 'Division' || widgetData.data.type === 'Times' || widgetData.data.type === 'Addition' || widgetData.data.type === 'Subtraction') {
                        result = widgetData.data.value.result;
                    } else if (widgetData.data.type === 'Int') {
                        result = widgetData.data.value.result;
                    } else {
                        result = 'not found';
                    }

                    resultElement.innerHTML = result;
                }
            }
        }

    }

    const parseFormula = (formulaItems, parent, machinePrefix) => {
        let formulaString = '';

        for (const formulaItem of formulaItems) {
            if (formulaItem !== '') {

                if (formulaItem.includes('widget')) {
                    formulaString += '( ' + getNameFromWidget(`${parent}`, formulaItem) + ' )<br>';
                } else {

                    formulaString += ` ( ${formulaItem} )<br>`
                }
            }
        }

        if (document.getElementById(machinePrefix) !== null) {
            document.getElementById(machinePrefix).querySelector('.formula-container').innerHTML = formulaString + '<hr>';
        }
        // formula-container
    }

    const getNameFromWidget = (parent, string) => {
        return string;
    }


    // Calculate if step is larger than calculate step
    useEffect(() => {
        if (activeStep >= 5) {
            calculateForm(rawTemplateData)
        }
    }, [activeStep])

    /**
     * For handling caclulation template tabs
     * @param id
     */
    const openCalculationTab = (id) => {
        // calculationTabParent
        /**
         * Reset tabs
         */
        for (const tablink of document.getElementsByClassName('calculationTablinks')) {
            tablink.classList.remove("currentTab")
        }

        for (const machine of document.getElementsByClassName('machine__item')) {
            if (machine.classList.contains('currentTab')) {
                machine.classList.remove('currentTab');
            }
        }

        document.getElementById(`calculationTablink_${id}`).classList.add('currentTab');
        document.getElementById(`calculationTablink_${id}`).classList.add('visitedTab');

        /**
         * Reset tabs
         */
        for (const tab of document.getElementsByClassName('calculationTab')) {
            // tab.classList.add("hidden")

            if (tab.parentElement.classList.contains('calculationTabParent')) {
                tab.parentElement.classList.add('hidden');
            }
        }

        /**
         * Display chosen tab
         */
        if (document.getElementById(id).parentElement.classList.contains('calculationTabParent')) {
            document.getElementById(id).parentElement.classList.remove('hidden');
        }

        setActiveMachineTab(id);
    }

    /**
     * Helper function for formattingn constants
     */
    function formatConstants(constants, prefix = null) {
        let consts = [];

        for (const constant of constants) {
            consts.push(<input key={prefix + '-' + constant.id} className={"machine-constant"} type={"hidden"} value={constant.data !== undefined ? constant.data : constant.value} id={prefix !== null ? `${prefix}-${constant.id}` : constant.id}/>)
        }

        return consts;
    }

    async function loadTemplate(templateID) {

        // Reset all used states
        setResultTemplates({});
        setCalculationButtonData([]);
        setCalculationTabs([]);
        setIsLoading(true);

        /**
         * Load template
         */
        await fetchContext.authAxios.get(`/calculations/templates/${templateID}`).then(({data: {data}}) => {

            setRawTemplateData(data);
            setLimitToOneAmount(data.data.limitToOneAmount ?? false);
            setResultTemplates(data.data.template_result);

            // Sort tab data
            const tabData = Object.entries(data.data)
                .filter((key, tab) => /\d+-\d+/.test(key[0]))
                .sort((a, b) => a[1]?.position - b[1]?.position);


            // Create array for buttons
            let tabButtons = [];
            // First create tabs for machines
            for (const [key, value] of tabData) {
                // Created needed variables
                const prefix = key.split('-')[0];
                const machineId = key.split('-')[1];
                let tabOptions = <></>;

                // Create tab name
                let name = null;
                if (value.name !== undefined && value.name !== '') {
                    name = value.name;
                } else {
                    for (const constant of value.constants) {
                        if (constant.id.includes("const-name")) {
                            name = constant.value
                        }
                    }

                    // If no name isset and no name in constants set machine id with prefix as name
                    if (name === null) {
                        name = `${prefix}-${machineId}`
                    }
                }

                // Handle optional machines
                if (value?.optional) {
                    let machineChosen = false;
                    if (calculationData !== null && calculationData !== undefined) {
                        if (calculationData.data.optionalMachines[key] !== undefined) {
                            machineChosen = calculationData.data.optionalMachines[key] == 'true';
                        }
                    }

                    // Add boolean to options
                    tabOptions = <span key={prefix} className={'toggleActive'}>
                            <Boolean
                                displayFalse={'Inactief'}
                                displayTrue={'Actief'}
                                value={machineChosen}
                                nullable={false}
                                field={{
                                    name: 'machineOptional',
                                    className: 'machineOptional'
                                }}
                                falseIsRed={true}
                                setValue={() => {
                                    calculateMachine(prefix,machineId)
                                }}
                            />
                        </span>;
                }

                // Update tab button state
                setCalculationButtonData(prev => [...prev, {
                    id: `${prefix}-${machineId}`,
                    name: name,
                    prefix: prefix,
                    optional: value.optional,
                    machineId: machineId,
                }]);

                // Format constants
                const constants = formatConstants(value.constants);

                // Add tab button to array
                tabButtons.push(<div key={`${prefix}-${machineId}`} className="calculationTabParent hidden">
                    {tabOptions}
                    <div
                        className={"calculationTab"}
                        machineid={machineId}
                        key={`${prefix}-${machineId}`}
                        id={`${prefix}-${machineId}`}
                    >
                        <div className={'formula-container'}></div>
                        {constants}</div>
                </div>)

                /**
                 * Add widgets
                 */
                setTimeout(function () { //Start the timer
                    new WidgetFormRenderer().renderForm(value.widgets, debug, prefix, `${prefix}-${machineId}`) //After 1 second, set render to true
                }, 100)
            }

            setCalculationTabs(tabButtons);
            // setRawTemplateData(data);

            if (calculationData !== null && calculationData !== undefined) {
                fillData(data?.data, calculationData.data.values, calculationData.data.templateData, calculationData);
            }
        })

        // hideMachinesWithoutInteraction();

        // Wait a bit than start calculating
        setTimeout(() => {
            calculateForm();
        }, 2500)
    }

    let i = 0;
    const fillData = async (data, values, rawData, chosenCalc) => {
        /**
         * wait until all elements are loaded
         */
        const checkLoop = function () {
            i++;
            if (i < 30) {
                fillData(data, values, rawData, chosenCalc);
            }
        }

        /**
         * If data is undefined stop execution
         */
        if (data === undefined) {
            console.warn('Data is undefined abort filling data')
            return false;
        }

        /**
         * Calc total widgets
         */
        let totalWidgets = 0;
        for (const [key, machine] of Object.entries(data)) {
            if (machine.widgets !== undefined) {
                totalWidgets += +machine.widgets.length
            }
        }

        if (document.getElementsByClassName('calculationWidget').length < totalWidgets) {
            setTimeout(checkLoop, 200);
            return false;
        } else {
            clearTimeout(checkLoop)
        }

        const calcDataProvider = new CalculationDataProvider({});

        for (const widget of document.getElementsByClassName('calculationWidget')) {
            let widgetData = JSON.parse(widget.getAttribute('widgetData'));
            if (values[widgetData.parentId] !== undefined) {
                let machineButton = document.getElementById(`calculationTablink_${widgetData.parentId}`);
                if (!machineButton.classList.contains('visitedTab')) {
                    machineButton.classList.add('visitedTab')
                }
            }
            await calcDataProvider.fillIndividualWidget(widget.getAttribute('widgetData'), widget.getAttribute('id'), data, values)
        }

        return true;
    }

    // SLeep helper function
    const sleep = m => new Promise(r => setTimeout(r, m))

    // Main function to handle calculation functions
    async function calculateMachine(prefix,machineId) {

        const tab = document.getElementById(`${prefix}-${machineId}`);

        if (tab.parentElement.querySelector('.machineOptional').value === 'false') {
            tab.classList.add('hidden')

            return;
        } else {
            tab.classList.remove('hidden')
        }

        // Add default values
        for (const widget of tab.querySelectorAll('.calculationWidget')) {
            const widgetData = JSON.parse(widget.getAttribute('widgetdata'))

            // Add default values
            if (widgetData?.data.type === "Input") {
                if (widgetData.data.title.toLowerCase() === "aantal") {
                    document.getElementById(`${widgetData.prefix}${widgetData.id}`).querySelector('.inputWidgetInput').value = 100
                }

                if (widgetData.data.title.toLowerCase() === "lengte product") {
                    document.getElementById(`${widgetData.prefix}${widgetData.id}`).querySelector('.inputWidgetInput').value = length
                }

                if (widgetData.data.title.toLowerCase() === "breedte product") {
                    document.getElementById(`${widgetData.prefix}${widgetData.id}`).querySelector('.inputWidgetInput').value = width
                }
            }
        }


        // Loop widgets
        const widgets = tab.querySelectorAll('.calculationWidget');
        for (const widget of widgets) {

            const widgetData = JSON.parse(widget.getAttribute('widgetdata'))

            // run calculation to handle tab
            new WidgetCollection(null, null, null, widgetData, debug, undefined).runCalculation(widgetData.data.type);
        }
    }

    async function calculateForm(rawTemplate) {

        // If loading all ready do not continue
        if (isLoading) return;
        setIsLoading(true);

        // Start loading
        const loading = toast.loading("Widgets toevoegen...",);

        console.time("loadingFor")

        let result = {'Inschiet': {}};
        let rawResult = {};
        let totalRawData = {};
        let units = {};
        // The results for quote, order and quote-confirmation templates
        let templateResults = {};
        let templateValues = {};

        setTimeout(() => {
            (async () => {
                // If raw template is null retry
                let dataWidgetCount = 0;
                if (rawTemplateData === null) {
                    await sleep(200);
                    return;
                }

                if (rawTemplateData !== null) {

                    for (const [key, machine] of Object.entries(rawTemplateData.data)) {
                        if (machine.widgets !== undefined) {
                            dataWidgetCount += machine.widgets.length
                        }
                    }
                }

                for (let i = 0; i < 100; i++) {
                    await sleep(100)
                    const domWidgets = document.querySelectorAll('.calculationWidget');

                    if (dataWidgetCount === 0) continue;

                    // Break loop when done
                    if (dataWidgetCount === domWidgets.length) {
                        break;
                    }
                }
            })()
        }, 200)

        // Close widget popup
        setTimeout(() => {
            toast.update(loading, {
                render: `Widgets toegevoegd.`,
                type: "success",
                isLoading: false,
                autoClose: 500
            });
        }, 1000)

        setTimeout((pendingUpdate) => {

            (async () => {
                if (isLoading) return;

                console.time("loadingFor")
                pendingUpdate = toast.loading("Machines laden...",);

                let amountKey = 1;
                let totalWidgets = 1;
                for (const amount of amountRows) {
                    await sleep(5)
                    const iterationAmount = amount[0]
                    const totalAmount = amount[0] + +amount[1];
                    const misses = amount[1];
                    const tabs = document.getElementsByClassName('calculationTab');

                    let tabKey = 1;
                    // Loop tabs and set value
                    for (const tab of tabs) {
                        // Check if optional and if so handle optional
                        if (tab.parentElement.querySelector('.machineOptional') !== null) {

                            // First handle optional machines
                            if (tab.parentElement.querySelector('.machineOptional').value === 'false') {
                                tab.classList.add('hidden')

                                continue;
                            } else {
                                tab.classList.remove('hidden')
                            }
                        }

                        // Add default values
                        for (const widget of tab.querySelectorAll('.calculationWidget')) {
                            const widgetData = JSON.parse(widget.getAttribute('widgetdata'))

                            // Add default values
                            if (widgetData?.data.type === "Input") {
                                if (widgetData.data.title.toLowerCase() === "aantal") {
                                    document.getElementById(`${widgetData.prefix}${widgetData.id}`).querySelector('.inputWidgetInput').value = totalAmount
                                }

                                if (widgetData.data.title.toLowerCase() === "lengte product") {
                                    document.getElementById(`${widgetData.prefix}${widgetData.id}`).querySelector('.inputWidgetInput').value = length
                                }

                                if (widgetData.data.title.toLowerCase() === "breedte product") {
                                    document.getElementById(`${widgetData.prefix}${widgetData.id}`).querySelector('.inputWidgetInput').value = width
                                }
                            }
                        }

                        // Loop widgets
                        const widgets = tab.querySelectorAll('.calculationWidget');
                        for (const widget of widgets) {
                            totalWidgets++;

                            // Sleep to give dom time
                            await sleep(1)
                            toast.update(pendingUpdate, {
                                render: `Aantal: ${amountKey}/${amountRows.length} Machine: ${tabKey}/${tabs.length}`,
                                // render: `Aantal: ${amountKey}/${amountRows.length} Machine:${tabKey}/${tabs.length} widget:${widgetNo}/${widgets.length}`,
                                type: "info",
                                isLoading: true
                            });

                            const widgetData = JSON.parse(widget.getAttribute('widgetdata'))
                            const res = new WidgetCollection(null, null, null, widgetData, debug, undefined).runCalculation(widgetData.data.type);

                            // If result is defined add to total result
                            if (res !== undefined) {

                                let title = '';
                                if (res.titel.includes('|')) {
                                    /**
                                     * Get prefix
                                     */
                                    const prefix = widget.id.split("-widget")[0];
                                    title = res.titel.replace('internalSKU', prefix);
                                } else {
                                    title = res.titel;
                                }

                                if (result[title] === undefined) {
                                    result[title] = {};
                                }

                                if (units[title] === undefined) {
                                    units[title] = res.unit;
                                }

                                if (result[title][iterationAmount] === undefined) {
                                    result[title][iterationAmount] = res.result
                                } else {
                                    result[title][iterationAmount] += res.result
                                }

                                if (!rawResult[widgetData.parentId]) {
                                    rawResult[widgetData.parentId] = {}
                                }

                                if (!rawResult[widgetData.parentId][title]) {
                                    rawResult[widgetData.parentId][title] = {}
                                }


                                if (!rawResult[widgetData.parentId][title][iterationAmount]) {
                                    rawResult[widgetData.parentId][title][iterationAmount] = {}
                                }

                                rawResult[widgetData.parentId][title][iterationAmount] = res.result;
                            }

                            // Add misses
                            if (misses) {
                                result['Inschiet'][iterationAmount] = misses
                            }

                            // Add template results
                            templateResults[iterationAmount] = {};
                            // Loop through template result
                            for (const [key, value] of Object.entries(resultTemplates ?? {})) {
                                if (!['quote', 'order', 'orderConfirmation'].includes(key)) continue;
                                await sleep(1)

                                // Set template result empty object
                                templateResults[iterationAmount][key] = [];

                                // Loop trough results
                                for (const [resultGroupIndex, templateResultGroup] of Object.entries(value)) {
                                    templateResults[iterationAmount][key][resultGroupIndex] = {
                                        label: templateResultGroup.name,
                                        values: []
                                    };

                                    for (const [valueIndex, value] of Object.entries(templateResultGroup.values)) {
                                        for (const [inputIndex, input] of Object.entries(value.inputs)) {
                                            if (!input.value || input.value === '') continue;

                                            if (templateResults[iterationAmount][key][resultGroupIndex].values[valueIndex] === undefined) {
                                                templateResults[iterationAmount][key][resultGroupIndex].values[valueIndex] = {
                                                    label: value.label,
                                                    inputs: [],
                                                    displaySentence: value.displaySentence
                                                };
                                            }

                                            let widgetValue;
                                            if (input.valueTypes === 'tabs') {
                                                widgetValue = document.getElementById(input.value)?.parentElement?.querySelector('.machineOptional').value === 'true' ? input.valueIfTrue : input.valueIfFalse;
                                            } else if (input.valueTypes === 'boolean') {
                                                widgetValue = input.value ? input.valueIfTrue : input.valueIfFalse;
                                            } else if (input.valueTypes === 'input') {
                                                widgetValue = input.value ?? '';
                                            } else {
                                                widgetValue = getResultFromWidget(input.value);

                                                if (widgetValue === input.value) {
                                                    continue;
                                                }
                                            }

                                            if (widgetValue !== false) {
                                                templateResults[iterationAmount][key][resultGroupIndex].values[valueIndex].inputs[inputIndex] = {
                                                    label: input.label,
                                                    name: input.name,
                                                    value: widgetValue
                                                };
                                            } else {
                                                templateResults[iterationAmount][key][resultGroupIndex].values[valueIndex].inputs[inputIndex] = {
                                                    label: input.label,
                                                    name: input.name,
                                                    value: input.value
                                                };
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        tabKey++;
                    }

                    /**
                     * Gather data for storage
                     * @type {{data: {data: {summary: any, amounts: number[], rawResult: ({}|*), misses: number, templateData: {}, calculationData: *[], length: string, width: string, checklist: *[], totals: number}, company: unknown, Name: string}}}
                     */
                    for (const [machineKey, machine] of Object.entries(rawTemplateData?.data ?? {})) {
                        if (document.getElementById(machineKey) !== null) {
                            if (document.getElementById(machineKey).parentElement.querySelector('.machineOptional') !== null) {
                                if (document.getElementById(machineKey).parentElement.querySelector('.machineOptional').value === 'false') {
                                    continue;
                                }
                            }
                        }

                        if (templateValues[machineKey] === undefined) {
                            templateValues[machineKey] = {};
                        }

                        if (templateValues[machineKey][iterationAmount] === undefined) {
                            templateValues[machineKey][iterationAmount] = [];
                        }

                        if (machine.widgets !== undefined) {
                            for (const [widgetKey, widget] of Object.entries(machine.widgets)) {
                                if (document.getElementById(`${widget.prefix}${widget.id}`) !== null) {

                                    let value = null;
                                    if (widget.data.type === 'MaterialChoice') {
                                        value = document.getElementById(`${widget.prefix}${widget.id}`).getAttribute('widgetdata');
                                    } else if (widget.data.type === 'ReturnWidget') {
                                        value = JSON.parse(document.getElementById(`${widget.prefix}${widget.id}`).getAttribute('widgetdata')).data.value.result;
                                    } else {
                                        if (document.getElementById(`${widget.prefix}${widget.id}`) !== null && document.getElementById(`${widget.prefix}${widget.id}`)?.querySelector('.inputWidgetInput') !== null) {
                                            value = document.getElementById(`${widget.prefix}${widget.id}`).querySelector('.inputWidgetInput').value
                                        } else if (document.querySelector(`select.select-${widget.prefix}${widget.id}`) !== null) {
                                            value = document.querySelector(`select.select-${widget.prefix}${widget.id}`).value
                                        }else{
                                            // Handle Division,return widgets else simple get result from widgetdata
                                            if(['Int'].includes(widget.data.type)){
                                                value = JSON.parse(document.getElementById(`${widget.prefix}${widget.id}`).getAttribute('widgetdata')).data.value;
                                            }else{
                                                value = JSON.parse(document.getElementById(`${widget.prefix}${widget.id}`).getAttribute('widgetdata')).data.value.result;
                                            }
                                        }
                                    }

                                    templateValues[machineKey][iterationAmount].push({
                                        id: widget.id,
                                        prefix: widget.prefix,
                                        value: value,
                                        unit: widget.data.unit
                                    })
                                }
                            }
                        }
                    }

                    amountKey++;
                }

                // Add manual labour
                for (const priceResult of document.querySelectorAll('.handwerk-price-result')) {
                    if (priceResult.dataset?.quantity === undefined || priceResult.dataset?.price === undefined || priceResult.dataset?.time === undefined) continue;
                    if (result['Handwerk kosten'] === undefined) result['Handwerk kosten'] = {};
                    if (result['Handwerk tijd'] === undefined) result['Handwerk tijd'] = {};

                    if (result['Handwerk kosten'][parseInt(priceResult.dataset.quantity)] === undefined) result['Handwerk kosten'][parseInt(priceResult.dataset.quantity)] = 0;
                    if (result['Handwerk tijd'][parseInt(priceResult.dataset.quantity)] === undefined) result['Handwerk tijd'][parseInt(priceResult.dataset.quantity)] = 0;

                    result['Handwerk kosten'][parseInt(priceResult.dataset.quantity)] += parseFloat(priceResult.dataset?.price);
                    result['Totaal.Subtotaal'][parseInt(priceResult.dataset.quantity)] += parseFloat(priceResult.dataset?.price);
                    result['Handwerk tijd'][parseInt(priceResult.dataset.quantity)] += parseFloat(priceResult.dataset?.time);
                }

                // Add thirdparty work
                for (const priceResult of document.querySelectorAll('.werk-derden--prijs')) {
                    if (priceResult.dataset?.quantity === undefined || priceResult.dataset?.price === undefined || priceResult.dataset?.total === undefined) continue;

                    // If werk derden key does not exist add with amount
                    if (result['Werk derden'] === undefined) result['Werk derden'] = {};
                    if (result['Werk derden'][parseInt(priceResult.dataset.quantity)] === undefined) result['Werk derden'][parseInt(priceResult.dataset.quantity)] = 0;

                    result['Werk derden'][parseInt(priceResult.dataset.quantity)] += parseFloat(priceResult.dataset?.total);

                    // Add subtotal if not exists
                    if (result['Totaal.Subtotaal'] === undefined) result['Totaal.Subtotaal'] = {};
                    result['Totaal.Subtotaal'][parseInt(priceResult.dataset.quantity)] += parseFloat(priceResult.dataset?.total);
                }

                // Used for gathering data
                totalRawData = {
                    templateData: rawTemplate,
                    calculationData: result,
                    rawResult: rawResult,
                    units: units,
                    amounts: amountRows,
                    totals: subTotal,
                    templateResults: templateResults
                };

                // Gather and set totals
                getAndSetSubtotal(result);
                await gatherData(templateValues, result, rawTemplate, totalRawData);

                toast.update(pendingUpdate, {
                    render: `Calculatie geladen.`,
                    type: "success",
                    isLoading: false,
                    autoClose: 1500
                });
                setIsLoading(false);
                console.timeEnd("loadingFor")

                // Close toast after a while
                setTimeout(() => {
                    toast.dismiss();
                    hideMachinesWithoutInteraction()
                }, 500)
            })()
        }, 500)
    }

    /**
     * Gathers subtotals and sets them
     * @param calculationData
     */
    function getAndSetSubtotal(calculationData) {

        let tmpTotals = [];
        if (calculationData['Totaal.Subtotaal'] !== undefined) {
            for (const [amount, value] of Object.entries(calculationData['Totaal.Subtotaal'])) {
                tmpTotals.push(String(value));
            }
        } else {
            console.warn('Totaal.Subtotaal Is not set.');
        }

        setSubTotal(tmpTotals);
    }

    const hideMachinesWithoutInteraction = () => {

        for (const tab of document.getElementsByClassName('calculationTab')) {
            if (tab.parentElement.querySelector('.machineOptional') === null) {
                let hiddenWidgets = 0;
                for (const widget of tab.querySelectorAll('.calculationWidget')) {
                    if (widget.classList.contains('hidden')) {
                        hiddenWidgets++;
                    }
                }

                // If all widgets hidden and machine is not optional hide button
                if (hiddenWidgets === tab.querySelectorAll('.calculationWidget').length) {
                    if (tab.id !== 'handwerk' && tab.id !== 'werk-derden') {
                        document.querySelector(`#calculationTablink_${tab.id}`)?.classList.add('hidden');
                    }
                }
            }
        }
    }

    async function gatherData(optionalValues = null, result = null, rawTemplate, totalRawData = null) {

        /**
         * Get checklist data
         */
        let checklistData = [];
        for (const element of document.getElementsByClassName('checklistInput')) {
            checklistData.push({
                type: element.type,
                name: element.getAttribute('name'),
                value: element.value,
                dutchName: element.getAttribute('data-dutch-name'),
                englishName: element.getAttribute('data-english-name'),
            })
        }

        /**
         * Gather data for storage
         * @type {{data: {data: {summary: any, amounts: number[], rawResult: ({}|*), misses: number, templateData: {}, calculationData: *[], length: string, width: string, checklist: *[], totals: number}, company: unknown, Name: string}}}
         */
        let templateValues = {};
        let optionalMachines = {};
        for (const [machineKey, machine] of Object.entries(rawTemplateData?.data ?? {})) {
            if (machine.optional) {
                if (document.getElementById(machineKey) !== null) {
                    if(document.getElementById(machineKey).parentElement.querySelector('.machineOptional') !== null){
                        optionalMachines[machineKey] = document.getElementById(machineKey).parentElement.querySelector('.machineOptional')?.value
                        if (document.getElementById(machineKey).parentElement.querySelector('.machineOptional').value) {
                            continue;
                        }
                    }
                }
            }

            templateValues[machineKey] = [];
            if (machine.widgets !== undefined) {
                for (const [widgetKey, widget] of Object.entries(machine.widgets)) {
                    let value = null;

                    if (document.getElementById(`${widget.prefix}${widget.id}`) !== null) {
                        if (widget.data.type === 'MaterialChoice') {
                            value = document.getElementById(`${widget.prefix}${widget.id}`).getAttribute('widgetdata');
                        } else {
                            if (document.getElementById(`${widget.prefix}${widget.id}`).querySelector('.inputWidgetInput') !== null) {
                                value = document.getElementById(`${widget.prefix}${widget.id}`).querySelector('.inputWidgetInput').value
                            } else if (document.getElementById(`${widget.prefix}${widget.id}`).querySelector('select') !== null) {
                                value = document.getElementById(`${widget.prefix}${widget.id}`).querySelector('select').value
                            }
                        }
                    }

                    templateValues[machineKey].push({
                        id: widget.id,
                        prefix: widget.prefix,
                        value: value
                    })
                }
            }
        }

        /**
         * Gather third party work
         */
        let thirdPartyWorkStorage = [];
        for (const thirdPartyWork of document.getElementsByClassName('werk-derden-entry')) {

            const entryData = JSON.parse(thirdPartyWork.dataset?.json ?? '{}');

            thirdPartyWorkStorage.push(entryData);
        }

        /**
         * Gather manual labor storage
         * @type {{}}
         */
        let manualLaborStorage = [];
        for (const handwerk of document.getElementsByClassName('handwerk-entry')) {

            const entryData = JSON.parse(handwerk.dataset?.json ?? '{}');

            manualLaborStorage.push(entryData);
        }

        let filteredAmounts = [];
        let filteredMisses = [];

        for (const amount of amountRows) {
            filteredAmounts.push(amount[0]);
            filteredMisses.push(amount[1])
        }

        let tmpTotals = [];
        if (result['Totaal.Subtotaal'] !== undefined) {
            for (const [amount, value] of Object.entries(result['Totaal.Subtotaal'])) {
                tmpTotals.push(String(value));
            }
        } else {
            console.warn('Totaal.Subtotaal Is not set.');
        }

        setAmounts(filteredAmounts);

        let summary= undefined;

        let newJson = {
            data: {
                company: chosenCustomerId,
                data: {
                    templateData: rawTemplate,
                    calculationData: result,
                    checklist: checklistData,
                    checklistTemplateId: checklistTemplateId,
                    values: optionalValues,
                    amounts: filteredAmounts,
                    length: length,
                    misses: filteredMisses,
                    rawResult: totalRawData.rawResult,
                    units: totalRawData.units,
                    width: width,
                    totals: tmpTotals,
                    totalResultJson: totalResultJson,
                    manualLabor: manualLaborStorage,
                    thirdParty: thirdPartyWorkStorage,
                    optionalMachines: optionalMachines,
                    templateResults: totalRawData.templateResults,
                    // summary: summary,
                    // summary: originalCalculation?.data?.originalSummary,
                }
            },
        }

        // On create get from json
        if(json?.data?.data?.originalSummary !== undefined){
            newJson.data.data.originalSummary = json.data?.data?.originalSummary;
        }else if(originalCalculation !== undefined){
            // On edit fetch from original calculation
            newJson.data.data.originalSummary = originalCalculation?.data?.originalSummary;
        }

        setJson(newJson);
    }

    function getMachineButtons() {

        return (
            <div className="form__item grid__4 machine__nav">
                {calculationButtonData
                    .filter(machine => !machine.optional)
                    .map((machine, key) => (
                        <div
                            key={key}
                            className={'required machine__item'}
                            id={`calculationTablink_${machine.id}`}
                            onClick={() => openCalculationTab(machine.id)}
                        >
                            {machine.name}
                        </div>
                    ))}
            </div>
        );
    }

    function getOptionalMachineButtons() {
        return (
            <div className="form__item grid__4 machine__nav">
                {
                    calculationButtonData
                        .filter(machine => machine.optional)
                        .map((machine, key) => (
                            <div
                                key={key}
                                className={`optional machine__item`}
                                id={`calculationTablink_${machine.id}`}
                                onClick={() => openCalculationTab(machine.id)}
                            >
                                {machine.name}
                            </div>
                        ))}
            </div>
        );
    }

    function getOtherMachineButtons() {
        const buttonsToAdd = [
            {
                id: 'handwerk',
                name: 'Handwerk',
                optional: true,
            },
            {
                id: 'werk-derden',
                name: 'Werkderden',
                optional: true,
            }
        ];

        return (
            <div className="form__item grid__4 machine__nav">
                {
                    buttonsToAdd
                        .map((machine, key) => (
                            <div
                                key={key}
                                className={`optional machine__item`}
                                id={`calculationTablink_${machine.id}`}
                                onClick={() => openCalculationTab(machine.id)}
                            >
                                {machine.name}
                            </div>
                        ))}
            </div>
        );
    }

    return (
        <Grid columns={2} customColTemplate={{
            1200: '300px 1fr',
            default: '400px 1fr',
        }}>
            <div className="machine__buttons form__item">
                <HasRole roles={['admin']}>
                    <div className="form__content">
                        <div className={'admin-options'}>
                            <button onClick={() => calculateForm()}>
                                Berekenen
                            </button>
                        </div>
                        <div className={'admin-options'}>
                            Debug:
                            <Boolean
                                style={{backgroundColor: "#E2E2E2"}}
                                value={debug}
                                displayFalse={"Uit"}
                                displayTrue={"Aan"}
                                field={{name: 'toggle-debug'}}
                                setValue={(name, value) => {
                                    setDebugMode(value)
                                }}
                            />
                        </div>
                        <div className={'admin-options'}>
                            Formula:
                            <Boolean style={{backgroundColor: "#E2E2E2"}}
                                     value={formula}
                                     displayFalse={"Uit"}
                                     displayTrue={"Aan"}
                                     field={{name: 'toggle-debug'}}
                                     setValue={(name, value) => {
                                         setFormula(value)
                                     }}
                            />
                        </div>
                    </div>
                </HasRole>
                <b>Verplichte machines</b>
                {getMachineButtons()}
                <b>Optionele machines:</b>
                {getOptionalMachineButtons()}
                <b>Overig:</b>
                {getOtherMachineButtons()}
            </div>
            <div className="machines form__item">
                <SpinnerOverlay visible={isLoading && !debug}>
                    {calculationTabs}
                    <div className="calculationTabParent hidden">
                        <HandwerkTab className={""} id={"handwerk"} quantities={amountRows} data={manualLabor}/>
                    </div>
                    <div className="calculationTabParent hidden">
                        <WerkderdenPurchaseTab
                            checklistData={checklistData}
                            id={"werk-derden"}
                            quantities={amountRows}
                            thirdParty={thirdParty}
                            activeTab={activeStep}
                            calculationId={calculationId}
                            activeMachineTab={activeMachineTab}
                            ableToView={editInfo}
                            werkDerdenData={werkDerdenData}
                            setWerkDerdenData={setWerkDerdenData}
                            copy={copy}
                        />
                    </div>
                </SpinnerOverlay>
            </div>

            <ChecklistSidebar
                checklist={checklistData}
                language={language}
            />

        </Grid>
    )
}

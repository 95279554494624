import Grid from "UI/App/Components/Grid/Grid";
import Select from "react-select";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import debounce from "lodash/debounce";
import OrderTimerRepository from "UI/App/Partials/Content/CRM/Orders/OrderTimer/OrderTimer";
import {toast} from "react-toastify";
import {formatTimeWithoutDate} from "App/Util/format";

export default function OrderTimer() {
    const {authAxios} = useContext(FetchContext);
    const [customers, setCustomers] = useState([]);
    const [projects, setProjects] = useState([]);

    const customerRef = useRef();
    const projectRef = useRef();
    const activityRef = useRef();

    /**
     * Search for customer
     * @type {(function(*): Promise<void>)|*}
     */
    const search = useCallback(async (name = "") => {
        if (!name) {
            void search("Pretore");
            return;
        }

        await authAxios.get('/crm/companies/all', {
            params: {
                fields: ['name'],
                filters: {
                    name: {
                        $containsi: name
                    },
                    archived: {
                        $neq: true
                    }
                },
                limit: 10,
                sort: 'name:asc'
            }
        })
            .then(({ data }) => {
                setCustomers(data.map(c => ({
                    value: c.id,
                    label: c.name
                })))
            })
    }, [authAxios]);

    /**
     * Get the orders by customer
     * @type {(function(number): Promise<void>)|*}
     */
    const getOrders = useCallback(async (customer) => {
        projectRef.current.clearValue();

        if (!customer) {
            setProjects([]);
            return;
        }

        await authAxios.get('/orders/all', {
            params: {
                fields: ['id', 'number', 'description'],
                filters: {
                    company: customer,
                    $or: [
                        {
                            status: {
                                $in: ['productie']
                            }
                        },
                        {
                            status: {
                                $null: true
                            }
                        }
                    ]
                },
                sort: 'id:desc'
            }
        })
            .then(({ data }) => {
                setProjects(data.map(c => ({
                    value: c.id,
                    label: `[${c.number}] ${c.description}`
                })))
            })
    }, [authAxios]);

    const debouncedSearch = debounce((searchString) => search(searchString), 500)

    useEffect(() => {
        void search("Pretore");
    }, [search]);

    const startTimer = useCallback(() => {
        const customer = customerRef.current?.getValue()?.[0]?.value;
        const project = projectRef.current?.getValue()?.[0]?.value;
        const activity = activityRef.current?.value;

        console.log({
            customer,
            project,
            activity
        })

        if (!customer) {
            toast.error("Kies eerst een klant!");
            return;
        }

        if (!project) {
            toast.error("Kies eerst een project!");
            return;
        }

        if (!activity) {
            toast.error("Activiteit moet ingevuld zijn!");
            return;
        }

        const orderTimer = new OrderTimerRepository(project, authAxios);
        orderTimer
            .start(activity)
            .then(() => {
                const event = new CustomEvent('timer::update', {});
                window.dispatchEvent(event);
            })
            .finally(() => {
                toast.success(`Timer gestart voor ${activity} om ${formatTimeWithoutDate()}`);

                // Clear inputs
                customerRef.current.clearValue();
                projectRef.current.clearValue();
                activityRef.current.value = '';
            })
    }, [authAxios]);

    return (
        <Grid columns='1' gap='5px'>
            <div>
                <label className='required'>Klant</label>
                <Select
                    placeholder="Kies een klant of type om te zoeken..."
                    onInputChange={(searchString, actionMeta) => {
                        if (actionMeta.action === 'input-change')
                            debouncedSearch(searchString)
                    }}
                    filterOption={() => true}
                    onChange={(value) => getOrders(value?.value)}
                    noOptionsMessage={ ({inputValue}) => inputValue.length > 0 ? `Geen klanten gevonden met de naam "${inputValue}"` : "Geen klanten gevonden" }
                    options={customers}
                    isSearchable
                    isClearable
                    ref={customerRef}
                />
            </div>
            <div>
                <label className='required'>Project</label>
                <Select
                    placeholder="Voor welk project gaat je werken?"
                    noOptionsMessage={() => "Geen projecten gevonden voor deze klant"}
                    options={projects}
                    ref={projectRef}
                />
            </div>
            <div>
                <label className='required'>Activiteit</label>
                <input
                    type='text'
                    placeholder='Wat ga je doen?'
                    ref={activityRef}
                />
            </div>
            <button onClick={startTimer} type='button' className='btn--primary'>Start timer</button>
        </Grid>
    )
}
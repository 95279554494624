import {useContext, useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import Inputs, {getFormData} from "UI/App/Components/Form/Inputs";
import {getCountriesList} from "App/Util/countries";
import Icon from "UI/App/Components/Icon/Icon";
import Block from "UI/App/Components/Block/Block";
import strapiToast from "App/Util/errorHandle";
import Container from "UI/App/Components/Container/Container";
import useWindowDimensions from "App/Util/useWindowDimensions";

export default function EditBranch() {
    const {authAxios} = useContext(FetchContext);

    // get the params from the url
    const params = useParams();
    // check if on suppliers path
    const location = useLocation();
    const navigate = useNavigate();
    const isSupplier = /\/suppliers\//.test(location.pathname);

    const { width } = useWindowDimensions();

    const [branchData, setBranchData] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [countryNames, setCountryNames] = useState([]);
    const [countryLandCodes, setCountryLandCodes] = useState([]);

    useEffect(() => {
        getCountriesList().then((result) => {
            setCountryNames(result.countries);
            setCountryLandCodes(result.countryPhoneCodes);
        });
    }, []);

    useEffect(() => {
        if(branchData) {
            setInputData(branchData);
        }
    }, [branchData, width])

    useEffect(() => {
        if (countryNames.length !== 0 && countryLandCodes.length !== 0) {
            authAxios.get(`/crm/companies/branches/${params.branchId}`, {
                params: {
                    populate: [
                        'address',
                        'telephone'
                    ]
                }
            }).then(({data: {data}}) => {
                setBranchData(data)
            }).catch((exception) => {
                console.error(exception);
            });
        }
    }, [countryNames, countryLandCodes]);

    function setInputData(data) {
        const inputs = [
            {
                type: 'hidden',
                name: !isSupplier ? 'company' : 'supplier',
                value: params.companyId,
                required: true,
            },
            {
                type: 'text',
                name: 'name',
                label: 'Naam vestiging',
                placeholder: 'Hoofdkantoor...',
                defaultValue: data?.name,
                required: true,
            },
            {
                type: 'text',
                name: 'locationManager',
                label: 'Locatiemanager',
                placeholder: 'Locatiemanager',
                defaultValue: data?.locationManager,
                required: false
            },
            {
                type: 'multiple',
                name: 'address',
                label: 'Adres',
                required: true,
                subFields: [
                    {
                        type: 'text',
                        name: 'address[addressLine1]',
                        placeholder: 'Adres regel 1...',
                        defaultValue: data?.address?.addressLine1,
                        required: true,
                        style: {
                            width: '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[addressLine2]',
                        placeholder: 'Adres regel 2...',
                        defaultValue: data?.address?.addressLine2,
                        style: {
                            width: '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[addressLine3]',
                        placeholder: 'Adres regel 3...',
                        defaultValue: data?.address?.addressLine3,
                        style: {
                            width: '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[postalCode]',
                        placeholder: 'Postcode...',
                        defaultValue: data?.address?.postalCode,
                        required: true,
                        style: {
                            width: width > 500 ? '30%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[city]',
                        placeholder: 'Stad...',
                        defaultValue: data?.address?.city,
                        required: true,
                        style: {
                            width: width > 500 ? '70%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[province]',
                        placeholder: 'Provincie...',
                        defaultValue: data?.address?.province,
                        style: {
                            width: width > 500 ? '30%' : '100%'
                        },
                    },
                    {
                        type: 'select',
                        name: 'address[country]',
                        placeholder: 'Land...',
                        value: data?.address?.country,
                        options: countryNames,
                        required: true,
                        style: {
                            width: width > 500 ? '70%' : '100%'
                        },
                    },
                ]
            },
            {
                type: 'multiple',
                name: 'telephone',
                label: 'Telefoonnummer',
                required: true,
                subFields: [
                    {
                        type: 'select',
                        name: 'telephone[dialingCode]',
                        placeholder: 'Landcode...',
                        options: countryLandCodes,
                        value: data?.telephone?.dialingCode,
                        required: true,
                        style: {
                            width: width > 600 ? '20%' : '100%'
                        },
                    },
                    {
                        type: 'tel',
                        name: 'telephone[telephoneNumber]',
                        placeholder: 'Telefoonnummer...',
                        defaultValue: data?.telephone?.telephoneNumber,
                        required: true,
                        style: {
                            width: width > 600 ? '80%' : '100%'
                        },
                    }
                ]
            },
            {
                type: 'text',
                name: 'email',
                label: 'Email',
                placeholder: 'Email...',
                required: false,
                defaultValue: data?.email,
            },
            {
                type: 'submit',
                name: 'submit',
                class: 'btn'
            },
        ];

        setInputs(inputs);
    }

    async function handleSubmit(e) {
        e.preventDefault();

        const data = await getFormData(e, authAxios);

        authAxios.put(`/crm/companies/branches/${params.branchId}`, {
            data
        }).then(() => {
            toast.success('Vestiging aangepast');
            navigate(`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${params.companyId}/branch/${params.branchId}`);
        }).catch((e) => {

            strapiToast(e)
            console.error(e);
        });
    }

    return (
        <Container>
            <Block name={'editBranche'} title={<>
                <Link className={'btn btn--transparent'} to={`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${params.companyId}`}><Icon name={'arrow-left'}/></Link>
                Vestiging aanpassen
            </>}>
                <form onSubmit={handleSubmit}>
                    <Inputs key={crypto.randomUUID()} inputs={inputs}/>
                </form>
            </Block>
        </Container>
    );
}

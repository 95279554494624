// Adds logs used primarily in exports
export function addLog({ authData, authAxios, payload = {}, status = "", action = "", apiToken = null }) {
    //create the data payload
    let data = {
        user: authData?.authState?.user,
        payload: payload,
        status: status,
        action: action,
        apiToken: apiToken
    };

    // guard against invalid authAxios
    if (authAxios === undefined) {
        return;
    }

    // make a public post request
    authAxios.post('/admin/logs', {
        data
    }).catch((exception) => {
        console.error(exception);
    });
}

